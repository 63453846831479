import classNames from "classnames";
import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from "../assets/Braun_Logo.png";
import Auth, {CognitoUser} from "@aws-amplify/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {AmplifyAuthAlert, AmplifyAuthError} from "./AmplifyAuthAlert";
import {Alert} from "react-bootstrap";
import {ModalShowMessage} from "../admin/ModalShowMessage";
import {emailExpression} from "../util"
import {passwordExpression} from "../util"

export interface LoginProps {
    cognitoUser: CognitoUser | undefined,
    cognitoUserChanged: (newUser: CognitoUser | undefined) => void
}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    // The user might have been redirected to this page because he is not logged in,
    // see the CheckNavigation component.
    // If redirected, the page he was trying to go to will be in location.state.
    // After he logs in, we will send him to that page.
    // Use { replace: true } in the navigate function below so we don't create
    // another entry in the history stack for the login page. This means that
    // when he gets to the desired page and clicks the back button, he
    // won't end up back on the login page, which is also really nice for the
    // user experience.
    // If location.state is undefined, there was no redirection, so go to "/".
    const pathname = location.state || "/";

    const [userName, setUserName] = useState("");
    const [userValid, setUserValid] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [recoveryEmail, setRecoveryEmail] = useState("");
    const [recoveryEmailValid, setRecoveryEmailValid] = useState(true);
    const [showRecoveryPassword, setShowRecoveryPassword] = useState(false)
    const [authError, setAuthError] = useState<AmplifyAuthError>()

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function closeAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    const updateUser = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserName(event.target.value)
        setUserValid(event.target.value.length === 0 || emailExpression.test(event.target.value))
    }

    const updatePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(event.target.value)
        setPasswordValid(event.target.value.length === 0 || passwordExpression.test(event.target.value))
    }

    const updateRecoveryEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecoveryEmail(event.target.value)
        setRecoveryEmailValid(event.target.value.length === 0 || emailExpression.test(event.target.value))
    }

    const handleLoginSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        // We need to run regex tests here because userValid and passwordValid are set only if the user edits the fields.
        if (passwordExpression.test(password) === false) {
            displayAlert("Password is invalid");
        }
        else if (emailExpression.test(userName) === false) {
            displayAlert("Username is not a valid email address");
        }
        else {
            // Sign in this user (also saves the user session in local storage, I think).
            Auth.signIn({
                password: password, username: userName
            })
            .then((user: CognitoUser) => {
                // Tell the parent app the Cognito user has changed.
                props.cognitoUserChanged(user);
                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    // Cognito requires a password change on first login.
                    // Go to the new password page.
                    navigate("/new-password");
                }
                else {
                    // Go to the desired page.
                    navigate(pathname, { replace: true });
                }
                return Auth.userAttributes(user);
            })
            .then(() => {
                setUserName("");
                setPassword("");
            })
            .catch((e: AmplifyAuthError) => {
                setAuthError(e)
            })
        }
    };

    const handleRequestPasswordChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        // We need to run regex tests here because recoveryEmailValid is set only if the user edits the fields.
        if (emailExpression.test(recoveryEmail) === false) {
            displayAlert("Username is not a valid email address");
        }
        else {
            Auth.forgotPassword(recoveryEmail).then(() => {
                setRecoveryEmail("");
                navigate("/password-recovery", {
                    state: {email: recoveryEmail}
                })
            })
            .catch((e: AmplifyAuthError) => {
                setAuthError(e)
            })
        }
    }

    return (
        <div>
            {
            props.cognitoUser !== undefined &&
            <div className={classNames("d-flex justify-content-center align-items-center")}>
                <Form className={classNames("rounded p-4 p-sm-3 rit-form")}>
                    <div className={classNames("text-center")}>
                        <img className="mb-3" alt="Braun Logo" width="141" height="58" src={logo}/>
                        <Alert variant={"warning"}>
                            A user is currently logged in.<br/>Log out first then try logging in.<br/>
                        </Alert>
                    </div>
                </Form>
            </div>
            }

            {props.cognitoUser === undefined && !showRecoveryPassword &&
                <div className={classNames("d-flex justify-content-center align-items-center")}>
                    <Form className={classNames("rounded p-4 p-sm-3 rit-form")} onSubmit={handleLoginSubmit}>
                        <div className={classNames("text-center")}>
                            <img className="mb-3" alt="Braun Logo" width="141" height="58" src={logo}/>
                        </div>
                        <div className={classNames("text-center")}>
                            <Form.Label><strong>Log In</strong></Form.Label>
                        </div>
                        <Form.Group className={classNames("mb-3")} controlId="name">
                            <Form.Label>Username (Email Address)</Form.Label>
                            <Form.Control value={userName} type="text" isInvalid={!userValid} onChange={event => updateUser(event)}/>
                        </Form.Group>
                        <Form.Group className={classNames("mb-3")} controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control value={password} type="password" isInvalid={!passwordValid} onChange={event => updatePassword(event)}/>
                        </Form.Group>
                        <div className={classNames("text-center")}>
                            <Button variant="primary" type="submit">Submit</Button>
                        </div>
                        <div className={classNames("text-center")} style={{paddingTop: 15}}>
                            <Form.Label>
                                <Button variant="link" onClick={() => setShowRecoveryPassword(true)}>I forgot my password</Button>
                            </Form.Label>
                        </div>
                    </Form>
                </div>
            }
            {props.cognitoUser === undefined && showRecoveryPassword &&
                <div className={classNames("d-flex justify-content-center align-items-center")}>
                    <Form className={classNames("rounded p-4 p-sm-3 rit-form")} onSubmit={handleRequestPasswordChange}>
                        <div className={classNames("text-center")}>
                            <img className="mb-3" alt="Braun Logo" width="141" height="58" src={logo}/>
                        </div>
                        <div className={classNames("text-center")}>
                            <Form.Label><strong>Request Password Reset</strong></Form.Label>
                        </div>
                        <div className={classNames("text-center")}>
                            <Form.Label>We'll send a reset code by email.</Form.Label>
                        </div>
                        <br/>

                        <Form.Group className={classNames("mb-3")} controlId="name">
                            <Form.Label>Username (Email Address)</Form.Label>
                            <Form.Control value={recoveryEmail} type="text" isInvalid={!recoveryEmailValid} onChange={event => updateRecoveryEmail(event)}/>
                        </Form.Group>

                        <div className={classNames("text-center")}>
                            <Button variant="primary" type="submit">Submit</Button>
                        </div>

                        <div className={classNames("text-center")} style={{paddingTop: 15}}>
                            <Form.Label>
                                <Button variant="link" onClick={() => setShowRecoveryPassword(false)}>I remember my password</Button>
                            </Form.Label>
                        </div>
                    </Form>
                </div>
            }

            {/* Modal to show auth message. */}
            <AmplifyAuthAlert error={authError}/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={closeAlert}
            />

        </div>
    );
}
