import React from "react";
import classNames from "classnames";
import {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {ICustomer, Customer} from "../models/customer";
import {idExpression} from "../util"
import {nameExpression, DialogType} from "../util"
import Alert from "react-bootstrap/Alert";

export interface ModalEditCustomerProps {
    show: boolean,
    title: string,
    type: DialogType,
    handleSave: (sys:ICustomer) => void,
    handleClose: () => void,
    customerValues: ICustomer
}

export const ModalEditCustomer: React.FC<ModalEditCustomerProps> = (props: ModalEditCustomerProps) => {
    const [customerId, setCustomerId] = useState("");
    const [idValid, setIdValid] = useState(true);
    const [customerName, setCustomerName] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [description, setDescription] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function clearAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    useEffect(() => {
        // Copy values sent to this component from the parent to the form fields.
        // console.log(`In editor for item ${props.customerValues.id}, ${props.customerValues.name}`);
        setCustomerId(props.customerValues.id);
        setIdValid(true);
        setCustomerName(props.customerValues.name);
        setNameValid(true);
        setDescription(props.customerValues.desc);
        clearAlert();
    }, [props.customerValues]);

    const updateId = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCustomerId(event.target.value);
        setIdValid(event.target.value.length === 0 || idExpression.test(event.target.value));
    }

    const updateName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCustomerName(event.target.value);
        setNameValid(event.target.value.length === 0 || nameExpression.test(event.target.value));
    }

    const handleSave = () => {
        // We need to run regex tests here because idValid and nameValid are set only if the user edits the fields.
        if (!idExpression.test(customerId) || !nameExpression.test(customerName)) {
            displayAlert("Enter valid customer ID and name");
        }
        else {
            var c = new Customer(customerId, customerName, description);

            setCustomerId("");
            setCustomerName("");
            setDescription("");
            clearAlert();
    
            // Return new customer to the parent.
            props.handleSave(c);
        }
    }

    const handleClose = () => {
        // Note we are not returning anything to the parent.
        setCustomerId("");
        setCustomerName("");
        setDescription("");
        clearAlert();
        props.handleClose();
    }

    return (
        <Modal show={props.show} backdrop="static" scrollable={true}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className={classNames("mb-3")} controlId="cust-id">
                        <Form.Label>Company ID</Form.Label>

                        {
                        props.type === DialogType.ADD &&
                        <Form.Control type="text" value={customerId} isInvalid={!idValid} onChange={(event) => updateId(event)}/>
                        }

                        {
                        props.type === DialogType.EDIT &&
                        <Form.Control type="text" value={customerId} disabled/>
                        }

                        <Form.Text className="text-muted">
                            Company ID must be unique. It should be short and contain only
                            lowercase letters, digits, underscores, and hyphens.
                            An existing ID cannot be changed.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className={classNames("mb-3")} controlId="cust-name">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" value={customerName} isInvalid={!nameValid} onChange={(event) => updateName(event)}/>
                        <Form.Text className="text-muted">
                            Enter a friendly name for the company.
                            It can contain uppercase and lowercase letters, digits, underscores, hyphens, periods and spaces.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className={classNames("mb-3")} controlId="desc">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" value={description}  placeholder="(optional)" onChange={(event) => setDescription(event.target.value)}/>
                    </Form.Group>

                    {/* This alert is for showing errors. It is always visible. */}
                    <Alert variant={showAlert ? "danger" : "light"}>
                        {alertMessage}
                    </Alert>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
);
}