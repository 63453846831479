import classNames from "classnames";
import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from "../assets/Braun_Logo.png";
import Auth, {CognitoUser} from "@aws-amplify/auth";
import {AmplifyAuthAlert, AmplifyAuthError} from "./AmplifyAuthAlert";
import {useNavigate, useLocation} from "react-router-dom";
import {PasswordRequirements} from "./PasswordRequirements";
import {ModalShowMessage} from "../admin/ModalShowMessage";
import {passwordExpression} from "../util"

export interface PasswordRecoveryProps {
    cognitoUser: CognitoUser | undefined,
    cognitoUserChanged: (newUser: CognitoUser | undefined) => void
}

export const PasswordRecovery: React.FC<PasswordRecoveryProps> = (props: PasswordRecoveryProps) => {
    const navigate = useNavigate();
    const [recoveryCode, setRecoveryCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPwValid, setNewPwValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPwValid, setConfirmPwValid] = useState(true);
    const [authError, setAuthError] = useState<AmplifyAuthError>()
    const email = useLocation().state.email
    const passwordChangedMsg:string = "Password changed! You must now log in with your new password.";

    const updateNewPw = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewPassword(event.target.value)
        setNewPwValid(event.target.value.length === 0 || passwordExpression.test(event.target.value))
    }

    const updateConfirmPw = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPassword(event.target.value)
        setConfirmPwValid(event.target.value.length === 0 || passwordExpression.test(event.target.value))
    }

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        // We need to run regex tests here because passwords are checked only if the user edits the fields.
        if (recoveryCode.length < 1){
            displayAlert("A reset code must be specified");
        }
        else if (confirmPassword !== newPassword) {
            displayAlert("New and confirm passwords don't match");
        }
        else if (passwordExpression.test(newPassword) === false) {
            displayAlert("New or confirm password is invalid");
        }
        else {
            Auth.forgotPasswordSubmit(email, recoveryCode, newPassword)
            .then((result: string) => {
                return result;
            })
            .then((result: string) => {
                // Tell the parent app a user is not currently logged in.
                props.cognitoUserChanged(undefined);
                setRecoveryCode("");
                setNewPassword("");
                setConfirmPassword("");
                displayAlert(passwordChangedMsg);
            })
            .catch((e: AmplifyAuthError) => {
                setAuthError(e)
            })
        }
    };

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);

        // If this is the password changed message, we successfully
        // changed the password, so go to the Login page.
        // We do this to delay navigating until the alert dialog is closed.
        if (alertMessage === passwordChangedMsg)
            navigate("/login");
    }

    return (
        <div>
            <div className={classNames("d-flex justify-content-center align-items-center")}>
                <Form className={classNames("rounded p-4 p-sm-3 rit-form")} onSubmit={handleSubmit}>
                    <div className={classNames("text-center")}>
                        <img className="mb-3" alt="Braun Logo" width="141" height="58" src={logo}/>
                    </div>

                    <div className={classNames("text-center")}>
                        <Form.Label><strong>Reset password for<br/>{email}</strong></Form.Label>
                    </div>

                    <div className={classNames("text-center")}>
                            <Form.Label>Enter the reset code you received by email.</Form.Label>
                    </div>
                    <br/>

                    <Form.Group className={classNames("mb-3")} controlId="recovery_code">
                        <Form.Label>Reset Code</Form.Label>
                        <Form.Control type="text" value={recoveryCode} onChange={event => setRecoveryCode(event.target.value)}/>
                    </Form.Group>

                    <Form.Group className={classNames("mb-3")} controlId="new_pw">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password" value={newPassword} isInvalid={!newPwValid} onChange={event => updateNewPw(event)}/>
                    </Form.Group>

                    <Form.Group className={classNames("mb-3")} controlId="confirm_pw">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" value={confirmPassword} isInvalid={!confirmPwValid} onChange={event => updateConfirmPw(event)}/>
                    </Form.Group>

                    <div className={classNames("text-center", "mb-3")}>
                        <Button variant="primary" type="submit">Submit</Button>
                    </div>

                    <PasswordRequirements/>
                </Form>
            </div>

            {/* Modal to show auth message. */}
            <AmplifyAuthAlert error={authError}/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />
        </div>
    );
}
