import ProgressBar from 'react-bootstrap/ProgressBar';

export interface BarChart2Props {
    title: string,
    subTitle: string,
    barTitles: string[],    // titles(shown above the bars)
    barValues: number[],    // values to show on the bars
    actuals: number[],      // actual values (shown above the bars)
    targets: number[]       // target values (shown above the bars)
}

export const BarChart2: React.FC<BarChart2Props> = (props: BarChart2Props) => {

    // Get color for progress bar variant according to % completed.
    function getVariantColor(value:number): string {
        if (value < 50)         // < 50% is Bootstrap danger red
            return "danger";
        if (value < 75)         // < 75% is Bootstrap warning yellow
            return "warning";
        return "success";       // Bootstrap success green
    }

    return (
        <div className="p-2 m-2" style={{ width: 300, border: '1px solid', borderRadius:6, backgroundColor:"#d9d9d9"}}>
            <p className="text-center braun-text mt-0 mb-0" style={{ fontSize: 20}}>{props.title}</p>
            <p className="text-center braun-text mt-0 mb-0" style={{ fontSize: 16}}>{props.subTitle} - Actual vs Target</p>

            <span className="d-flex justify-content-between mb-0" >
                <span className="mb-0 braun-text">{props.barTitles[0]}</span>
                <span className="mb-0 braun-text">{`${props.actuals[0]}/${props.targets[0]}`}</span>
            </span>
            {
                (props.targets[0] <= 0) &&
                <span className="mb-2 progress-bar-error">Error: Target is zero</span>
            }
            {
                (props.targets[0] > 0) &&
                <ProgressBar className="mb-2" variant={getVariantColor(props.barValues[0])} now={Math.round(props.barValues[0])} label={`${Math.round(props.barValues[0])}%`} />
            }

            <span className="d-flex justify-content-between mb-0" >
                <span className="mb-0 braun-text">{props.barTitles[1]}</span>
                <span className="mb-0 braun-text">{`${props.actuals[1]}/${props.targets[1]}`}</span>
            </span>
            {
                (props.targets[1] <= 0) &&
                <span className="mb-2 progress-bar-error">Error: Target is zero</span>
            }
            {
                (props.targets[1] > 0) &&
                <ProgressBar className="mb-2" variant={getVariantColor(props.barValues[1])} now={Math.round(props.barValues[1])} label={`${Math.round(props.barValues[1])}%`} />
            }

            <span className="d-flex justify-content-between mb-0" >
                <span className="mb-0 braun-text">{props.barTitles[2]}</span>
                <span className="mb-0 braun-text">{`${props.actuals[2]}/${props.targets[2]}`}</span>
            </span>
            {
                (props.targets[2] <= 0) &&
                <span className="mb-2 progress-bar-error">Error: Target is zero</span>
            }
            {
                (props.targets[2] > 0) &&
                <ProgressBar className="mb-2" variant={getVariantColor(props.barValues[2])} now={Math.round(props.barValues[2])} label={`${Math.round(props.barValues[2])}%`} />
            }
        </div>
    );
}
