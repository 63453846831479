import React from "react";
import classNames from "classnames";
import {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col} from "react-bootstrap";
import TimezoneSelect, {ITimezone} from "react-timezone-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {_ShiftTargetsList, _ShiftTargets, _Targets} from "../models/types"
import {Trash3} from 'react-bootstrap-icons';
import Alert from "react-bootstrap/Alert";
import {positiveIntExpression, checkForShiftOverlap} from "../util"
import {get_date} from "../util";
import {isAfter, isEqual} from 'date-fns'

interface EditShiftsTargetsProps {
    show: boolean,
    title: string,
    handleSave: (list:_ShiftTargetsList) => void,
    handleClose: () => void,
    shiftTargetList: _ShiftTargetsList,
    systemNames: string[]
}

// Prop shiftTargetList will contain the name and targets for each system in each shift.
// But if there are no shifts, or the last shift is deleted while editing, and then the
// user adds a shift, we won't know the system names. That's why we need property systemNames.

export const ModalEditShiftsTargets: React.FC<EditShiftsTargetsProps> = (props: EditShiftsTargetsProps) => {
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const [shiftTargetList, setShiftTargetList] = useState<_ShiftTargetsList>( {timezone:"America/New_York", list:[]} );

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function clearAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    useEffect(() => {
        setShiftTargetList(props.shiftTargetList);

        if (props.shiftTargetList.timezone !== undefined) {
            if (typeof props.shiftTargetList.timezone === "string")
                setSelectedTimezone(props.shiftTargetList.timezone);
            else
                setSelectedTimezone(props.shiftTargetList.timezone.value);
        }
        else {
            setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        }

        clearAlert();
    }, [props.shiftTargetList]);

    const setName = (value:string, index:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[index].shift.name = value;
        setShiftTargetList(s);
    }

    // Note the date comtrol stores 12:00 AM as "00:00" not "24:00".
    const setStartTime = (value:string, index:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[index].shift.start = value;
        setShiftTargetList(s);
    }
    // Note the date comtrol stores 12:00 AM as "00:00" not "24:00".
    const setEndTime = (value:string, index:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[index].shift.end = value;
        setShiftTargetList(s);
    }

    const setPounds = (value:number, shiftIndex:number, targetIndex:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[shiftIndex].targets[targetIndex].pounds = value;
        setShiftTargetList(s);
    }

    const setPoundsPerHour = (value:number, shiftIndex:number, targetIndex:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[shiftIndex].targets[targetIndex].pounds_per_hour = value;
        setShiftTargetList(s);
    }

    const setLoads = (value:number, shiftIndex:number, targetIndex:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[shiftIndex].targets[targetIndex].loads = value;
        setShiftTargetList(s);
    }

    const setLoadsPerHour = (value:number, shiftIndex:number, targetIndex:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[shiftIndex].targets[targetIndex].loads_per_hour = value;
        setShiftTargetList(s);
    }

    const setTurns = (value:number, shiftIndex:number, targetIndex:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[shiftIndex].targets[targetIndex].turns = value;
        setShiftTargetList(s);
    }

    const setTurnsPerHour = (value:number, shiftIndex:number, targetIndex:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list[shiftIndex].targets[targetIndex].turns_per_hour = value;
        setShiftTargetList(s);
    }

    // Deletes a shift by index number.
    const handleDelete = (index:number) => {
        var s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list.splice(index,1);

        // Shift at the given index number was deleted, renumber remaining shifts with ID > index.
        s.list.forEach((item:_ShiftTargets, i) => {
            if (item.shift.id > index)
                item.shift.id -= 1;
        });

        setShiftTargetList(s);
    }

    // Adds a new shift.
    const handleAdd = () => {
        // It's possible there are no shifts currently, in which case we are adding the first one.
        // That's why we need props.systemNames (the list of system names).

        // Create a new shift with default values.
        // Existing shifts have id numbers from 0 to length-1, so this new shift will have id = length.
        let newId:number = shiftTargetList.list.length;
        let newShift:_ShiftTargets = {shift: {id:newId, name:"New Shift", start:"08:00", end:"17:00"}, targets:[]};
        
        // Add one set of default targets for each system.
        props.systemNames.forEach((name:string, i) => {
            newShift.targets.push({systemName:name, pounds:1, pounds_per_hour:1, loads:1, loads_per_hour:1, turns:1, turns_per_hour:1});
        });

        let s:_ShiftTargetsList = JSON.parse(JSON.stringify(shiftTargetList));
        s.list.push(newShift);
        setShiftTargetList(s);
    };

    const handleSave = () => {
        // Note the date control stores 12:00 AM as "00:00" not "24:00".

        // Check for duplicate shift names
        let names = new Set();
        shiftTargetList.list.forEach((item) => {
            names.add(item.shift.name);        
        });

        if (shiftTargetList.list.length > names.size) {
            displayAlert("Error: Duplicate shift names are not allowed.");
            return;
        }

        // If start time > end time for a shift, that shift crosses midnight.
        // There should be no more than one shift that crosses midnight.
        let now:Date = new Date();
        let count = 0;
        shiftTargetList.list.forEach((item) => {
            // if start is after end...
            if (isAfter( get_date(now, item.shift.start, shiftTargetList.timezone), get_date(now, item.shift.end, shiftTargetList.timezone) ))
                count++;
        });

        if (count > 1) {
            displayAlert("Error: No more than one shift can cross midnight.");
            return;
        }

        count = 0;
        shiftTargetList.list.forEach((item) => {
            // if start and end dates are equal...
            if (isEqual( get_date(now, item.shift.start, shiftTargetList.timezone), get_date(now, item.shift.end, shiftTargetList.timezone) ))
                count++;
        });

        if (count > 0) {
            displayAlert("Error: Start time equals end time for one or more shifts.");
            return;
        }

        // Check for overlapping shift times.
        count = 0;
        for (var i = 0; i < shiftTargetList.list.length - 1; i++) {
            for (var j = i+1; j < shiftTargetList.list.length; j++) {
                if (checkForShiftOverlap(shiftTargetList.list[i].shift, shiftTargetList.list[j].shift))
                    count++;
            }
        }

        if (count > 0) {
            displayAlert("Error: One or more shifts have overlapping times.");
            return;
        }

        let targetError: boolean = false;

        // For each shift in shiftTargetList...
        shiftTargetList.list.forEach((s, i) => {
            // For each set of targets in this shift...
            s.targets.forEach((t, j) => {
                if (!positiveIntExpression.test(t.loads.toString()) ||
                    !positiveIntExpression.test(t.loads_per_hour.toString()) ||
                    !positiveIntExpression.test(t.pounds.toString()) ||
                    !positiveIntExpression.test(t.pounds_per_hour.toString()) ||
                    !positiveIntExpression.test(t.turns.toString()) ||
                    !positiveIntExpression.test(t.turns_per_hour.toString()))
                {
                    targetError = true;
                }
            });
        });

        if (targetError) {
            displayAlert("Error: Invalid target(s) detected. All target values must be positive whole numbers.");
            return;
        }

        let tz:string;

        // Set timezone to a string (e.g. America/New_York).
        if (typeof selectedTimezone === "string") 
            tz = selectedTimezone;
        else 
            tz = selectedTimezone.value;

        shiftTargetList.timezone = tz;

        clearAlert();

        // Return shift list to the parent.
        props.handleSave( JSON.parse(JSON.stringify(shiftTargetList)) );
    }

    const handleClose = () => {
        // Note we are not returning anything to the parent.
        clearAlert();
        props.handleClose();
    }

    return (
        <Modal show={props.show} fullscreen={true} backdrop="static" scrollable={true}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form className={classNames("mb-4")}>
                    <Form.Group>
                        <Container fluid>
                            {
                                // Iterate over the list of shifts.
                                shiftTargetList.list.map((shift:_ShiftTargets, i:number) =>
                                    <div key={i.toString()}>
                                        <Row>
                                            <Col>
                                                <Form.Label>Shift Name</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Start Time</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>End Time</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Action</Form.Label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group controlId="shift-name">
                                                    <Form.Control type="text" name="shiftname" value={shift.shift.name} onChange={event => setName(event.target.value, i)}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="start-time">
                                                    <Form.Control type="time" name="starttime" value={shift.shift.start} onChange={event => setStartTime(event.target.value, i)}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="end-time">
                                                    <Form.Control type="time" name="endtime" value={shift.shift.end} onChange={event => setEndTime(event.target.value, i)}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="delete">
                                                    <OverlayTrigger placement={'top'} overlay={<Tooltip id="del">Delete Shift</Tooltip>}>
                                                        <Button variant="outline-dark" onClick={() => handleDelete(i)}><Trash3/></Button>
                                                    </OverlayTrigger>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div className="mb-3"/>

                                        <Row>
                                            <Col>
                                                <Form.Label>System Name</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Pounds</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Pounds/Hour</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Loads</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Loads/Hour</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Turns</Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Label>Turns/Hour</Form.Label>
                                            </Col>
                                        </Row>

                                        {
                                            // Iterate over the list of targets and generate a row for each.
                                            shift.targets.map((target:_Targets, j:number) =>
                                                <Row key={j.toString()}>
                                                    <Col>
                                                        <Form.Group controlId="label">
                                                            <Form.Control type="text" value={target.systemName} disabled/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="pounds">
                                                            <Form.Control type="number" min="1" value={target.pounds} onChange={event => setPounds(Number(event.target.value), i, j)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="pounds_hr">
                                                            <Form.Control type="number" min="1" value={target.pounds_per_hour} onChange={event => setPoundsPerHour(Number(event.target.value), i, j)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="loads">
                                                            <Form.Control type="number" min="1" value={target.loads} onChange={event => setLoads(Number(event.target.value), i, j)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="loads_hr">
                                                            <Form.Control type="number" min="1" value={target.loads_per_hour} onChange={event => setLoadsPerHour(Number(event.target.value), i, j)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="turns">
                                                            <Form.Control type="number" min="1" value={target.turns} onChange={event => setTurns(Number(event.target.value), i, j)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="turns_hr">
                                                            <Form.Control type="number" min="1" value={target.turns_per_hour} onChange={event => setTurnsPerHour(Number(event.target.value), i, j)}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        <hr className="mb-4"/>
                                    </div>
                                )
                            }
                        </Container>
                    </Form.Group>
    
                    <div className={classNames("text-center", "mb-2", "mt-4")}>
                        <Button variant="primary"onClick={() => handleAdd()}>Add Shift</Button>
                    </div>

                    <p className="text-center">
                        &bull; All shifts must fit within a 24 hour period.
                        &bull; Shift times cannot overlap with another shift.
                        &bull; No more than one shift can cross midnight.
                    </p>

                    {/* This alert is for showing errors. It is always visible. */}
                    <Alert variant={showAlert ? "danger" : "light"}>
                        {alertMessage}
                    </Alert>
                
                    <Form.Group className={classNames("mb-3")} controlId="timezone">
                        <Form.Label>Timezone</Form.Label>
                        <TimezoneSelect
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                        />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
   );
}