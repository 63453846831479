import {CognitoUser} from "@aws-amplify/auth";
import * as React from "react";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {CheckNavigation} from "./CheckNavigation"

export interface PrivateRoutesProps {
    cognitoUser: CognitoUser | undefined,
    cognitoUserChanged: (newUser: CognitoUser | undefined) => void
}

export const PrivateRoutes: React.FC<PrivateRoutesProps> = (props: PrivateRoutesProps) => {

    // This component checks if a user is logged in.
    // If props.cognitoUser is defined, we know we have a valid user.
    // However, if a user directly edits the address on the browser address bar
    // the app is reinitialized and props.cognitoUser will be undefined,
    // so CheckNavigation will check if there is a valid user in local storage
    // and take the appropriate action.

    useEffect(() => {
        // console.log("PrivateRoutes Cognito user is", props.cognitoUser);
    }, [props.cognitoUser]);

    // To disable login, set the if statement to "if (true)".

    if (props.cognitoUser === undefined) {
        // console.log("PrivateRoutes user is UNDEFINED");
        return <CheckNavigation cognitoUserChanged={props.cognitoUserChanged}/>
    }
    else {
        // console.log("PrivateRoutes user is DEFINED");
        return <Outlet/>
    }
}
