import React from "react";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Link} from "react-router-dom";
import {WashnetBackend} from "../services/WashnetBackend";
import {ISite, SiteListResponse, Site} from "../models/site";
import {IUser, User, UserListResponse, UserPermissions, UserPermissionsSet} from "../models/user";
import {CustomerNode} from "../models/customer";
import {ModalEditSite} from "./ModalEditSite";
import {ModalEditUser} from "./ModalEditUser";
import {ModalEditPermissions} from "./ModalEditPermissions"
import {ModalShowMessage} from "./ModalShowMessage"
import {Pencil, Unlock, Trash3} from 'react-bootstrap-icons';
import {Data} from "../components/Data"
import {DialogType} from "../util"

export interface CustomerDetailProps {
    backend: WashnetBackend,
    currentUser: IUser | undefined
}

export const CustomerDetail: React.FC<CustomerDetailProps> = (props: CustomerDetailProps) => {
    const {customerId, customerName} = useParams();
    const [sites, setSites] = useState<ISite[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const [tree, setTree] = useState<CustomerNode | undefined>(undefined);

    function sortUserList(list: IUser[]): IUser[] {
        return list.sort( (user1: IUser, user2: IUser) => {
            if (user1.last === undefined || user2.last === undefined)
                return 0;
            let c = user1.last.localeCompare(user2.last);
            if (c === 0) {
                if (user1.first !== undefined && user2.first !== undefined)
                    c = user1.first.localeCompare(user2.first);
            }
            return c;
        })
    }

    // Function to read the user list from the backend.
    // Called from useEffect and when the user list is edited.
    var refreshUserList = () => {
        props.backend.getUsersForCustomer(customerId as string)
        .then((response: UserListResponse) => {
            setUsers( sortUserList(response.users) );
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    // Function to read the site list from the backend.
    // Called from useEffect and when the site list is edited.
    var refreshSiteList = () => {
        props.backend.getSitesForCustomer(customerId as string)
        .then((response: SiteListResponse) => {
            setSites(response.sites)
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined) {
            console.log("customerId is undefined");
            return;
        }

        // TODO: DETERMINE HOW TO REPLACE THE FOLLOWING PROMISES WITH refreshUserList AND refreshSiteList.
        
        // Get site list for this customer.
        props.backend.getSitesForCustomer(customerId).then( (response:SiteListResponse) => {
            setSites(response.sites);
            // Get user list for this customer.
            return props.backend.getUsersForCustomer(customerId);
        })
        .then( (response:UserListResponse) => {
            setUsers( sortUserList(response.users) );
            // Get tree for this customer.
            // Gets only those tree items the user has permission to see.
            return props.backend.getTree(customerId);
        })
        .then( (tree:CustomerNode) => {
            setTree(tree);
        })
        .catch((error) => {
            displayAlert(error.message);
        })

    }, [props.currentUser, customerId]);

    ////////////////////////////////////////////////////////////////////

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    ////////////////////////////////////////////////////////////////////

    // Values sent to the add/edit dialogs.
    const [siteValues, setSiteValues] = useState<ISite>(new Site());

    ///////////////////////////////////
    // For Add Site modal dialog
    const [showAddDialog, setShowAddDialog] = useState(false);

    const handleShowAddDialog = () => {
        setSiteValues(new Site());
        setShowAddDialog(true);
    }
        
    // This is a callback called by the child component ModalEditSite.
    // The child passes the new site values in parameter site.
    const handleSaveAddDialog = (site:ISite) => {
        setShowAddDialog(false);

        // Create a new site in the backend.
        props.backend.createSite(customerId as string, site)
        .then((site: ISite) => {
            refreshSiteList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseAddDialog = () => {
        setShowAddDialog(false);
    }

    ///////////////////////////////////
    // For Edit Site modal dialog
    const [showEditDialog, setShowEditDialog] = useState(false);

    // Opens editor for a site (row) in the table.
    const handleShowEditDialog = (index:number) => {
        // This does a deep copy.
        setSiteValues(JSON.parse(JSON.stringify(sites[index])));

        setShowEditDialog(true);
    }

    // This is a callback called by the child component ModalEditSite.
    // The child passes the updated site values in parameter site.
    const handleSaveEditDialog = (site:ISite) => {
        setShowEditDialog(false);

        // Update this site in the backend.
        props.backend.updateSite(customerId as string, site)
        .then((site: ISite) => {
            refreshSiteList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    }

    // Deletes a site (row) from the table.
    const handleDelete = (index:number) => {
        // index is row clicked in the table

        // Delete this site from the backend.
        props.backend.deleteSite( customerId as string, sites[index].id )
        .then(() => {
            refreshSiteList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    ////////////////////////////////////////////////////////////////////

    // User values sent to the add/edit dialogs.
    const [userValues, setUserValues] = useState<IUser>(new User());

    ///////////////////////////////////
    // For Add User modal dialog
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);

    const handleShowAddUserDialog = () => {
        setUserValues(new User());
        setShowAddUserDialog(true);
    }
        
    // This is a callback called by the child component ModalEditUser.
    // The child passes the new user values in parameter user.
    const handleSaveAddUserDialog = (user:IUser) => {
        setShowAddUserDialog(false);

        // Set this user's customer ID.
        user.customerId = customerId as string;

        // Create a new user in the backend.
        props.backend.createUser(customerId as string, user)
        .then((user: IUser) => {
            refreshUserList();
        })
        .then(() => {
            // A new user was just created. Remind the user to set permissions.
            displayAlert("Remember to set permissions for this user.");
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseAddUserDialog = () => {
        setShowAddUserDialog(false);
    }

    ///////////////////////////////////
    // For Edit User modal dialog
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);

    // Opens editor for a user (row) in the table.
    const handleShowEditUserDialog = (index:number) => {
        // This does a deep copy.
        setUserValues(JSON.parse(JSON.stringify(users[index])));

        setShowEditUserDialog(true);
    }

    // This is a callback called by the child component ModalEditUser.
    // The child passes the updated user values in parameter user.
    const handleSaveEditUserDialog = (user:IUser) => {
        setShowEditUserDialog(false);

        // Update this user in the backend.
        props.backend.updateUser(customerId as string, user)
        .then((user: IUser) => {
            refreshUserList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseEditUserDialog = () => {
        setShowEditUserDialog(false);
    }

    // Deletes a user (row) from the table.
    const handleDeleteUser = (index:number) => {
        // index is row clicked in the table

        // Delete this user from the backend.
        props.backend.deleteUser( customerId as string, users[index].userId )
        .then(() => {
            refreshUserList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    ////////////////////////////////////////////////////////////////////
    // For Edit User Permission modal dialog
    const [showPermissionDialog, setShowPermissionDialog] = useState(false);

    // User values sent to the edit permission dialog.
    const [userValuesForPermissionEditor, setUserValuesForPermissionEditor] = useState<IUser>(new User());

    const handleShowPermissionDialog = () => {
        setShowPermissionDialog(true);
    }

    // Returns the elements in array A that are not in array B.
    // This is like A - B, the difference operation on sets.
    function difference( A:string[], B:string[] ):string[] {
        return A.filter(x => !B.includes(x));
    }
        
    // This is a callback called by the ModalEditPermissions editor.
    // The editor passes the updated permissions to us in parameter perms.
    const handleSavePermissionDialog = (perms:UserPermissionsSet) => {
        setShowPermissionDialog(false);

        let user:IUser = JSON.parse(JSON.stringify( userValuesForPermissionEditor ));

        // user contains the ro, rw and admin permissions BEFORE editing.
        // perms contains the ro, rw and admin permissions AFTER editing.

        // Determine the permissions that were added and deleted by
        // calculating the difference between each permission set before
        // and after editing. We do this by calling the difference function.

        // These are permissions that were added.
        // For example, permissions in array perms.ro that are not in user.ro were ADDED.
        let addPerms:UserPermissions = {
            ro: difference(perms.ro!, user.ro!),
            rw: difference(perms.rw!, user.rw!),
            admin: difference(perms.admin!, user.admin!)
        };

        // These are permissions that were deleted.
        // For example, permissions in array user.ro that are not in perms.ro were DELETED.
        let deletePerms:UserPermissions = {
            ro: difference(user.ro!, perms.ro!),
            rw: difference(user.rw!, perms.rw!),
            admin: difference(user.admin!, perms.admin!)
        };

        user.customerAdmin = perms.customerAdmin;
        user.superuser = perms.superuser;

        // Write permissions to the backend.
        // First write the permissions to be deleted.
        props.backend.deleteUserGrants(customerId!, user.userId, deletePerms)
        .then((response: string) => {
            // Second write the permissions to be added.
            return props.backend.addUserGrants(customerId!, user.userId, addPerms);
        })
        .then((response: string) => {
            // We updateUser in case user.customerAdmin
            // or user.superuser has changed.
            // Don't pass the following to updateUser.
            // These permissions (grants) were written above.
            user.ro = undefined;
            user.rw = undefined;
            user.admin = undefined;

            return props.backend.updateUser(customerId!, user);
        })
        .then((user: IUser) => {
            refreshUserList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleClosePermissionDialog = () => {
        setShowPermissionDialog(false);
    }

    // Opens the permissions editor for a user (row) in the table.
    const handleEditPermission = (index:number) => {
        // index is the index of the row clicked in the table.

        // The users array, returned by getUsersForCustomer(), doesn't
        // include the ro, rw and admin arrays, so we call getUser because
        // it returns these arrays, which are needed in the edit permissions dialog.
        // The ro, rw and admin arrays returned by getUser() will be in one of two states:
        //   [] an empty array, meaning the associated string set doesn't exist in the DynamoDB table
        //   ["somestring", ...] an array of one or more strings, meaning the associated string set exists in the DynamoDB table and contains those strings
        props.backend.getUser(users[index].customerId, users[index].userId)
        .then((user: IUser) => {
            setUserValuesForPermissionEditor(user);
            setShowPermissionDialog(true);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    return(
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>Administration Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/customers" }}>Manage Companies</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/customers/${customerId}/${customerName}` }}>{customerName}</Breadcrumb.Item>
            </Breadcrumb>

            <h4>{`Manage Company: ${customerName}`}</h4>

            <div className="mb-4"/>
            <h5>Sites</h5>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Site Name</th>
                        <th>Site ID</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    sites.map((site: ISite, i: number) =>
                        <tr key={i.toString()}>
                            <td><Link to={`/admin/sites/${customerId}/${customerName}/${site.id}/${site.name}`}><Data value={site.name}/></Link></td>
                            <td><Data value={site.id}/></td>
                            <td><Data value={site.desc}/></td>
                            <td>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="edit">Edit Site</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleShowEditDialog(i)} className={classNames("me-2")}><Pencil/></Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="delete">Delete Site</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleDelete(i)}><Trash3/></Button>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <Button variant="primary" onClick={handleShowAddDialog}>Add Site</Button>

            <hr/>

            <div className="mb-4"/>
            <h5>Users</h5>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Username (Email)</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    users.map((user: IUser, i: number) =>
                        <tr key={i.toString()}>
                            <td><Data value={user.last}/>, <Data value={user.first}/></td>
                            <td><Data value={user.email}/></td>
                            <td>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="ed">Edit User</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleShowEditUserDialog(i)} className={classNames("me-2")}><Pencil/></Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="perm">Edit User Permission</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleEditPermission(i)} className={classNames("me-2")}><Unlock/></Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="del">Delete User</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleDeleteUser(i)}><Trash3/></Button>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <Button variant="primary" onClick={handleShowAddUserDialog}>Add User</Button>
            <hr/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />

            {/* Modal dialog to add a site. */}
            <ModalEditSite show={showAddDialog}
                title={"Add Site"}
                type={DialogType.ADD}
                handleSave={handleSaveAddDialog}
                handleClose={handleCloseAddDialog}
                siteValues={siteValues}
            />

            {/* Modal dialog to edit an existing site. */}
            <ModalEditSite show={showEditDialog}
                title={"Edit Site"}
                type={DialogType.EDIT}
                handleSave={handleSaveEditDialog}
                handleClose={handleCloseEditDialog}
                siteValues={siteValues}
            />

            {/* Modal dialog to add a user. */}
            <ModalEditUser show={showAddUserDialog}
                title={"Add User"}
                type={DialogType.ADD}
                handleSave={handleSaveAddUserDialog}
                handleClose={handleCloseAddUserDialog}
                user={userValues}
            />

            {/* Modal dialog to edit an existing user. */}
            <ModalEditUser show={showEditUserDialog}
                title={"Edit User"}
                type={DialogType.EDIT}
                handleSave={handleSaveEditUserDialog}
                handleClose={handleCloseEditUserDialog}
                user={userValues}
            />

            {/* Modal dialog to edit an existing user's permissions. */}
            <ModalEditPermissions show={showPermissionDialog}
                title={"Edit User Permissions"}
                type={DialogType.EDIT}
                handleSave={handleSavePermissionDialog}
                handleClose={handleClosePermissionDialog}
                user={userValuesForPermissionEditor}
                currentUser={props.currentUser}
                customerTree={tree}
            />
        </>
    );
}
