import Table from 'react-bootstrap/Table';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {WashnetBackend} from "../services/WashnetBackend";
import {ReportHeader} from '../components/ReportHeader';
import {AlarmSummaryRequest, AlarmSummaryResponse} from "../models/reports"
import {ModalShowMessage} from "../admin/ModalShowMessage"
import {ReportParams} from "../models/reports";
import {Data} from "../components/Data"

export interface AlarmSummaryProps {
    backend: WashnetBackend
}

export const ReportAlarmSummary: React.FC<AlarmSummaryProps> = (props: AlarmSummaryProps) => {

    const {customerId, siteId, systemId, machineId} = useParams();
    const [alarmSummary, setAlarmSummary] = useState<AlarmSummaryResponse>();

    const state:ReportParams = useLocation().state;

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined || machineId === undefined) {
            console.log("customerId, siteId, systemId or machineId is undefined");
            return;
        }

        // Outgoing to backend.
        let params: AlarmSummaryRequest = {
            customerId: customerId,
            siteId: siteId,
            systemId: systemId,
            machineId: machineId,
            startDate: state.startDate,
            endDate: state.endDate
        }

        props.backend.getAlarmSummaryReport(params)
        .then((response: AlarmSummaryResponse) => {
            setAlarmSummary(response)
        })
        .catch((error) => {
            setAlertMessage(error.message);
            setShowAlert(true);
        })

    }, [customerId, siteId, systemId, machineId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleCloseMessage = () => {
        setShowAlert(false);
    }

    return (
        <div>
            <ReportHeader title="Alarm Summary" machineName={state.machineName} startDate={state.startDate} endDate={state.endDate} timezone={state.timezone} />

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Count</th>
                    <th>Alarm</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    alarmSummary !== undefined &&
                    <>
                        {
                        alarmSummary.response.map((item, j) =>
                            <tr key={j.toString()}>
                            <td><Data value={item.count}/></td>
                            <td><Data value={item.alarm}/></td>
                            </tr>
                        )
                        }
                    </>
                    }
                </tbody>
            </Table>
            <hr/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseMessage}
            />

        </div>
    );
}
