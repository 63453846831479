import classNames from "classnames";
import {State} from "../models/types";
import {getLedColor} from '../util'

export interface LedBarProps {
    machineStates: State[]
}

export const LedBar: React.FC<LedBarProps> = (props: LedBarProps) => {
    return (

        <span>
            {
            props.machineStates.map((item, i) =>
                <img key={i.toString()} className={classNames("pe-0")} alt="led" src={getLedColor(item.productivityState)}/>
            )
            }
        </span>
    );
}
