import React from "react";
import {MultiBarChart} from '../components/MultiBarChart';
import {ProductionTotals, IProductionTargets} from "../models/types";

export interface ProductionSummaryProps {
    totals?: ProductionTotals,
    targets?: IProductionTargets
}

export const ProductionSummary: React.FC<ProductionSummaryProps> = (props: ProductionSummaryProps) => {

    return (
        <MultiBarChart totals={props.totals} targets={props.targets}/>
    );
}
