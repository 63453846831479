import React, {useContext} from "react";
import {Spinner} from "react-bootstrap";
import {WashnetContext} from "../services/WashnetContext";
import {ThemeContext} from "../App";

export interface BusySpinnerProps {
}

export const BusySpinner: React.FC<React.PropsWithoutRef<BusySpinnerProps>> = (props: BusySpinnerProps) => {
    const theme = useContext(ThemeContext);
    const backend = useContext(WashnetContext);

    return (
        // <span>{backend?.isBusy ? "true" : "false"}</span>

        <Spinner animation="border"
                    role="status"
                    variant={theme.theme === "dark" ? "light" : "dark"}
                    size="sm"
                    className={backend?.isBusy ? "" : "visually-hidden"}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    )
}
