import classNames from "classnames";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Alert from "react-bootstrap/Alert";
import {useParams} from 'react-router-dom';
import {WashnetBackend} from "../services/WashnetBackend";
import {ISite, Site} from "../models/site";
import {MachineStatusResponse} from "../models/machine";
import {IMachine, MachineListResponse, MachineAlarmsResponse, MachineAlarm} from "../models/machine";
import {getProductivityColor, getMachineColor, getNetworkColor, unixTimeToString} from "../util";
import Button from "react-bootstrap/Button";
import {ArrowClockwise} from 'react-bootstrap-icons';
import washer from "../assets/washer.png";
import dryer from "../assets/dryer.png";
import tunnel from "../assets/tunnel.png";
import press from "../assets/press.png";
import generic from "../assets/generic_machine.png";
import {ModalShowMessage} from "../admin/ModalShowMessage";
import {getUnixTime, sub} from 'date-fns';

export interface MachineViewProps {
    backend: WashnetBackend
}

// let testAlarms = 
// {
//     alarms: [
//         {
//             machineId: "shuttle2",
//             alarmName: "6 - TRACK MISALIGNED",
//             timestamp: 1694520000
//         },
//         {
//             machineId: "shuttle2",
//             alarmName: "66 - EXHAUST OVERTEMP",
//             timestamp: 1694520060
//         },
//         {
//             machineId: "shuttle2",
//             alarmName: "6 - TRACK MISALIGNED",
//             timestamp: 1694520120
//         },
//         {
//             machineId: "shuttle2",
//             alarmName: "8 - CONTROL BUSS ALARM",
//             timestamp: 1694520180
//         },
//         {
//             machineId: "shuttle2",
//             alarmName: "12 - DOOR OPEN",
//             timestamp: 1694520240
//         },
//         {
//             machineId: "shuttle2",
//             alarmName: "6 - TRACK MISALIGNED",
//             timestamp: 1694520300
//         },
//         {
//             machineId: "shuttle2",
//             alarmName: "6 - TRACK MISALIGNED",
//             timestamp: 1694520360
//         }
//     ]
// }

// let testStatus =
// {
//     networkStatus: "ONLINE",
//     machineStatus: "MOVING TO STANDBY",
//     productivityState: "IN_MANUAL",
//     formulaMsg: "Red Formula",
//     customerMsg: "Park Hotel",
//     itemMsg: "Blankets",
//     timestamp: 1694520000,
//     agentStatus: "",
//     agentTimestamp: 0,
//     isEmpty: false
// }

export const MachineView: React.FC<MachineViewProps> = (props: MachineViewProps) => {
    const {customerId, customerName, siteId, siteName, systemId, systemName, machineId, machineName} = useParams();
    const [machineStatus, setMachineStatus] = useState<MachineStatusResponse>();
    const [machines, setMachines] = useState<IMachine[]>([]);
    const [machineAlarms, setMachineAlarms] = useState<MachineAlarm[]>([]);
    const [site, setSite] = useState<ISite>( new Site() );
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);    // For the Report Builder button.

    // Handler for the refresh button.
    function refreshPage() {
        setButtonDisabled(true);

        // TODO: Figure out how to get rid of this imperative (!).
        props.backend.getMachineStatus(customerId!, siteId!, systemId!, machineId!).then( (response:MachineStatusResponse) => {
            setMachineStatus(response);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
        .finally(() => {
            setButtonDisabled(false);
        })
    }

    // Returns a machine image.
    function renderImage(machineId) {
        if (machineId === undefined || machines === undefined)
            return null;
        
        // Look up machine type for this machineId in the machines array.
        const machine = machines.find((element) => element.id === machineId);

        // Not found.
        if (machine === undefined)
            return null;

        var type:string = machine.type.toLowerCase();

        // For machines that aren't a washer, dryer, tunnel or press, return a generic icon.
        if (type === "washer")
            return <img src={washer} alt="washer" width="85" height="114"/>
        else if (type === "dryer")
            return <img src={dryer} alt="dryer" width="85" height="114"/>
        else if (type === "tunnel")
            return <img src={tunnel} alt="tunnel" width="281" height="96"/>
        else if (type === "press")
            return <img src={press} alt="press" width="103" height="111"/>
        else
            return <img src={generic} alt="generic" width="145" height="114"/>
    }

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined || machineId === undefined) {
            console.log("customerId or siteId or systemId or machineId is undefined");
            return;
        }

        setButtonDisabled(true);

        // Request the current data for this machine.
        // Note we don't specify start time and end time because we are getting the current snapshot of machine status.
        props.backend.getMachineStatus(customerId, siteId, systemId, machineId).then( (response:MachineStatusResponse) => {
            setMachineStatus(response);
            // Request the list of machines for the system in which this machine is a member.
            return props.backend.getMachinesForSystem(customerId, siteId, systemId);
        })
        .then( (response:MachineListResponse) => {
            setMachines(response.machines);

            // Request the list of alarms for this machine for the last 24 hours.
            var now:Date = new Date();
            var endMs:number = getUnixTime(now);
            var startMs:number = getUnixTime(sub(now, {hours:24}));
            
            // TODO: Delete these times, for testing only.
            // startMs = 1699448400;
            // endMs = 1699480800;
            // console.log(`start time is ${startMs} end time is ${endMs}`);
    
            return props.backend.getMachineAlarms(customerId, siteId, systemId, machineId, startMs, endMs);
        })
        .then( (response:MachineAlarmsResponse) => {
            setMachineAlarms(response.alarms);

            return props.backend.getSite(customerId, siteId);
        })
        .then((response: ISite) => {
            setSite(response);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
        .finally(() => {
            setButtonDisabled(false);
        })

    }, [customerId, siteId, systemId, machineId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function handleCloseAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    ///////////////////////////////////

    function isDataStale(timestamp: number): boolean {
        // Timestamp is UNIX time in UTC.

        // Get current time as UNIX time in UTC.
        let now = getUnixTime(new Date());

        // Return true if timestamp is older than 1860 seconds (31 minutes), false otherwise.
        return (now - timestamp > 1860);
    }

    ///////////////////////////////////

    return (
        <div>
            {/* <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/Customer/${customerId}/${customerName}` }}>{customerName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/Site/${customerId}/${customerName}/${siteId}/${siteName}` }}>{siteName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/System/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}/${systemName}` }}>{systemName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/Machine/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}/${systemName}/${machineId}/${machineName}` }}>{machineName}</Breadcrumb.Item>
            </Breadcrumb> */}

            <div className="d-flex justify-content-between ms-1 mb-4">
                <h4 className="mt-3 braun-text">{`Summary for ${machineName}`}</h4>
                <Button className={classNames("me-2")} variant="light" size="lg" onClick={() => refreshPage()}><ArrowClockwise/></Button>
            </div>

            <div className="d-flex justify-content-between ms-1 mb-4">
                {renderImage(machineId)}

                {
                    buttonDisabled && 
                    <div>
                        <Button className={"me-3"} disabled={true}>Report Builder</Button>
                    </div>
                }

                {
                    // Pass machine list in state parameter, the report builder needs to populate one of its dropdowns with the list of machines.
                    (!buttonDisabled) && 
                    <Link to={`/Report/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}/${systemName}`} state={{machines}}>
                        <Button className={"me-3"} disabled={false}>Report Builder</Button>
                    </Link>
                }
            </div>

            {
                // Data is still loading.
                (machineStatus === undefined) &&
                <Alert variant="info">Loading machine status...</Alert>
            }
            {
                // No data returned for this machine.
                (machineStatus !== undefined && machineStatus.isEmpty) &&
                <Alert variant="warning">No current data available for this machine.</Alert>
            }
            {
                // Data is stale (older than 30 minutes).
                (machineStatus !== undefined && !machineStatus.isEmpty && isDataStale(machineStatus.timestamp)) &&
                <Alert variant="warning">Machine data is older than thirty minutes.</Alert>
            }
            {
                machineStatus !== undefined &&
                <>
                    <Form>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Form.Label className={classNames("braun-text")}><b>Network Status</b></Form.Label>
                                    <Form.Group className={classNames("mb-3")} controlId="network">
                                        <Form.Control type="text" value={machineStatus.networkStatus} readOnly style={{backgroundColor: getNetworkColor(machineStatus.networkStatus)}}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className={classNames("braun-text")}><b>Machine Status</b></Form.Label>
                                    <Form.Group className={classNames("mb-3")} controlId="machine">
                                        {/* We used to determine color from the machine status message. Now we determine color from the productivity state message. */}
                                        <Form.Control type="text" value={machineStatus.machineStatus} readOnly style={{backgroundColor: getMachineColor(machineStatus.productivityState)}}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className={classNames("braun-text")}><b>Productivity State</b></Form.Label>
                                    <Form.Group className={classNames("mb-3")} controlId="productivity">
                                        <Form.Control type="text" value={machineStatus.productivityState} readOnly style={{backgroundColor: getProductivityColor(machineStatus.productivityState)}}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className={classNames("braun-text")}><b>Item</b></Form.Label>
                                    <Form.Group className={classNames("mb-3")} controlId="item">
                                        <Form.Control type="text" value={machineStatus.itemMsg} readOnly/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className={classNames("braun-text")}><b>Formula</b></Form.Label>
                                    <Form.Group className={classNames("mb-3")} controlId="formula">
                                        <Form.Control type="text" value={machineStatus.formulaMsg} readOnly/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className={classNames("braun-text")}><b>Customer</b></Form.Label>
                                    <Form.Group className={classNames("mb-3")} controlId="customer">
                                        <Form.Control type="text" value={machineStatus.customerMsg} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </>
            }

            <hr/>

            <h5 className="mb-3 braun-text">Recent Alarms</h5>
            {
                (machineAlarms.length === 0) &&
                <Alert variant="warning">No alarms occurred in the last 24 hours.</Alert>
            }
            {
                (machineAlarms.length > 0) &&
                <div>
                    <Container fluid>
                        <Row>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Alarm Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    machineAlarms.map((alarm: MachineAlarm, i: number) =>
                                    <tr key={`s${i}`}>
                                        <td>{unixTimeToString(alarm.timestamp, true, site.cfg.shiftList.timezone.toString())}</td>
                                        <td>{alarm.alarmName}</td>
                                    </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Row>
                    </Container>
                </div>
            }

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />

     </div>
    );
  }