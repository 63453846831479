import classNames from "classnames";
import {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import {ArrowClockwise} from 'react-bootstrap-icons';
import {useParams} from 'react-router-dom';
import {Link} from "react-router-dom";
import {WashnetBackend} from "../services/WashnetBackend";
import {ISite, Site, SiteConfig} from "../models/site";
import {ISystem, SystemStatusResponse, SystemListResponse, SystemConfig, SystemTargets} from "../models/system";
import {IMachine, MachineListResponse} from "../models/machine";
import {AlarmSummary} from '../components/AlarmSummary';
import {StatusSummary} from '../components/StatusSummary';
import {ProductionSummary} from '../components/ProductionSummary';
import {IProductionTargets, ProductionTargets, ProductionTotals, _ShiftTargetsList, ShiftUnixTime} from '../models/types';
import {ModalShowMessage} from "../admin/ModalShowMessage"
import {getCurrentShift} from "../util";
import {ModalEditShiftsTargets} from "../components/ModalEditShiftsTargets";

export interface SystemViewProps {
    backend: WashnetBackend
}

export const SystemView: React.FC<SystemViewProps> = (props: SystemViewProps) => {
    const {customerId, customerName, siteId, siteName, systemId, systemName} = useParams();
    const [systemStatus, setSystemStatus] = useState<SystemStatusResponse>();

    const [systems, setSystems] = useState<ISystem[]>([]);
    const [systemNames, setSystemNames] = useState<string[]>([]);

    const [machines, setMachines] = useState<IMachine[]>([]);
    const [site, setSite] = useState<ISite>( new Site() );
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    const [currentShift, setCurrentShift] = useState<ShiftUnixTime>();
    const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(true);    // For the Shifts/Targets and Report Builder buttons.

    // True if agent is connected, false otherwise.
    function isAgentConnected(systemId:string): boolean {
        const sys = systems.find((element) => element.id === systemId);
        if (sys === undefined) {
            // Agent status not found.
            return false;
        }
        return (sys.agentStatus.toLowerCase() !== "disconnected");
    }

    function totalsAreZero(totals: ProductionTotals): boolean {
        return (totals.loads === 0 && totals.loads_per_hour === 0 && totals.pounds === 0 &&
                totals.pounds_per_hour === 0 && totals.turns === 0 && totals.turns_per_hour === 0);
    }

    // Gets the current shift and returns the shift start and end times.
    function getShiftTimes(cfg:SiteConfig): [number, number] {
        let start:number = 0;
        let end:number = 0;
        let now:Date = new Date();

        var s = getCurrentShift(now, cfg.shiftList);
        if (s !== undefined) {
            start = s.start;
            end = s.now;
        }
        // TODO: Delete these times, for testing only.
        // start = 1699448400;
        // end = 1699480800;
        // console.log(`start time is ${start} end time is ${end}`);

        setCurrentShift(s)
        setStartTime(start);
        setEndTime(end);

        return [start, end];
    }

    // Get system targets for given system and the current shift.
    function getTargetsForSystem(systemId:string): IProductionTargets {
        const shiftId = currentShift?.id;
        const sys = systems.find((element) => element.id === systemId);
        if (sys !== undefined) {
            const s = sys.cfg.shiftList.find((element) => element.shiftId === shiftId);
            if (s !== undefined) {
                return s.targets;
            }
        }

        // Targets not found for system, return default targets.
        console.log(`Targets not found for system ${systemId}, using default targets.`);
        return new ProductionTargets();
    }

    // Handler for the refresh button.
    function refreshPage() {
        setButtonsDisabled(true);
        var [start, end] = getShiftTimes(site.cfg);

        // Request the system status.
        // TODO: Figure out how to get rid of this imperative (!).
        props.backend.getSystemStatus(customerId!, siteId!, systemId!, start, end)
        .then( (response:SystemStatusResponse) => {
            setSystemStatus(response);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
        .finally(() => {
            setButtonsDisabled(false);
        })
    }

    // Function to read the site from the backend.
    // Called from useEffect and when the site production targets are edited.
    var refreshSite = () => {
        setButtonsDisabled(true);
        props.backend.getSite(customerId as string, siteId as string)
        .then((response: ISite) => {
            setSite(response);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
        .finally(() => {
            setButtonsDisabled(false);
        })
    }

    // Function to read the systems for this site from the backend.
    // Called from useEffect and when a systems production targets are edited.
    var refreshSystems = () => {
        setButtonsDisabled(true);
        props.backend.getSystemsForSite(customerId as string, siteId as string)
        .then((response: SystemListResponse) => {
            setSystems(response.systems);

            // Save the system names.
            let names:string[] = [];
            response.systems.forEach((sys:ISystem, i) => {
                names.push(sys.name);
            });
            setSystemNames(names);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
        .finally(() => {
            setButtonsDisabled(false);
        })
    }

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined) {
            console.log("customerId or siteId or systemId is undefined");
            return;
        }

        setButtonsDisabled(true);

        // TODO: DETERMINE HOW TO REPLACE THE FOLLOWING PROMISES WITH refreshSystems.
        // Request the site, which includes the shift list.
        props.backend.getSite(customerId, siteId)
        .then((response: ISite) => {
            setSite(response);

            var [start, end] = getShiftTimes(response.cfg);

            // Request the system status.
            return props.backend.getSystemStatus(customerId, siteId, systemId, start, end);
        })
        .then( (response:SystemStatusResponse) => {
            setSystemStatus(response);
            // Request the target settings (and other info) for all systems for this site.
            return props.backend.getSystemsForSite(customerId, siteId);
        })
        .then( (response:SystemListResponse) => {
            setSystems(response.systems);

            // Save the system names.
            let names:string[] = [];
            response.systems.forEach((sys:ISystem, i) => {
                names.push(sys.name);
            });
            setSystemNames(names);

            // Request the list of machines for this system.
            return props.backend.getMachinesForSystem(customerId, siteId, systemId);
        })
        .then( (response:MachineListResponse) => {
            setMachines(response.machines);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
        .finally(() => {
            setButtonsDisabled(false);
        })
    }, [customerId, siteId, systemId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function handleCloseAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    ///////////////////////////////////
    // For editing shifts and targets

    // We edit the site shift list and the targets for each
    // system within this site, all together in the same editor.
    //
    // So we combine the site shift list and the targets for each
    // system in an object and pass this to the editor.
    //
    // When the editor closes it returns to us an edited object.
    // We get the shift list and target info from the object and
    // write this info to the site and each system.

    const [shiftTargetList, setShiftTargetList] = useState<_ShiftTargetsList>( {timezone:"America/New_York", list:[]} );

    // For modal dialog
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    // This function should match handleShow in SiteView.tsx.
    const handleShow = () => {

        // It's important that a site and each of its systems be kept in sync.
        // So make the number of shifts at the site match the number
        // of shifts at each system within this site.
        // The site is the master - make each system match the site.

        // These are the scenarios we can encounter:
        // case     shifts at site      shifts at system        How can this happen?
        // ---------------------------------------------------------------------------------------
        // 1        none                none                    site and system were just created
        // 2        greater than 0      none                    site exists, system was just added
        // 3        none                greater than 0          system exists, site was just added
        // 4        greater than 0      greater than 0          this is normal

        // The only way case 3 can happen is a site was deleted, but it's systems
        // were not (the systems remained in DynamoDB), then a new site was added
        // with exactly the same customer ID and site ID as the deleted site.
        // This is unlikely but can happen.

        // THE FOLLOWING WORKS AS LONG AS SHIFT IDS AT THE SITE MATCH SHIFT IDS AT THE SYSTEM.

        let numShifts:number = site.cfg.shiftList.shifts.length;

        // For each shift at the site...
        site.cfg.shiftList.shifts.forEach((s, i) => {
            // For each system at this site...
            systems.forEach((sys, j) => {
                let num:number = sys.cfg.shiftList.length;

                // Add shifts to the system if necessary.
                while (num < numShifts) {
                    sys.cfg.shiftList.push( {shiftId: num, targets:{pounds:1, pounds_per_hour:1, loads:1, loads_per_hour:1, turns:1, turns_per_hour:1}} );
                    num++;
                }

                // Remove shifts from the system if necessary.
                while (num > numShifts) {
                    sys.cfg.shiftList.pop();
                    num--;
                }
            });
        });

        // Assemble the shift info (from the site) and target info (from each system)
        // into a _ShiftTargetsList that we will send to the editor.

        let theList: _ShiftTargetsList = {
            timezone: site.cfg.shiftList.timezone,
            list: []
        }

        // For each shift at the site...
        site.cfg.shiftList.shifts.forEach((s, i) => {
            theList.list.push( {shift:{id:s.id, name:s.name, start:s.start, end:s.end}, targets:[]} );

            // For each system at this site...
            systems.forEach((sys, j) => {
                // Get the target set that matches s.id (the current shift ID)
                let element = sys.cfg.shiftList.at(s.id);
                if (element !== undefined) {
                    theList.list[i].targets.push(
                        {
                            systemName: sys.name,
                            pounds: element.targets.pounds,
                            pounds_per_hour: element.targets.pounds_per_hour,
                            loads: element.targets.loads,
                            loads_per_hour: element.targets.loads_per_hour,
                            turns: element.targets.turns,
                            turns_per_hour: element.targets.turns_per_hour
                        }
                    )
                }
                else {
                    // This should not happen.
                    console.log(`Cannot find shift ${s.id} for system ${sys.name} in handleShow, saving default targets.`);
                    theList.list[i].targets.push( {systemName: sys.name, pounds:1, pounds_per_hour:1, loads:1, loads_per_hour:1, turns:1, turns_per_hour:1} );
                }
            });
        });

        // Save theList, it will be passed to the editor.
        setShiftTargetList(theList);

        setShow(true);
    }

    // This function should match handleSave in SiteView.tsx.
    const handleSave = (theList:_ShiftTargetsList) => {
        // theList is passed to us when Save button is pushed in the editor.
        setShow(false);

        // Get the info from theList and put it in the site and system objects that we will write to the backend.

        // First do the site object.

        let newCfg: SiteConfig  = {
            shiftList: {timezone: theList.timezone, shifts:[]}
        }

        // For each shift in theList...
        theList.list.forEach((s, i) => {
            newCfg.shiftList.shifts.push( {id:i, name:s.shift.name, start:s.shift.start, end:s.shift.end, targets: new ProductionTargets()} );

            // The site targets are a rollup of all system targets within this site.
            // So sum up all of the system targets for this shift and save these in the site cfg.
            let targets = new ProductionTargets(0,0,0,0,0,0);
            s.targets.forEach((tar, j) => {
                targets.loads += tar.loads;
                targets.loads_per_hour += tar.loads_per_hour;
                targets.pounds += tar.pounds;
                targets.pounds_per_hour += tar.pounds_per_hour;
                targets.turns += tar.turns;
                targets.turns_per_hour += tar.turns_per_hour;
            });

            newCfg.shiftList.shifts[i].targets = targets;
        });

        // Do I need to make a copy?
        // I think so, you shouldn't mutate a state variable without using the set method returned from useState.
        // Put the updated cfg object in the site object.
        let newSite:ISite = JSON.parse(JSON.stringify(site));
        newSite.cfg = newCfg;

        // Now do the system objects. 

        // A temporary map to hold a SystemConfig (targets) for each system.
        // Map key is system name, value is a SystemConfig object.
        const systemConfigs = new Map<string, SystemConfig>();

        // For each shift in theList...
        theList.list.forEach((s, i) => {
            // For each set of targets in the shift (one set for each system)...
            s.targets.forEach((tar, j) => {
                let targetSet: SystemTargets = {shiftId: s.shift.id, targets: new ProductionTargets(tar.pounds, tar.pounds_per_hour, tar.loads, tar.loads_per_hour, tar.turns, tar.turns_per_hour)}

                // If there's already a SystemConfig for this system, add the target set to it. 
                if (systemConfigs.has(tar.systemName)) {
                    let config = systemConfigs.get(tar.systemName);
                    config?.shiftList.push(targetSet);
                }
                // Otherwise create a new SystemConfig for this system and add the target set to it. 
                else {
                    systemConfigs.set(tar.systemName, {shiftList: [targetSet]});
                }
            });
        });

        let newSystems:ISystem[] = [];

        // key is a system name, value is SystemConfig object.
        systemConfigs.forEach(function (value, key) {
            // Find the old unedited system object.
            const oldSys = systems.find((item) => item.name === key);

            // Save the updated cfg object in a new system object.
            if (oldSys !== undefined) {
                // Do I need to make a copy?
                // I think so, you shouldn't mutate a state variable without using the set method returned from useState.
                var newSys:ISystem = JSON.parse(JSON.stringify(oldSys));
                newSys.cfg = value;
                newSystems.push(newSys);
            }
            else {
                // This should not happen.
                console.log(`Cannot find old system object for ${key} in handleSave.`);
                // Do what?
                // newSystems.push(???);
            }
        });

        // Save the updated site object to the backend.
        props.backend.updateSite(customerId as string, newSite)
        .then((site: ISite) => {
            refreshSite();
        })
        .catch((error) => {
            displayAlert(error.message);
        })

        // Save the updated system objects to the backend.
        newSystems.forEach((sys, i) => {
            props.backend.updateSystem(customerId as string, siteId as string, sys)
            .then((system: ISystem) => {
                refreshSystems();
            })
            .catch((error) => {
                displayAlert(error.message);
            })
        });
    }

    ///////////////////////////////////

    return (
        <div>
            {/* <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/Customer/${customerId}/${customerName}` }}>{customerName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/Site/${customerId}/${customerName}/${siteId}/${siteName}` }}>{siteName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/System/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}/${systemName}` }}>{systemName}</Breadcrumb.Item>
            </Breadcrumb> */}

            <div className="d-flex justify-content-between ms-1 mb-4">
                <h4 className="mt-3 braun-text">{`Summary for ${systemName}`}</h4>
                <Button className={classNames("me-2")} variant="light" size="lg" onClick={() => refreshPage()}><ArrowClockwise/></Button>
            </div>

            <div className="d-flex justify-content-end mb-4">
                {
                    buttonsDisabled && 
                    <Button className={"me-3"} disabled={true}>Report Builder</Button>
                }

                {
                    // Pass machine list in state parameter, the report builder needs to populate one of its dropdowns with the list of machines.
                    (!buttonsDisabled) && 
                    <Link to={`/Report/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}/${systemName}`} state={{machines}}>
                        <Button className={"me-3"} disabled={false}>Report Builder</Button>
                    </Link>
                }

                <Button variant="primary" onClick={handleShow} disabled={buttonsDisabled}>Shifts &amp; Targets</Button>
            </div>

            {
                (startTime === 0 && endTime === 0) &&
                <Alert variant="warning">No data available. The current time must be within a defined shift to view data.</Alert>
            }

            {
                // If the production totals are all zero, assume there is no data for this system.
                (startTime > 0 && endTime > 0 && systemStatus?.summary !== undefined && totalsAreZero(systemStatus?.summary.totals) === true) &&
                <Alert variant="warning">No data available for the current shift.</Alert>
            }

            {
                // If the production totals are not all zero, assume there is data for this system.
                (startTime > 0 && endTime > 0 && systemStatus?.summary !== undefined && totalsAreZero(systemStatus?.summary.totals) === false) &&
                <div>
                        {
                            (!isAgentConnected(systemId!)) &&
                            <Alert variant="warning">This system is not connected to Washnet Web. This data may be stale!</Alert>
                        }

                        {/* Production Summary for this system. */}
                        {/* Production summary, include system targets for the current shift. */}
                        <ProductionSummary totals={systemStatus.summary?.totals} targets={getTargetsForSystem(systemId!)} />
                        <div className="mb-4"/>

                        {/* Alarm Summary for this system. */}
                        <AlarmSummary backend={props.backend} machineAlarms={systemStatus.summary?.alarms} path={`${customerId}#${siteId}#${systemId}`} />
                        <div className="mb-4"/>

                        {/* Status Summary for this system. */}
                        <StatusSummary backend={props.backend} machineStates={systemStatus.summary?.states} path={`${customerId}#${siteId}#${systemId}`} />
                        <div className="mb-4"/>
                </div>
            }

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />

            {/* Modal dialog to edit shifts and targets. */}
            <ModalEditShiftsTargets show={show}
                title={`Shifts and Production Targets for ${siteName}`}
                handleSave={handleSave}
                handleClose={handleClose}
                shiftTargetList={shiftTargetList}
                systemNames={systemNames}
            />

        </div>
    );
}
