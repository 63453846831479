import classNames from "classnames";
import washnet1 from "../assets/washnet-web.png";
import washnet2 from "../assets/home-header.png";

export const RootView: React.FC = () => {
    return (
        <div className={classNames("root-container")}>
            <img className={classNames("mx-auto d-block")} src={washnet2} alt="Washnet" height="100%" width="100%" />
        </div>

        // Alternative
        // <div className={classNames("root-container")}>
        //     <img className={classNames("mx-auto d-block")} src={washnet1} alt="Washnet" height="75%" width="75%" />
        //     <p className={classNames("text-center")}>Please select a site, system, or machine</p>
        // </div>
    );
}
