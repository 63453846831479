import React from "react";
import Form from 'react-bootstrap/Form';

export const PasswordRequirements: React.FC<{}> = (props: {}) => {
    return (
     <>
        <Form.Text className="text-muted">
            <h6>Password Requirements</h6>
            Minimum length is 8 characters and must contain:<br/>
            &nbsp;at least 1 number<br/>
            &nbsp;at least 1 lowercase letter<br/>
            &nbsp;at least 1 uppercase letter<br/>
            &nbsp;at least 1 special character from this set:<br/>
            &nbsp;&nbsp;^ $ * . [ ] { } ( ) ? - " ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ ` + =<br/>
            May contain a non-leading, non-trailing space
        </Form.Text>
    </>
    );
}