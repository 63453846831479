// Machine status messages and associated colors.
// Note that the Map.get() method does a case sensitive lookup.
export const messages = new Map<string, string>([
    // Tunnel Washer 130, Tunnel Washer 150
    ["JOGGING REVERSE", "green"],
    ["JOGGING FORWARD", "green"],
    ["STARTING WASH", "green"],
    ["PLACE HOLDER: 4 = WASH", "green"],
    ["TRANSPORT IN", "green"],    // will have prefix WASH, suffix MM:SS
    ["MOVING TO STANDBY", "orange"],
    ["TRANSPORTING", "green"],
    ["CYCLE TIME", "green"],      // will have prefix WASH, suffix MM:SS

    // Dryer (all will have prefix LOCAL or REMOTE)
    ["PURGE CYCLE", "green"],   // will have suffix MM:SS
    ["DRY CYCLE", "green"],     // will have suffix MM:SS
    ["COOL CYCLE", "green"],    // will have suffix MM:SS
    ["LINT CYCLE", "green"],    // will have suffix MM:SS
    ["NOT READY", "orange"],
    ["WAITING TO LOAD", "orange"],
    ["LOADING", "yellow"],
    ["WAITING TO UNLOAD", "orange"],
    ["UNLOADING", "yellow"],

    // Press
    // will have prefix MODE
    ["MODE|INACTIVE", "orange"],
    ["MODE|AUTO", "green"],
    // will have prefix AUTO
    ["AUTO|INACTIVE", "orange"],
    ["RUN_TEST_PRESS_FUNCTION", "green"],
    ["WAIT_FOR_TEST_PRESS_FUNCTION_COMPLETE", "green"],
    ["WAIT_FOR_PRESS_START_SIGNAL", "green"],
    ["WAIT_FOR_VALID_CODE", "green"],
    ["WAIT_TRANSPORT_COMPLETE", "green"],
    ["WAIT_FOR_CHUTE_EYE_CLEAR", "green"],
    ["WAIT_FOR_RAM_DOWN_DELAY", "green"],
    ["TAMP_MOVE_RAM_DOWN", "green"],
    ["TAMP_WAIT_FOR_TIMEOUT", "green"],
    ["TAMP_MOVE_RAM_UP", "green"],
    ["MOVE_RAM_DOWN", "green"],
    ["WAIT_FOR_TIMEOUT", "green"],
    ["WAIT_FOR_RAMUP_A_LITTLE", "green"],
    ["WAIT_FOR_BASKETUP", "green"],
    ["WAIT_FOR_RAMUP", "green"],
    ["WAIT_FOR_CONVEYOR_EYE_TOGGLE", "green"],
    ["WAIT_FOR_CONVEYOR_TIMEOUT", "green"],
    ["WAIT_FOR_PUSHERFORWARD", "green"],
    ["WAIT_FOR_PUSHERBACK", "green"],
    ["WAIT_FOR_BASKET_DOWN", "green"],
    ["WAIT_FOR_RAMUP_AGAIN", "green"],
    // will have prefix MODE
    ["MODE|MANUAL", "yellow"],
    // will have prefix MANUAL
    ["INACTIVE", "yellow"],
    ["MOVE_BASKET_UP", "yellow"],
    //["MOVE_BASKET_UP", "yellow"],         // duplicate
    ["MOVE_BASKET_DOWN", "yellow"],
    //["MOVE_BASKET_DOWN", "yellow"],       // duplicate
    ["MOVE_RAM_UP", "yellow"],
    //["MOVE_RAM_UP", "yellow"],            // duplicate
    ["MOVE_RAM_DOWN", "yellow"],
    //["MOVE_RAM_DOWN", "yellow"],          // duplicate
    ["MOVE_CONVEYOR_FORWARD", "yellow"],
    ["MOVE_CONVEYOR_BACKWARD", "yellow"],
    ["MOVE_CONVEYOR", "yellow"],
    ["MOVE_PUSHER_FORWARD", "yellow"],
    //["MOVE_PUSHER_FORWARD", "yellow"],    // duplicate
    ["MOVE_PUSHER_BACKWARD", "yellow"],
    // ["MOVE_PUSHER_BACKWARD", "yellow"],  // duplicate
    ["PROMPT_USER_HOME_RAM", "yellow"],
    ["PRESS IDLE", "yellow"],
    ["PRESS IDLE, PUMP ON", "yellow"],
    ["ALARM ACTIVE", "yellow"],
    ["MOVING BASKET UP", "yellow"],
    ["RAM IN BASKET, MOVING UP", "yellow"],
    ["RAM MOVING DOWN", "yellow"],
    ["RAM IN BASKET, MOVING DOWN", "yellow"],
    ["CONVEYOR FORWARD", "yellow"],
    ["CONVEYOR REVERSE", "yellow"],
    ["MOVE COMPLETE", "yellow"],
    // will have prefix AUTO
    ["PRESS IDLE", "green"],
    ["PRESS IDLE - PUMP ON", "green"],
    ["ALARM ACTIVE", "green"],
    ["PRESS WAITING FOR TUNNEL", "green"],
    ["WAITING FOR TRANSPORT COMPLETE", "green"],
    ["WAIT FOR CHUTE EYE BLOCKED", "green"],
    ["WAIT FOR CHUTE EYE CLEAR", "green"],
    ["BASKET MOVING UP", "green"],
    ["BASKET MOVING DOWN", "green"],
    ["RAM CORRECTING SAG", "green"],
    ["RAM MOVING UP", "green"],
    ["RAM INSIDE BASKET, MOVING UP", "green"],
    ["RAM MOVING DOWN", "green"],
    ["RAM INSIDE BASKET, MOVING DOWN", "green"],
    ["RAM MOVING HOME", "green"],
    ["RAM, WAITING FOR DOWN DELAY", "green"],
    ["RAM TAMP, MOVING DOWN", "green"],
    ["RAM TAMP, HOLDING", "green"],
    ["RAM TAMP, MOVING UP", "green"],
    ["WAITING FOR DISCHARGE DELAY", "green"],
    ["PUSHER MOVING FORWARD", "green"],
    ["PUSHER MOVING BACK", "green"],
    ["CONVEYOR MOVING FORWARD", "green"],
    ["CONVEYOR MOVING REVERSE", "green"],
    ["PRESS READY TO DISCHARGE", "green"],
    ["PRESS DETERMINE CYCLE TYPE", "green"],
    ["PRESS STOPPED", "orange"],

    // Unload Conveyor
    ["No Alarm", "green"],
    // will have prefix REMOTE
    ["INITIALIZING", "green"],
    ["READY FOR LOAD", "green"],
    ["LOADING (FWD), ON DELAY", "green"],
    ["LOADING (REV), ON DELAY", "green"],
    ["READY TO UNLOAD (FWD)", "orange"],
    ["READY TO UNLOAD (REV)", "orange"],
    ["UNLOADING / BELTCHECK (FWD)", "green"],
    ["UNLOADING / BELTCHECK (REV)", "green"],
    ["LOADING (FWD), START DELAY", "green"],
    ["LOADING (REV), START DELAY", "green"],
    ["LOADING (FWD), OFF DELAY", "green"],
    ["LOADING (REV), OFF DELAY", "green"],

    // Extract Conveyor
    // will have prefix REMOTE
    ["IN RESET", "green"],
    ["CHECKING FOR LOAD", "green"],
    ["CONVEYOR EMPTY", "green"],
    ["LOADING CAKE", "green"],
    ["POSITIONING CAKE", "green"],
    ["CONVEYOR LOADED", "green"],
    ["CONVEYOR UNLOADING", "green"],
    ["CONVEYOR FULL", "green"],
    ["LOAD FAULT", "red"],
    ["UNLOAD FAULT", "red"],
    ["BELT CHECK FAULT", "red"],
    // will have prefix LOCAL
    ["JOG OFF", "yellow"],
    ["JOG FWD", "yellow"],
    ["JOG REV", "yellow"],
    ["JOG STOP", "yellow"],
    // will have prefix START_UP
    ["READY FOR START", "yellow"],
    ["RUNNING BELT CHECK", "yellow"],
    ["READY FOR REMOTE", "yellow"],
    ["BELT CHECK FAULT", "red"],

    // DCSE Elevator
    // will have prefix REMOTE
    //["IN RESET", "green"],                  // duplicate
    ["POSITIONING TOP BED FOR LOADING", "green"],
    ["WAITING FOR TOP BED LOADED", "green"],
    ["UNLOAD OR ELEVATE", "green"],
    ["UNLOAD TOP BED", "green"],
    ["POSITIONING BOTTOM BED FOR LOADING", "green"],
    ["WAITING FOR BOTTOM BED LOADED", "green"],
    ["WAITING FOR UNLOAD COMMAND", "green"],
    ["WAITING FOR UNLOAD COMPLETE", "green"],
    // will have prefix LOCAL
    ["MANUAL FUNCTIONS ENABLED", "yellow"],
    // will have prefix START_UP
    //["READY FOR START", "yellow"],          // duplicate
    //["RUNNING BELT CHECK", "yellow"],       // duplicate
    ["HOMING ELEVATE", "yellow"],
    //["READY FOR REMOTE", "yellow"],         // duplicate

    // ESHN Shuttle
    // will have prefix REMOTE
    ["IN RESET", "green"],                    // duplicate
    ["CHECKING FOR COMMANDS", "green"],
    ["STARTING TRAVEL", "green"],
    ["POSITIONING TRAVEL", "green"],
    ["UNLOADING TOP BED", "green"],
    ["UNLOADING BOTTOM BED", "green"],
    // will have prefix LOCAL
    //["MANUAL FUNCTIONS ENABLED", "yellow"], // duplicate
    // will have prefix START_UP
    //["READY FOR START", "yellow"],          // duplicate
    //["RUNNING BELT CHECK", "yellow"],       // duplicate
    ["HOMING TRAVEL AND ELEVATE", "yellow"],
    //["READY FOR REMOTE", "yellow"]          // duplicate
]);

