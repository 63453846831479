import {EventKey} from '@restart/ui/types';
import {useEffect, useState, useContext} from "react";
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import {Link} from "react-router-dom";
import {CustomerNode} from "../models/customer";
import {SiteNode} from "../models/site";
import {SystemNode} from "../models/system";
import {MachineNode} from "../models/machine";
import {CognitoUser} from "@aws-amplify/auth";
import {ThemeContext} from "../App";
import {MachineIcon} from '../components/MachineIcon';
import logo from "../assets/Braun_Logo.png";

export interface AppSidebarProps {
    showSidebar: boolean;
    customerTree?: CustomerNode;
    user?: CognitoUser
}

export const AppSidebar: React.FC<React.PropsWithoutRef<AppSidebarProps>> = (props: AppSidebarProps) => {

    const { theme, changeTheme } = useContext(ThemeContext);
    const [customerTree, setCustomerTree] = useState<CustomerNode | undefined>(undefined);

    // Count is used to give each item in the tree a unique eventKey.
    // Each eventKey has this form: count, then #, then item ID. (e.g. 1#rit).
    // Without unique eventKeys, all items in tree having the same eventKey
    // will be highlighted when one of them is selected.
    var count:number = 0;

    useEffect(() => {
        setCustomerTree(props.customerTree);
    }, [props.customerTree]);

    const onSelect = (eventKey: EventKey | null) => {
        console.log("Scrolling to", eventKey);
    }

    return (
        <>
        {/* The sidebar will be returned only when the boolean property showSidebar is true. */}
        {
        (props.showSidebar && customerTree !== undefined) &&
        <SidebarMenu
            exclusiveExpand={false}
            collapseOnSelect={false}
            onSelect={onSelect}
            bg={theme}
            variant={theme}
            expand={false}
            defaultExpanded={true}
            hide={false}
       >
            <SidebarMenu.Collapse>
                {/* <SidebarMenu.Header>
                    <SidebarMenu.Brand as={Link} to="/">
                        <img alt="Braun Logo" width="109" height="45" src={logo}/>
                    </SidebarMenu.Brand>
                    <SidebarMenu.Toggle/>
                </SidebarMenu.Header> */}

                <SidebarMenu.Body>

                    {/* Indentation Level 0 - Customer */}
                    <SidebarMenu.Nav className="customer">
                        <SidebarMenu.Sub>
                            <SidebarMenu.Sub.Toggle>
                                <SidebarMenu.Nav.Link eventKey={`${count++}#${customerTree.id}`}
                                                        as={Link}
                                                        to={`/Customer/${customerTree.id}/${customerTree.name}`}>
                                    <SidebarMenu.Nav.Icon/>
                                    <SidebarMenu.Nav.Title>{customerTree.name}</SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Link>
                            </SidebarMenu.Sub.Toggle>

                            {/* Indentation Level 1 - Site */}
                            {
                                // For each site...
                                customerTree.sites.map((site:SiteNode, i:number) =>
                                    <SidebarMenu.Sub.Collapse key={i.toString()}>
                                        <SidebarMenu.Nav className="site">
                                            <SidebarMenu.Sub>
                                                <SidebarMenu.Sub.Toggle>
                                                    <SidebarMenu.Nav.Link eventKey={`${count++}#${site.id}`}
                                                                            as={Link}
                                                                            to={`/Site/${customerTree.id}/${customerTree.name}/${site.id}/${site.name}`}>
                                                        <SidebarMenu.Nav.Icon/>
                                                        <SidebarMenu.Nav.Title>{site.name}</SidebarMenu.Nav.Title>
                                                    </SidebarMenu.Nav.Link>
                                                </SidebarMenu.Sub.Toggle>
                        
                                                {/* Indentation Level 2 - System */}
                                                {
                                                    // For each system...
                                                    site.systems.map((system:SystemNode, j:number) =>
                                                        <SidebarMenu.Sub.Collapse key={j.toString()}>
                                                            <SidebarMenu.Nav className="system">
                                                                <SidebarMenu.Sub>
                                                                    <SidebarMenu.Sub.Toggle>
                                                                        <SidebarMenu.Nav.Link eventKey={`${count++}#${system.id}`}
                                                                                                as={Link}
                                                                                                to={`/System/${customerTree.id}/${customerTree.name}/${site.id}/${site.name}/${system.id}/${system.name}`}>
                                                                            <SidebarMenu.Nav.Icon/>
                                                                            <SidebarMenu.Nav.Title>{system.name}</SidebarMenu.Nav.Title>
                                                                        </SidebarMenu.Nav.Link>
                                                                    </SidebarMenu.Sub.Toggle>

                                                                    {/* Indentation Level 3 - Machine */}
                                                                    {
                                                                        // For each machine...
                                                                        system.machines.map((machine:MachineNode, k:number) =>
                                                                            <SidebarMenu.Sub.Collapse key={k.toString()}>
                                                                                <SidebarMenu.Nav className="machine">
                                                                                    <SidebarMenu.Nav.Link eventKey={`${count++}#${machine.id}`}
                                                                                                            as={Link}
                                                                                                            to={`/Machine/${customerTree.id}/${customerTree.name}/${site.id}/${site.name}/${system.id}/${system.name}/${machine.id}/${machine.name}`}>
                                                                                        <MachineIcon machineType={machine.type} machineName={machine.name}/>
                                                                                    </SidebarMenu.Nav.Link>
                                                                                </SidebarMenu.Nav>
                                                                            </SidebarMenu.Sub.Collapse>
                                                                            // end machine
                                                                        )
                                                                    }
                                                                    
                                                                </SidebarMenu.Sub>
                                                            </SidebarMenu.Nav>
                                                        </SidebarMenu.Sub.Collapse>
                                                        // end system
                                                    )
                                                }
                        
                                            </SidebarMenu.Sub>
                                        </SidebarMenu.Nav>
                                    </SidebarMenu.Sub.Collapse>
                                    // end site
                                )
                            }

                        </SidebarMenu.Sub>
                    </SidebarMenu.Nav>
                    {/* end customer */}

                </SidebarMenu.Body>
            </SidebarMenu.Collapse>
        </SidebarMenu>
        }
        </>
    );
}
