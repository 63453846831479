import { CustomerStatusResponse} from "../models/customer"
import { SiteStatusResponse} from "../models/site"
import { SystemStatusResponse} from "../models/system"
import {MachineStatusResponse, MachineAlarmsResponse} from "../models/machine"
import {ICustomer, CustomerNode, CustomerListResponse} from "../models/customer";
import {ISite, SiteListResponse} from "../models/site";
import {ISystem, SystemListResponse} from "../models/system";
import {IMachine, MachineListResponse} from "../models/machine"
import {IUser, UserListResponse, UserPermissions} from "../models/user";
import {MachineUtilizationRequest, MachineUtilizationResponse} from "../models/reports"
import {AlarmDetailRequest, AlarmDetailResponse} from "../models/reports"
import {AlarmSummaryRequest, AlarmSummaryResponse} from "../models/reports"
import {ItemDetailRequest, ItemDetailResponse} from "../models/reports"
import {ItemSummaryRequest, ItemSummaryResponse} from "../models/reports"
import {FormulaDetailRequest, FormulaDetailResponse} from "../models/reports"
import {FormulaSummaryRequest, FormulaSummaryResponse} from "../models/reports"
import {ICounter, useBusy} from "./busy";
import {WashnetBackend} from "./WashnetBackend";
import {checkId} from "../util"
import Auth, {CognitoUser} from "@aws-amplify/auth";
// TODO: disabled until we can get websockets working
// import {Observable, Observer, Subject, Subscription} from "rxjs";
// import {BraunLiveMsg, BraunLiveWebSocket} from "./live";

// The backend URL. Change this to use a different backend.
const AwsBackendURL:string = "https://api.washnetweb.com";           // Production URL (Braun)
// const AwsBackendURL:string = "https://api.washnetweb.autofrog.com";  // Development URL (RIT)

// The following exception classes mimic an HTTP exception. 
// The functions below use the AWS REST API.
// Before they communicate with the backend using this API
// they check customerId, siteId, etc. If an invalid ID is
// detected an HttpBadRequest exception is thrown, even though
// no communication with the backend has actually taken place.

abstract class HttpException {
    constructor(public message: string, public code: number, public readonly error: any | undefined) {
    }
}

class HttpBadRequest extends HttpException {
    constructor(message: string = "malformed request", error?: any) {
        // 400 Bad Request
        super(message, 400, error);
    }
}

export function useAwsBackend(): WashnetBackend {
    const busyCounter = useBusy();
    return AwsBackendImpl.getInstance(busyCounter);
}

export class AwsBackendImpl extends WashnetBackend {

    // URL of the resource we will communicate with.
    private backendURL: string;

    // This class is a singleton. Only one object of this class can be created.

    // The one and only instance of this class.
    private static instance: AwsBackendImpl;

    private constructor(busyCounter: ICounter, AwsBackendUrl: string) {
        super(busyCounter);
        this.backendURL = AwsBackendUrl;
        console.log("Constructing AWS backend");
    }

    public static getInstance(busyCounter: ICounter): AwsBackendImpl {
        // Creates AwsBackendImpl instance only once (the first time we are called).
        if (!AwsBackendImpl.instance) {
            AwsBackendImpl.instance = new AwsBackendImpl(busyCounter, AwsBackendURL);
        }

        return AwsBackendImpl.instance;
    }

    public getBackendURL(): string {
        return this.backendURL;
    }

    // Get machine name from machine ID by looking it up in the tree cache.
    // path is the path to the machine in the tree (e.g. customerId#siteId#systemId).
    // If the tree is not in the cache we just return machineId.
    public getMachineName(key:string, machineId:string) {
        if (machineId !== undefined) {
            const arr = key.split("#");
            const customerId = arr[0];
            const siteId = arr[1];
            const systemId = arr[2];
            const tree = this.treeCache.get(customerId);
            if (tree !== undefined) {
                const site = tree.sites.find((item) => item.id === siteId);
                if (site !== undefined) {
                    const system = site.systems.find((item) => item.id === systemId);
                    if (system !== undefined) {
                        const machine = system.machines.find((item) => item.id === machineId);
                        if (machine !== undefined) {
                            return machine.name;
                        }
                    }
                }
            }
        }
        return machineId;
    }

    // TODO: disabled until we can get websockets working
    // private live = new BraunLiveWebSocket("https://api.washnetweb.com/live");

    public getTree(customerId:string): Promise<CustomerNode> {
        if ( !checkId(customerId) ) {
            throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
        }

        // If the tree for this customer is in the cache, return it.
        let tree = this.treeCache.get(customerId);
        if (tree !== undefined) {
            console.log(`The tree for customer ${customerId} is in the cache`);
            return Promise.resolve(tree);
        }

        // Otherwise fetch the tree from the backend.
        super.startBusy();
        return Promise.resolve().then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/tree`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .then((tree:CustomerNode) => {
            // Add this tree to the cache.
            this.treeCache.set(customerId, tree);
            return tree;
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getCustomer(customerId:string): Promise<ICustomer> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getAllCustomers(): Promise<CustomerListResponse> {
        super.startBusy();
        return Auth.currentAuthenticatedUser().then((u: CognitoUser) => Auth.currentSession())
            .then((creds) => {
                const request: RequestInit = {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                    }
                };
                return fetch(`${this.backendURL}/customers`, request)
            })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        return Promise.reject(responseBody);
                    })
                }
                else {
                    return response.json();
                }
            })
            .catch((error) => {
                // We get here for unhandled exceptions.
                throw error;
            })
            .finally(() => {
                super.finishBusy()
            })
    }

    public getSite(customerId:string, siteId:string): Promise<ISite> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getSitesForCustomer(customerId:string): Promise<SiteListResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getSystem(customerId:string, siteId:string, systemId:string): Promise<ISystem> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getSystemsForSite(customerId:string, siteId:string): Promise<SystemListResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getMachine(customerId:string, siteId:string, systemId:string, machineId:string): Promise<IMachine> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
            else if ( !checkId(machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines/${machineId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getMachinesForSystem(customerId:string, siteId:string, systemId:string): Promise<MachineListResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines`, request);
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        // }, (e) => {
        //     console.error("The fetch failed", e);
        //     throw(e);
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getCustomerStatus(customerId: string, start:number, end:number): Promise<CustomerStatusResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/start/${start}/end/${end}/status`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getSiteStatus(customerId:string, siteId:string, start:number, end:number): Promise<SiteStatusResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/start/${start}/end/${end}/status`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getSystemStatus(customerId:string, siteId:string, systemId:string, start:number, end:number): Promise<SystemStatusResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/start/${start}/end/${end}/status`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getMachineStatus(customerId:string, siteId:string, systemId:string, machineId:string): Promise<MachineStatusResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
            else if ( !checkId(machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines/${machineId}/status`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public getMachineAlarms(customerId:string, siteId:string, systemId:string, machineId:string, start:number, end:number): Promise<MachineAlarmsResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
            else if ( !checkId(machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines/${machineId}/start/${start}/end/${end}/alarms`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getMachineUtilizationReport(params: MachineUtilizationRequest): Promise<MachineUtilizationResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/machine-utilization-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getAlarmDetailReport(params: AlarmDetailRequest): Promise<AlarmDetailResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/alarm-detail-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getAlarmSummaryReport(params: AlarmSummaryRequest): Promise<AlarmSummaryResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/alarm-summary-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getItemDetailReport(params: ItemDetailRequest): Promise<ItemDetailResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/item-detail-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getItemSummaryReport(params: ItemSummaryRequest): Promise<ItemSummaryResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/item-summary-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getFormulaDetailReport(params: FormulaDetailRequest): Promise<FormulaDetailResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/formula-detail-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    getFormulaSummaryReport(params: FormulaSummaryRequest): Promise<FormulaSummaryResponse> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(params.customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${params.customerId}) specified`);
            }
            else if ( !checkId(params.siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${params.siteId}) specified`);
            }
            else if ( !checkId(params.systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${params.systemId}) specified`);
            }
            else if ( !checkId(params.machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${params.machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${params.customerId}/sites/${params.siteId}/systems/${params.systemId}/machines/${params.machineId}/start/${params.startDate}/end/${params.endDate}/formula-summary-report`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    createCustomer(customer: ICustomer): Promise<ICustomer> {
        super.startBusy();
        return Auth.currentAuthenticatedUser()                      // this is a promise
            .then((u: CognitoUser) => Auth.currentSession())        // does user authentication
            .then((creds) => {                                      // makes the POST request to the backend to create the customer
                const request: RequestInit = {
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                    },
                    body: JSON.stringify(customer)
                };
                return fetch(`${this.backendURL}/customers`, request)
            })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        return Promise.reject(responseBody);
                    })
                }
                else {
                    return response.json();
                }
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                super.finishBusy()
            })
    }

    deleteCustomer(customerId:string): Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
            };
            return fetch(`${this.backendURL}/customers/${customerId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    updateCustomer(customer: ICustomer): Promise<ICustomer> {
        super.startBusy();
        return Auth.currentAuthenticatedUser()
            .then((u: CognitoUser) => Auth.currentSession())
            .then((creds) => {
                const request: RequestInit = {
                    method: "PUT",
                    headers: {
                        "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                    },
                    body: JSON.stringify(customer)
                };
                return fetch(`${this.backendURL}/customers/${customer.id}`, request)
            })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((responseBody) => {
                        return Promise.reject(responseBody);
                    })
                }
                else {
                    return response.json();
                }
            })
            .catch((error) => {
                // We get here for unhandled exceptions.
                throw error;
            })
            .finally(() => {
                super.finishBusy()
            })
    }

    createSite(customerId:string, site:ISite) : Promise<ISite> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(site)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
   }

    deleteSite(customerId:string, siteId:string): Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    updateSite(customerId:string, site: ISite): Promise<ISite> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(site)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${site.id}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    createSystem(customerId:string, siteId:string, system: ISystem) : Promise<ISystem> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(system)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    deleteSystem(customerId:string, siteId:string, systemId:string): Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    updateSystem(customerId:string, siteId:string, system: ISystem): Promise<ISystem> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(system)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${system.id}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    createMachine(customerId:string, siteId:string, systemId:string, machine:IMachine) : Promise<IMachine> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(machine)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    deleteMachine(customerId:string, siteId:string, systemId:string, machineId:string): Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
            else if ( !checkId(machineId) ) {
                throw new HttpBadRequest(`Invalid machine ID (${machineId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines/${machineId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    updateMachine(customerId:string, siteId:string, systemId:string, machine:IMachine): Promise<IMachine> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if ( !checkId(siteId) ) {
                throw new HttpBadRequest(`Invalid site ID (${siteId}) specified`);
            }
            else if ( !checkId(systemId) ) {
                throw new HttpBadRequest(`Invalid system ID (${systemId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(machine)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/sites/${siteId}/systems/${systemId}/machines/${machine.id}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    // Get one user for the given customer.
    getUser(customerId:string, userId:string) : Promise<IUser> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if (userId === "") {
                throw new HttpBadRequest(`Invalid user ID specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users/${userId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    // Get all users for the given customer.
    getUsersForCustomer(customerId:string) : Promise<UserListResponse> {
        super.startBusy();
        // Otherwise, get the user list for this customer and return it.
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                // The response body is the user list.
                return response.json().then( (responseBody) => {
                    return Promise.resolve(responseBody);
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    createUser(customerId:string, user: IUser) : Promise<IUser> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(user)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    deleteUser(customerId:string, userId:string) : Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if (userId === "") {
                throw new HttpBadRequest(`Invalid user ID specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users/${userId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    updateUser(customerId:string, user: IUser) : Promise<IUser> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(user)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users/${user.userId}`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }
    
    getUserGrants(customerId:string, userId:string) : Promise<UserPermissions> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if (userId === "") {
                throw new HttpBadRequest(`Invalid user ID specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users/${userId}/grants`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    addUserGrants(customerId:string, userId:string, grants:UserPermissions) : Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if (userId === "") {
                throw new HttpBadRequest(`Invalid user ID specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(grants)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users/${userId}/grants`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    deleteUserGrants(customerId:string, userId:string, grants:UserPermissions) : Promise<string> {
        super.startBusy();
        return Promise.resolve().then(() => {
            if ( !checkId(customerId) ) {
                throw new HttpBadRequest(`Invalid customer ID (${customerId}) specified`);
            }
            else if (userId === "") {
                throw new HttpBadRequest(`Invalid user ID specified`);
            }
        })
        .then(() => {
            return Auth.currentAuthenticatedUser();
        })
        .then((u: CognitoUser) => {
            return Auth.currentSession();
        })
        .then((creds) => {
            const request: RequestInit = {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                },
                body: JSON.stringify(grants)
            };
            return fetch(`${this.backendURL}/customers/${customerId}/users/${userId}/grants`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return new Promise<string>( (resolve, reject) => {
                    resolve("Success!");
                })
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    public whoAmI(): Promise<IUser> {
        super.startBusy();
        return Auth.currentAuthenticatedUser().then((u: CognitoUser) => Auth.currentSession())
        .then((creds) => {
            const request: RequestInit = {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + creds.getAccessToken().getJwtToken()
                }
            };
            return fetch(`${this.backendURL}/whoami`, request)
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((responseBody) => {
                    return Promise.reject(responseBody);
                })
            }
            else {
                return response.json();
            }
        })
        .catch((error) => {
            // We get here for unhandled exceptions.
            throw error;
        })
        .finally(() => {
            super.finishBusy()
        })
    }

    // TODO: disabled until we can get websockets working
    // subscribe<T>(key: string) : Observable<BraunLiveMsg<T>> {
    //     return this.live.subscribe<T>(key)
    // }
}
