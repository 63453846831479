import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import {WashnetBackend} from "../services/WashnetBackend";
import {CustomerList} from "./CustomerList";
import {IUser} from "../models/user";

export interface CustomersProps {
    backend: WashnetBackend,
    currentUser: IUser | undefined
}

export const Customers: React.FC<React.PropsWithoutRef<CustomersProps>> = (props) => {

    return (
        <>
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>Administration Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/customers" }}>Manage Companies</Breadcrumb.Item>
        </Breadcrumb>

        <h4>Manage Companies</h4>

        <Container fluid>
            <Row>
                <CustomerList backend={props.backend} currentUser={props.currentUser}/>
            </Row>
        </Container>
        </>
    );
}
