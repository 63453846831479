interface DataProps {
    value?: string | number | boolean
}

export const Data: React.FC<DataProps> = (props: DataProps) => {
    if (typeof props.value === "boolean")
        return (
            (props.value === true) ? <span>yes</span> : <span>no</span>
        )
    else
        return (   
            (props.value === undefined || props.value === null) ? <span>----</span> : <span>{props.value}</span>
        )
}
