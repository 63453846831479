import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {WashnetBackend} from "../services/WashnetBackend";
import {ReportHeader} from '../components/ReportHeader';
import {MachineUtilizationRequest, MachineUtilizationResponse} from "../models/reports"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {secToHMS} from "../util";
import {ModalShowMessage} from "../admin/ModalShowMessage"
import {ReportParams} from "../models/reports";
import {Data} from "../components/Data"

export interface MachineUtilizationProps {
    backend: WashnetBackend
}

export const ReportMachineUtilization: React.FC<MachineUtilizationProps> = (props: MachineUtilizationProps) => {

    const {customerId, siteId, systemId, machineId} = useParams();
    const [machineUtilization, setMachineUtilization] = useState<MachineUtilizationResponse>();

    const state:ReportParams = useLocation().state;

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined || machineId === undefined) {
            console.log("customerId, siteId, systemId or machineId is undefined");
            return;
        }

        // Outgoing to backend.
        let params: MachineUtilizationRequest = {
            customerId: customerId,
            siteId: siteId,
            systemId: systemId,
            machineId: machineId,
            startDate: state.startDate,
            endDate: state.endDate
        }

        props.backend.getMachineUtilizationReport(params)
        .then((response: MachineUtilizationResponse) => {
            setMachineUtilization(response)
        })
        .catch((error) => {
            setAlertMessage(error.message);
            setShowAlert(true);
        })

    }, [customerId, siteId, systemId, machineId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleCloseMessage = () => {
        setShowAlert(false);
    }

    return (
        <div>
            <ReportHeader title="Machine Utilization" machineName={state.machineName} startDate={state.startDate} endDate={state.endDate} timezone={state.timezone} />

            {/* Option 2 Rows and Columns */}
            {
                machineUtilization !== undefined &&
                <>
                {
                    machineUtilization.response.map((item, i) => 
                    <div key={i.toString()}>
                        <Container>
                            <Row>
                                <Col className="col-sm">
                                <span><b>Summary</b></span>
                                </Col>
                                <Col className="col-sm">
                                </Col>
                                <Col className="col-sm">
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Throughput: <Data value={item.throughput.toFixed(2)}/> lbs/hr
                                </Col>
                                <Col className="col-sm">
                                Number of Loads: <Data value={item.numLoads}/>
                                </Col>
                                <Col className="col-sm">
                                Avg Turn Time: {secToHMS(item.avgTurnTime)}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Total Weight: <Data value={Math.round(item.totalWeight)}/> lbs
                                </Col>
                                <Col className="col-sm">
                                Total Time: {secToHMS(item.totalTime)}
                                </Col>
                                <Col className="col-sm">
                                Avg Turns/Hour: <Data value={item.avgTurnsPerHour.toFixed(2)}/>
                                </Col>
                            </Row>
                        </Container>
                        <hr/>
                        <Container>
                            <Row>
                                <Col className="col-sm">
                                <span><b>Time Usage</b></span>
                                </Col>
                                <Col className="col-sm">
                                <span><b>Time</b></span>
                                </Col>
                                <Col className="col-sm">
                                <span><b>Percent</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Processing:
                                </Col>
                                <Col className="col-sm">
                                {secToHMS(item.totalTimeProcessing)}
                                </Col>
                                <Col className="col-sm">
                                <Data value={item.percentTimeProcessing.toFixed(1)}/>%
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Waiting to Load:
                                </Col>
                                <Col className="col-sm">
                                {secToHMS(item.totalTimeWaitingForLoad)}
                                </Col>
                                <Col className="col-sm">
                                <Data value={item.percentTimeWaitingForLoad.toFixed(1)}/>%
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Waiting to Unload:
                                </Col>
                                <Col className="col-sm">
                                {secToHMS(item.totalTimeWaitingForUnload)}
                                </Col>
                                <Col className="col-sm">
                                <Data value={item.percentTimeWaitingForUnload.toFixed(1)}/>%
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                In Alarm:
                                </Col>
                                <Col className="col-sm">
                                {secToHMS(item.totalTimeInAlarm)}
                                </Col>
                                <Col className="col-sm">
                                <Data value={item.percentTimeInAlarm.toFixed(1)}/>%
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                In Manual:
                                </Col>
                                <Col className="col-sm">
                                {secToHMS(item.totalTimeInManual)}
                                </Col>
                                <Col className="col-sm">
                                <Data value={item.percentTimeInManual.toFixed(1)}/>%
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Offline:
                                </Col>
                                <Col className="col-sm">
                                {secToHMS(item.totalTimeOffline)}
                                </Col>
                                <Col className="col-sm">
                                <Data value={item.percentTimeOffline.toFixed(1)}/>%
                                </Col>
                            </Row>
                        </Container>
                        <hr/>
                        <Container>
                            <Row>
                                <Col className="col-sm">
                                <span><b>Utility Usage</b></span>
                                </Col>
                                <Col className="col-sm">
                                <span><b>Total</b></span>
                                </Col>
                                <Col className="col-sm">
                                <span><b>Per Unit Weight</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Total Water:
                                </Col>
                                <Col className="col-sm">
                                n/a
                                </Col>
                                <Col className="col-sm">
                                n/a
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-sm">
                                Fresh Water:
                                </Col>
                                <Col className="col-sm">
                                n/a
                                </Col>
                                <Col className="col-sm">
                                n/a
                                </Col>
                            </Row>
                        </Container>
                        <hr/>
                    </div>
                    )
                }
                </>
            }

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseMessage}
            />

        </div>
    );
}
