import {ITimezone} from "react-timezone-select";

export interface IProductionTargets {
    pounds: number,
    pounds_per_hour: number,
    loads: number,
    loads_per_hour: number,
    turns: number,
    turns_per_hour: number
}

export class ProductionTargets implements IProductionTargets {
    constructor(pounds=1, pounds_per_hour=1, loads=1, loads_per_hour=1, turns=1, turns_per_hour=1) {
        this.pounds = pounds;
        this.pounds_per_hour = pounds_per_hour;
        this.loads = loads;
        this.loads_per_hour = loads_per_hour;
        this.turns = turns;
        this.turns_per_hour = turns_per_hour;        
    }
    pounds: number;
    pounds_per_hour: number;
    loads: number;
    loads_per_hour: number;
    turns: number;
    turns_per_hour: number
}

///////////////////////////////////

// This stuff currently used by customer status page.

// For Period dropdown
export enum PeriodType {
    LAST_HOUR = 1,
    LAST_TWO_HOURS,
    LAST_FOUR_HOURS,
    LAST_EIGHT_HOURS
}

// For Display dropdown
export enum DisplayType {
    TOTALS = 1, RATES
}

// For Rank By dropdown
export enum RankType {
    POUNDS = 1, LOADS, TURNS
}

// For sorting the list
export enum SortType {
    TOTAL_POUNDS = 1, TOTAL_LOADS, TOTAL_TURNS, RATE_POUNDS, RATE_LOADS, RATE_TURNS
}

// Calculated metrics for a site, used by customer status page.
export interface ISiteMetrics
{
    id: string,
    name: string,
    pounds: number,
    loads: number,
    turns: number,
    pounds_per_hour: number,
    loads_per_hour: number,
    turns_per_hour: number,
    total_pounds_percent: number,
    total_loads_percent: number,
    total_turns_percent: number,
    rate_pounds_percent: number,
    rate_loads_percent: number,
    rate_turns_percent: number,
    targets: IProductionTargets
}

///////////////////////////////////

export interface AlarmSummary {
    count: number,
    alarm: string
}

export interface Alarms {       // List of alarms for one machine
    machineId: string,
    alarms: AlarmSummary[]
}

export interface State {        // State for one machine
    machineId: string,
    networkStatus: string,
    machineStatus: string,
    productivityState: string
}

export interface States {       // States for several machines of the same type (e.g. Washers)
    machineType: string,        // "Washer, "Dryer", "Tunnel", etc.
    states: State[]
}

export interface ProductionTotals {
    id: string,                 // "All" or site ID or system ID
    pounds: number,
    pounds_per_hour: number,
    loads: number,
    loads_per_hour: number,
    turns: number,
    turns_per_hour: number,
}

export interface SystemSummary {
    systemId: string,           // System ID
    totals: ProductionTotals,
    alarms: Alarms[],
    states: States[]
}

///////////////////////////////////

export interface Shift {
    id: number,         // shift ID
    name: string,       // shift name
    start: string,      // a time string (e.g. "08:00"), not a Date
    end: string,        // a time string (e.g. "16:00"), not a Date
    targets: IProductionTargets
}

// Timezone and an array of shifts.
export interface IShiftList {
    timezone: ITimezone,
    shifts: Shift[]
}

export class ShiftList implements IShiftList {
    constructor(timezone = Intl.DateTimeFormat("en-US", {timeZone: "America/New_York"}).resolvedOptions().timeZone, shifts:Shift[] = [])
    {
        this.timezone = timezone;
        this.shifts = shifts;
    }

    timezone: ITimezone;
    shifts: Shift[];
}

///////////////////////////////////
// This stuff is for the shift/target editor.

export interface _Shift {
    id: number,         // shift ID
    name: string,       // shift name
    start: string,      // a time string (e.g. "08:00"), not a Date
    end: string         // a time string (e.g. "16:00"), not a Date
}

export interface _Targets {
    systemName: string,
    pounds: number,
    pounds_per_hour: number,
    loads: number,
    loads_per_hour: number,
    turns: number,
    turns_per_hour: number
}

export interface _ShiftTargets {
    shift: _Shift,
    targets: _Targets[]
}

// Pass this to and from the editor.
export interface _ShiftTargetsList {
    timezone: ITimezone,
    list: _ShiftTargets[]
}

///////////////////////////////////

// This is the shift used by Report View and elsewhere.
// Similar to Shift but has complete Dates instead of time strings.
export interface ReportShift {
    id: number,
    name: string,
    start: Date,    // start time as Date
    end: Date,      // end time as Date
}

// Similar to Shift but has UNIX times instead of time strings.
export interface ShiftUnixTime {
    id: number,
    name: string,
    start: number,  // start time as UNIX time
    end: number,    // end time as UNIX time
    now: number,    // current time as UNIX time
}

///////////////////////////////////
