import Table from 'react-bootstrap/Table';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {WashnetBackend} from "../services/WashnetBackend";
import {ReportHeader} from '../components/ReportHeader';
import {ItemSummaryRequest, ItemSummaryResponse} from "../models/reports"
import {secToHMS} from "../util";
import {ModalShowMessage} from "../admin/ModalShowMessage"
import {ReportParams} from "../models/reports";
import {Data} from "../components/Data"

export interface ItemSummaryProps {
    backend: WashnetBackend
}

export const ReportItemSummary: React.FC<ItemSummaryProps> = (props: ItemSummaryProps) => {

    const {customerId, siteId, systemId, machineId} = useParams();
    const [itemSummary, setItemSummary] = useState<ItemSummaryResponse>();

    const state:ReportParams = useLocation().state;

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined || machineId === undefined) {
            console.log("customerId, siteId, systemId or machineId is undefined");
            return;
        }

        // Outgoing to backend.
        let params: ItemSummaryRequest = {
            customerId: customerId,
            siteId: siteId,
            systemId: systemId,
            machineId: machineId,
            startDate: state.startDate,
            endDate: state.endDate
        }

        props.backend.getItemSummaryReport(params)
        .then((response: ItemSummaryResponse) => {
            setItemSummary(response)
        })
        .catch((error) => {
            setAlertMessage(error.message);
            setShowAlert(true);
        })

    }, [customerId, siteId, systemId, machineId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleCloseMessage = () => {
        setShowAlert(false);
    }

    return (
        <div>
            <ReportHeader title="Item Summary" machineName={state.machineName} startDate={state.startDate} endDate={state.endDate} timezone={state.timezone} />

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Count</th>
                    <th>Item</th>
                    <th>Formula</th>
                    <th>Weight</th>
                    <th>Total Process Time</th>
                    <th>Avg Process Time</th>
                    <th>Total Alarm Time</th>
                    <th>Avg Alarm Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    itemSummary !== undefined &&
                    <>
                        {
                        itemSummary.response.map((item, j) =>
                            <tr key={j.toString()}>
                            <td><Data value={item.count}/></td>
                            <td><Data value={item.item}/></td>
                            <td><Data value={item.formula}/></td>
                            <td><Data value={Math.round(item.weight)}/></td>
                            <td>{secToHMS(item.totalTimeProcessing)}</td>
                            <td>{secToHMS(item.averageTimeProcessing)}</td>
                            <td>{secToHMS(item.totalTimeInAlarm)}</td>
                            <td>{secToHMS(item.averageTimeInAlarm)}</td>
                            </tr>
                        )
                        }
                    </>
                    }
                </tbody>
            </Table>
            <hr/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseMessage}
            />

        </div>
    );
}
