import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {ICustomer, CustomerListResponse, Customer} from "../models/customer";
import {IUser} from "../models/user";
import {WashnetBackend} from "../services/WashnetBackend";
import Button from "react-bootstrap/Button";
import {Container, Row} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {ModalEditCustomer} from "./ModalEditCustomer";
import {ModalShowMessage} from "./ModalShowMessage"
import {Pencil, Trash3} from 'react-bootstrap-icons';
import {Data} from "../components/Data"
import {DialogType} from "../util"

export interface CustomerListProps {
    backend: WashnetBackend,
    currentUser: IUser | undefined
}

export const CustomerList: React.FC<React.PropsWithoutRef<CustomerListProps>> = (props: CustomerListProps) => {
    const [customers, setCustomers] = useState<ICustomer[]>([]);

    // Function to read the customer list from the backend.
    // Called from useEffect and when the customer list is edited.
    var refreshCustomerList = () => {
        // A super user can see all customers, all others see only one customer.
        if (props.currentUser !== undefined) {
            if (props.currentUser.superuser) {
                props.backend.getAllCustomers()
                    .then((response: CustomerListResponse) => {
                        setCustomers(response.customers)
                    })
                    .catch((error) => {
                        displayAlert(error.message);
                    })
            }
            else {
                
                // TODO: Is this stuff fixed now?
                // When I first navigate to this page, props.currentUser is Tim and getCustomer("rit") works.
                // On page refresh,
                //      the entire app is refreshed
                //      and props.currentUser is Pete and getCustomer("microsoft") throws "Customer ID doesn't exist".
                // When the app is refreshed, props.currentUser is Pete, but later it is set to Tim in the app
                // This page gets props.currentUser = Pete, but never gets the updated props.currentUser = Tim
                // Is this a race condition?
                // Why does the entire app refresh with I refresh this page? It this the expected behavior?
                // Options:
                // 1. Put backend and currentUser in WashnetContext, and this page will get them from context instead of props
                // 2. Forget about context, have this page load the current user itself, instead of getting it from props
                //
                // And the theme is reset to darks (because the app is refreshed)

                props.backend.getCustomer(props.currentUser.customerId)
                    .then((response: ICustomer) => {
                        setCustomers([response])
                    })
                    .catch((error) => {
                        displayAlert(error.message);
                    })
            }
        }
    }

    useEffect(() => {
        refreshCustomerList();
    }, [props.currentUser])

    ////////////////////////////////////////////////////////////////////

    // Values sent to the add/edit dialogs.
    const [customerValues, setCustomerValues] = useState<ICustomer>(new Customer());

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    ///////////////////////////////////
    // For Add modal dialog
    const [showAddDialog, setShowAddDialog] = useState(false);

    const handleShowAddDialog = () => {
        setCustomerValues(new Customer());
        setShowAddDialog(true);
    }
        
    // This is a callback called by the child component ModalEditCustomer.
    // The child passes the new customer values in parameter cust.
    const handleSaveAddDialog = (cust:ICustomer) => {
        setShowAddDialog(false);

        // Create a new customer in the backend.
        props.backend.createCustomer(cust)
        .then((customer: ICustomer) => {
            // Call this to reread the customer table from the backend, which rerenders the page.
            refreshCustomerList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseAddDialog = () => {
        setShowAddDialog(false);
    }

    ///////////////////////////////////
    // For Edit modal dialog
    const [showEditDialog, setShowEditDialog] = useState(false);

    // Opens editor for a customer (row) in the table.
    const handleShowEditDialog = (index:number) => {

        // This does a deep copy.
        setCustomerValues(JSON.parse(JSON.stringify(customers[index])));

        setShowEditDialog(true);
    }

    // This is a callback called by the child component ModalEditCustomer.
    // The child passes the updated customer values in parameter cust.
    const handleSaveEditDialog = (cust:ICustomer) => {
        setShowEditDialog(false);

        // Update this customer in the backend.
        props.backend.updateCustomer(cust)
        .then((customer: ICustomer) => {
            refreshCustomerList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    }

    ///////////////////////////////////
    // Deletes a customer (row) from the table.
    const handleDelete = (index:number) => {

        // Delete this customer from the backend.
        props.backend.deleteCustomer( customers[index].id )
        .then(() => {
            refreshCustomerList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    return (
        <>
        <Container fluid>
            <Row>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Company ID</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        customers?.map((c: ICustomer, i: number) =>
                        <tr key={`c ${i}`}>
                            <td><Link to={`/admin/customers/${c.id}/${c.name}`}><Data value={c.name}/></Link></td>
                            <td><Data value={c.id}/></td>
                            <td><Data value={c.desc}/></td>
                            <td>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="edit">Edit Company</Tooltip>}>
                                    <Button className={classNames("me-2")} variant="outline-dark"  size="sm" onClick={() => handleShowEditDialog(i)}><Pencil/></Button>
                                </OverlayTrigger>

                                {/* Show the Delete Company button only if the user is a super user. */}
                                {
                                props.currentUser !== undefined && props.currentUser.superuser &&
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="delete">Delete Company</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleDelete(i)}><Trash3/></Button>
                                </OverlayTrigger>
                                }
                            </td>
                        </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Row>

            {/* Show the Add Company button only if the user is a super user. */}
            {
            props.currentUser !== undefined && props.currentUser.superuser &&
            <Button className="mt-3" variant="primary" onClick={handleShowAddDialog} >Add Company</Button>
            }
        </Container>

        {/* Modal to show message. */}
        <ModalShowMessage show={showAlert}
            message={alertMessage}
            variant="danger"
            handleClose={handleCloseAlert}
        />

        {/* Modal dialog to add a customer. */}
        <ModalEditCustomer show={showAddDialog}
            title={"Add Company"}
            type={DialogType.ADD}
            handleSave={handleSaveAddDialog}
            handleClose={handleCloseAddDialog}
            customerValues={customerValues}
        />

        {/* Modal dialog to edit an existing customer. */}
        <ModalEditCustomer show={showEditDialog}
            title={"Edit Company"}
            type={DialogType.EDIT}
            handleSave={handleSaveEditDialog}
            handleClose={handleCloseEditDialog}
            customerValues={customerValues}
        />

        </>
    );
}
