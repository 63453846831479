import {ITimezone} from "react-timezone-select";

export interface UserListResponse {
    numUsers?: number,
    users: IUser[]
}

// createUser will not include userId, but will include password
// updateUser will include userId, and will include password only if it's being changed
export interface IUser extends UserPermissions {
    userId: string,
    customerId: string,
    first?: string,
    last?: string,
    email?: string,
    nickname?: string,
    phone?: string,
    timezone?: ITimezone,
    temporaryPassword?: string,
    superuser?: boolean,
    customerAdmin?: boolean,
}

export interface UserPermissions {
    ro?: string[],
    rw?: string[],
    admin?: string[]
}

// This is used by the edit permissions dialog.
export interface UserPermissionsSet {
    superuser?: boolean,
    customerAdmin?: boolean,
    ro?: string[],
    rw?: string[],
    admin?: string[]
}

// Timezone America/New_York is Eastern Time.
export class User implements IUser {

    // Password is not included as a constructor parameter
    constructor(userId = "", customerId = "", first = "", last = "",
                email = "", nickname = "", phone = "", timezone = Intl.DateTimeFormat("en-US", {timeZone: "America/New_York"}).resolvedOptions().timeZone,
                superuser = false, customerAdmin = false,
                ro = [] as string[], rw = [] as string[], admin = [] as string[]
    )
    {
        this.userId = userId;
        this.customerId = customerId;
        this.first = first;
        this.last = last;
        this.email = email;
        this.nickname = nickname;
        this.phone = phone;
        this.timezone = timezone;
        this.superuser = superuser;
        this.customerAdmin = customerAdmin;
        this.ro = ro;
        this.rw = rw;
        this.admin = admin
    }

    userId: string;
    customerId: string;
    first?: string;
    last?: string;
    email?: string;
    nickname?: string;
    phone?: string;
    timezone?: ITimezone;
    temporaryPassword?: string;
    superuser?: boolean;
    customerAdmin?: boolean;
    ro?: string[];
    rw?: string[];
    admin?: string[]
}
