import Auth, {CognitoUser} from "@aws-amplify/auth";
import classNames from "classnames";
import React, {useEffect, useState, useContext} from "react";
import {GearFill, ArrowLeftRight} from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Link, useNavigate} from "react-router-dom";
import {BusySpinner} from "../components/BusySpinner";
import {CurrentUser} from "./CurrentUser";
import {ThemeContext} from "../App";
import {ICustomer} from "../models/customer"
import logo from "../assets/Braun_Logo.png";
import washnetweb from "../assets/washnet-web.png";
import {appVersion} from "../version"

export interface AppNavbarProps {
    user?: CognitoUser,
    customerId: string,
    customerList: ICustomer[],
    cognitoUserChanged: (newUser: CognitoUser | undefined) => void,
    onCustomerChanged?: (customerId:string) => void,
    toggleSidebar?: () => void
}

export const AppNavbar: React.FC<AppNavbarProps> = (props: AppNavbarProps) => {
    const navigate = useNavigate();
    const [isBraunAdmin, setIsBraunAdmin] = useState(false);
    const { theme, changeTheme } = useContext(ThemeContext);

    /* For user preferences modal. */
    const [showPrefs, setShowPrefs] = useState(false);
    const handleClosePrefs = () => setShowPrefs(false);
    const handleShowPrefs = () => setShowPrefs(true);

    /* For About modal. */
    const [showAbout, setShowAbout] = useState(false);
    const handleCloseAbout = () => setShowAbout(false);
    const handleShowAbout = () => setShowAbout(true);

    const handleLogOut = (event: React.MouseEvent<HTMLElement>) => {
        // Sign out the current user (also removes the user session from local storage, I think).
        Auth.signOut()
            .then(() => {
                // Tell the parent app that no one is logged in.
                if (props.cognitoUserChanged) {
                    props.cognitoUserChanged(undefined);
                }
                setIsBraunAdmin(false);
                navigate("/login");
            })
    }

    useEffect(() => {
        // Determine if user is a braun admin.
        props.user?.getUserAttributes((error, attrs) => {
            if(error || !attrs) {
                setIsBraunAdmin(false);
            }
            else {
                const ba = attrs.find((attr) => attr.Name==="custom:braunAdmin");
                setIsBraunAdmin( ba != null && ba.Value === "true");
            }
        })
    }, [props.user])

    // Called when a customer is selected from the customer list.
    const handleSelect=(event, eventKey)=>{
        // Call the onCustomerChanged handler.
        if (props.onCustomerChanged) {
            props.onCustomerChanged(event);
        }
    }    

    // Called when the toggle sidebar icon is pressed.
    const handleSidebarToggle = () => {
        // Call the toggleSidebar handler.
        if (props.toggleSidebar) {
            props.toggleSidebar();
        }
    }

    return (
        <>
        <Navbar expand="sm" bg={theme} variant={theme} className={classNames("main-header")}>
            <Container fluid>
                {/* Go to Braun website. */}
                {/* <Navbar.Brand href="https://www.gabraun.com/" target="_blank">
                    <img alt="Braun Logo" width="109" height="45" src={logo}/>
                </Navbar.Brand>
                <Navbar.Toggle/> */}

                {/* Go to home page. */}
                {/* <Navbar.Brand as={Link} to="/">
                    <img alt="Braun Logo" width="109" height="45" src={logo}/>
                </Navbar.Brand>
                <Navbar.Toggle/> */}

                <Navbar.Brand>
                    <OverlayTrigger placement={'bottom-start'} overlay={<Tooltip id="hideshow">Hide/Show Sidebar</Tooltip>}>
                        <Button variant={theme} size="lg" onClick={() => handleSidebarToggle()} className={classNames("me-0")}><ArrowLeftRight/></Button>
                    </OverlayTrigger>
                </Navbar.Brand>

                {/* Go to home page. */}
                <Navbar.Brand as={Link} to="/">
                    <img alt="Washnet Web" width="254" height="46" src={washnetweb}/>
                </Navbar.Brand>

                <Navbar.Toggle/>

                {
                // Only the super user can see the customer list, hidden for everyone else.
                (isBraunAdmin) &&
                <Navbar.Collapse className={classNames("justify-content-start")}>
                    <Nav>
                        <NavDropdown title={<Navbar.Brand>Change Company</Navbar.Brand>} menuVariant={theme} onSelect={(event, eventKey) => handleSelect(event, eventKey)}>
                            {
                                // Add list of customers to the dropdown.
                                // Set the current customer as active in the dropdown.
                                props.customerList.map((customer:ICustomer, i:number) =>
                                    <NavDropdown.Item key={i} eventKey={customer.id} active={props.customerId === customer.id}>{customer.name}</NavDropdown.Item>
                                )
                            }
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                }

                <Navbar.Collapse className={classNames("justify-content-end")}>
                    <Navbar.Brand className={classNames("ms-1", "pe-3")}>
                        <CurrentUser cognitoUser={props.user}/>
                    </Navbar.Brand>
                    <Nav>
                        <OverlayTrigger placement={'bottom-start'} overlay={<Tooltip id="settings">Settings</Tooltip>}>
                            <NavDropdown 
                                title={<Button variant={theme} size="lg"><GearFill/></Button>}
                                drop="start"
                                menuVariant={theme}
                                className={classNames("pe-3")}>

                                <NavDropdown.Item as={Link} to="/Support">Braun Support</NavDropdown.Item>

                                {/* Option 1 - Preferences on separate page. */}
                                {/* <NavDropdown.Item as={Link} to="/prefs">Preferences</NavDropdown.Item> */}
                                {/* Option 2 - Preferences in a modal popup dialog. */}
                                <NavDropdown.Item onClick={event => handleShowPrefs()}>Preferences</NavDropdown.Item>

                                <NavDropdown.Item as={Link} to="/login">Log In</NavDropdown.Item>
                                <NavDropdown.Item onClick={event => handleLogOut(event)}>Log Out</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/change-password">Change Password</NavDropdown.Item>

                                {/* The following links to the Test page, for testing only. */}
                                {/* <NavDropdown.Item as={Link} to="/test">Test Page</NavDropdown.Item> */}
                                <NavDropdown.Item as={Link} to="/admin">Administration</NavDropdown.Item>
                                
                                {/* About is a modal popup dialog. */}
                                <NavDropdown.Item onClick={event => handleShowAbout()}>About</NavDropdown.Item>
                            </NavDropdown>
                        </OverlayTrigger>
                    </Nav>
                    <Navbar.Brand className={classNames("ms-2", "pe-3")}>
                        <BusySpinner/>
                    </Navbar.Brand>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        {/* User preferences modal dialog. This is shown only when the Preferences NavDropdown.Item is selected above. */}
        <Modal show={showPrefs} onHide={handleClosePrefs} backdrop="static" size="sm">
            <Modal.Header>
                <Modal.Title>User Preferences</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className={classNames("mb-3")} controlId="theme">
                        <Form.Label>Theme</Form.Label>
                        <Form.Check
                            type="radio"
                            name="group1"
                            label="Dark"
                            checked={theme === "dark"}
                            onChange={event => changeTheme("dark")}
                        />
                        <Form.Check
                            type="radio"
                            name="group1"
                            label="Light"
                            checked={theme === "light"}
                            onChange={event => changeTheme("light")}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClosePrefs}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        {/* About modal dialog. This is shown only when the About NavDropdown.Item is selected above. */}
        <Modal className="about-modal"  show={showAbout} onHide={handleCloseAbout} backdrop="static" size="sm">
        <Modal.Header>
                <img alt="Washnet Web" width="254" height="46" src={washnetweb}/>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className={classNames("text-center")}>
                        <p style={{color:"white"}}>
                            Version {appVersion}<br/>
                            Copyright &copy; G.A. Braun, Inc.<br/>
                            All Rights Reserved
                        </p>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleCloseAbout}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
