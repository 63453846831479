import {SiteNode} from "./site"
import {ProductionTotals, IProductionTargets} from "./types";

export interface CustomerListResponse {
    customers: ICustomer[]
}

export interface CustomerConfig {
    targets: IProductionTargets
}

export interface ICustomer {
    id: string,
    name: string,
    desc: string;
    cfg: CustomerConfig
}

export class Customer implements ICustomer {
    constructor(id="", name="", desc="", cfg={targets: {pounds:1, pounds_per_hour:1, loads:1, loads_per_hour:1, turns:1, turns_per_hour:1}}) {
        this.id = id;
        this.name = name;
        this.cfg = cfg;
        this.desc = desc;
    }
    id: string;
    name: string;
    desc:string;
    cfg: CustomerConfig;
}

// For tree
export interface CustomerNode {
    id: string,
    name: string;
    sites: SiteNode[]
}

///////////////////////////////////

export interface CustomerStatusResponse {
    sites: ProductionTotals[]          // Production totals for each site for this customer
}
