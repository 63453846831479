import Auth, {CognitoUser} from "@aws-amplify/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

interface CheckNavigationProps {
    cognitoUserChanged: (newUser: CognitoUser | undefined) => void
}

export const CheckNavigation: React.FC<CheckNavigationProps> = (props: CheckNavigationProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    // This component checks if a user is logged in by
    // checking for a valid user in local storage.
    // If someone is logged in, go to the page he is trying to go to.
    // Otherwise, redirect him to the login page.

    useEffect(() => {
        // This call will fetch the current user session from local storage if it exists.
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            // console.log(`CheckNavigation: valid user found going to ${location.pathname}`);

            // User successfully fetched from local storage, which means a user is logged in.
            // Go to the location the user is trying to go to (it's in location.pathname).
            navigate(location.pathname);
            return user;
        })
        .catch((error) => {
            // console.log("CheckNavigation: no valid user was found, going to \login");

            // There is no user in local storage, which means a user is not logged in.
            // Redirect him to the /login page, but save the location he is
            // trying to go to. This allows us to send him
            // along to that page after he logs in, which is a nicer user experience
            // than dropping him off on the home page.
            navigate("/login", { state: location.pathname });
        })
    }, []);

    return (<></>);
}
