import {MachineNode} from "./machine";
import {SystemSummary} from "./types";
import {IProductionTargets} from "./types";

export interface SystemListResponse {
    systems: ISystem[];
}

// Shift ID and targets for that shift.
export interface SystemTargets {
    shiftId: number,
    targets: IProductionTargets
}

// An array of targets for one system, one set of targets for each shift.
export interface SystemConfig {
    shiftList: SystemTargets[]
}

export interface ISystem {
    id: string;
    name: string;
    desc: string;
    cfg: SystemConfig;
    agentStatus: string,
    agentTimestamp: number
}

export class System implements ISystem {
    constructor(id="", name="", desc="", cfg={shiftList:[]})
    {
        this.id = id;
        this.name = name;
        this.cfg = cfg;
        this.desc = desc;
        this.agentStatus = "";
        this.agentTimestamp = 0;
        }
    
    id: string;
    name: string;
    desc:string;
    cfg: SystemConfig;
    agentStatus: string;
    agentTimestamp: number;
}

// For tree
export interface SystemNode {
    id: string;
    name: string;
    machines: MachineNode[];
}

///////////////////////////////////

export interface SystemStatusResponse {
    summary?: SystemSummary;
}
