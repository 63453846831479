import classNames from "classnames";
import { parseISO, format, getUnixTime, sub } from 'date-fns'
import {useEffect, useState} from "react";
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {WashnetBackend} from "../services/WashnetBackend";
import {ReportParams} from "../models/reports";
import {Shift, ReportShift, ShiftList} from "../models/types"
import {ISite, Site} from "../models/site";
import {IMachine} from "../models/machine";
import {findCurrentShift, findPreviousShift, findShift} from "../util";
import {ModalShowMessage} from "../admin/ModalShowMessage"

export interface ReportProps {
    backend: WashnetBackend
}

export const ReportView: React.FC<ReportProps> = (props: ReportProps) => {
    const {customerId, customerName, siteId, siteName, systemId, systemName} = useParams();
    const navigate = useNavigate();

    // state.machines is type IMachine[]
    const state = useLocation().state;

    const [site, setSite] = useState<ISite>( new Site() );

    // For showing/hiding various fields on the form.
    const [showShiftList, setShiftListVisibility] = useState(false);
    const [showStartDate, setStartDateVisibility] = useState(false);
    const [showStartTime, setStartTimeVisibility] = useState(false);
    const [showEndDate, setEndDateVisibility] = useState(false);
    const [showEndTime, setEndTimeVisibility] = useState(false);

    const date = format(new Date(), 'yyyy-MM-dd');
    const [reportType, setReportType] = useState("ReportMachineUtilization");
    const [machineId, setMachineId] = useState<string>("");
    const [reportTime, setReportTime] = useState("CurrentShift");
    const [shift, setShift] = useState("unknown");
    const [startDate, setStartDate] = useState(date);
    const [startTime, setStartTime] = useState("08:00");
    const [endDate, setEndDate] = useState(date);
    const [endTime, setEndTime] = useState("17:00");

    // Finds a machine name given the machine ID.
    function findName(machines: IMachine[], id:string):string {
        const s = machines.find((element) => element.id === id);
        return (s === undefined) ? "Unknown Machine" : s.name;
    }

    // Function to read the site from the backend.
    // Called from useEffect.
    var refreshSite = () => {
        // Request the site, which includes the shift list.
        props.backend.getSite(customerId as string, siteId as string)
        .then((response: ISite) => {
            setSite(response);

            // Set the Shift field to the first shift in the list, if it exists.
            if (response.cfg.shiftList.shifts.length > 0)
                setShift(response.cfg.shiftList.shifts[0].name);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined) {
            console.log("customerId or siteId or systemId is undefined");
            return;
        }

        // Make sure the Machine field has something in it.
        if (state.machines.length > 0)
            setMachineId(state.machines[0].id);
        else
            setMachineId("");

        refreshSite();
    }, [customerId, siteId, systemId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function handleCloseAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    ///////////////////////////////////

    // Submit button handler.
    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (machineId === "") {
            displayAlert("To generate a report, a machine must be selected. In the tree, select a machine or select a system containing one or more machines.");
            return;
        }

        // Get the shift list.
        var shiftList:ShiftList = site.cfg.shiftList;

        // Here are the formats of date and time strings that come out of the date and time pickers.
        // date: yyyy-mm-dd (e.g. "2023-01-23")
        // time: hh:mm (time is in 24 hour format, e.g. 7 AM is "07:00", 1 PM is "13:00")

        console.log(`Form submitted: ${startDate} / ${startTime} / ${endDate} / ${endTime} / ${shift}`);

        // startMs and endMs are actually in seconds (unix time) not milliseconds
        var startMs: number;
        var endMs: number;
        var now:Date = new Date();
        var s: ReportShift | undefined;

        startMs = getUnixTime(parseISO(`${startDate} ${startTime}`));
        // console.log(`start date is ${startDate} start time is ${startTime} and in unix time is ${startMs}`);

        endMs = getUnixTime(parseISO(`${endDate} ${endTime}`));
        // console.log(`end date is ${endDate} end time is ${endTime} and in unix time is ${endMs}`);

        // Report time can be:
        // "CurrentShift" - find a shift where shift start < now < shift end, use those start and end times
        // "LastShift" - look up start and end times in shift list
        // "LastHour" - start time is now minus 1 hour
        // "Last8Hours" - start time is now minus 8 hours
        // "ReportByShift" - look up start and end times in the shift list
        // "ReportByTime" - use start time and date and end time and date from fields on the form

        switch(reportTime) {
            case "CurrentShift":
                // Call findCurrentShift, it will return START and END (the start time/date and end time/date for the current shift).
                // For the report, start = START and end = now.
                s = findCurrentShift(now, shiftList);
                if (s !== undefined) {
                    endMs = getUnixTime(now);
                    startMs = getUnixTime(s.start);
                }
                break;
            case "LastShift":
                // Call findPreviousShift, it will return START and END (the start time/date and end time/date for the previous shift).
                // For the report, start = START and end = END.
                s = findPreviousShift(now, shiftList);
                if (s !== undefined) {
                    endMs = getUnixTime(s.end);
                    startMs = getUnixTime(s.start);
                }
                break;
            case "LastHour":
                // This is easy, end = now, start = now minus 1 hour
                endMs = getUnixTime(now);
                startMs = getUnixTime(sub(now, {hours:1}));
                break;
            case "Last8Hours":
                // This is easy, end = now, start = now minus 8 hours
                endMs = getUnixTime(now);
                startMs = getUnixTime(sub(now, {hours:8}));
                break;
            case "ReportByShift":
                // Call findShift, it will return the start date/time and the end date/time of the given shift on the given start date.
                // It's possible end date/time will be the following day.
                // It's also possible the selected shift hasn't occurred yet.
                s = findShift(startDate, shift, shiftList); 
                if (s !== undefined) {
                    endMs = getUnixTime(s.end);
                    startMs = getUnixTime(s.start);
                }
                break;
            case "ReportByTime":
                // This is easy. For the report, just get start date/time and end date/time from the controls.
                // It's possible start date/time and thus the end date/time will be in the future.
                endMs = getUnixTime(parseISO(`${endDate} ${endTime}`));
                startMs = getUnixTime(parseISO(`${startDate} ${startTime}`));
                break;
        }

        ///////////////////////////////////
        // BEGIN TESTING findCurrentShift and findPreviousShift

        // var testShifts:ShiftList = {
        //     timezone: "America/Los_Angeles", 
        //     shifts: [
        //         {id:0, name:"First", start:"04:00", end:"12:00", targets:{"pounds":6,"pounds_per_hour":6,"loads":6,"loads_per_hour":6,"turns":6,"turns_per_hour":6}},
        //         {id:1, name:"Second", start:"12:00", end:"20:00", targets:{"pounds":7,"pounds_per_hour":7,"loads":7,"loads_per_hour":7,"turns":6,"turns_per_hour":7}},
        //         {id:2, name:"Third", start:"20:00", end:"04:00", targets:{"pounds":8,"pounds_per_hour":8,"loads":8,"loads_per_hour":8,"turns":8,"turns_per_hour":8}}
        //     ]
        // }

        // // var n = toDate(`${format(new Date(), 'yyyy-MM-dd')} 08:15:00`, { timeZone: testShifts.timezone.toString() });  // this is case 1, first shift, start and end are today
        // // var n = toDate(`${format(new Date(), 'yyyy-MM-dd')} 13:15:00`, { timeZone: testShifts.timezone.toString() });  // this is case 1, second shift, start and end are today
        // // var n = toDate(`${format(new Date(), 'yyyy-MM-dd')} 21:15:00`, { timeZone: testShifts.timezone.toString() });  // this is case 2, third shift, start is today, end is tomorrow
        // // var n = toDate(`${format(new Date(), 'yyyy-MM-dd')} 23:15:00`, { timeZone: testShifts.timezone.toString() });  // this is case 2, third shift, start is today, end is tomorrow
        // var n = toDate(`${format(new Date(), 'yyyy-MM-dd')} 01:15:00`, { timeZone: testShifts.timezone.toString() });  // this is case 3, third shift, start date is yesterday, end is today

        // var s = findCurrentShift(n, testShifts);
        // if (s !== undefined) {
        //     console.log('returned from findCurrentShift');
        //     console.log('name ' + s.name);
        //     // console.log('now ' + format(n, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     // console.log('start ' + format(s.start, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     // console.log('end ' + format(s.end, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     console.log('now ' + formatInTimeZone(n, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        //     console.log('start ' + formatInTimeZone(s.start, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        //     console.log('end ' + formatInTimeZone(s.end, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        // }
        // else {
        //     console.log("current shift not found");
        // }

        // s = findPreviousShift(n, testShifts);
        // if (s !== undefined) {
        //     console.log('returned from findPreviousShift');
        //     console.log('name ' + s.name);
        //     // console.log('now ' + format(n, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     // console.log('start ' + format(s.start, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     // console.log('end ' + format(s.end, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     console.log('now ' + formatInTimeZone(n, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        //     console.log('start ' + formatInTimeZone(s.start, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        //     console.log('end ' + formatInTimeZone(s.end, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        // }
        // else {
        //     console.log("previous shift not found");
        // }
        
        // END TESTING findCurrentShift and findPreviousShift
        ///////////////////////////////////

        ///////////////////////////////////
        // BEGIN TESTING findShift

        // var testShifts:ShiftList = {
        //     timezone: "America/Los_Angeles", 
        //     shifts: [
        //         {id:0, name:"First", start:"04:00", end:"12:00", targets:{"pounds":6,"pounds_per_hour":6,"loads":6,"loads_per_hour":6,"turns":6,"turns_per_hour":6}},
        //         {id:1, name:"Second", start:"12:00", end:"20:00", targets:{"pounds":7,"pounds_per_hour":7,"loads":7,"loads_per_hour":7,"turns":6,"turns_per_hour":7}},
        //         {id:2, name:"Third", start:"20:00", end:"04:00", targets:{"pounds":8,"pounds_per_hour":8,"loads":8,"loads_per_hour":8,"turns":8,"turns_per_hour":8}}
        //     ]
        // }
        
        // // Test 1 - find shift named First
        // // s = findShift("2023-01-23", "First", testShifts); 
        // // Should return name: First, start: 2023-01-23 04:00:00 PST, end: 2023-01-23 12:00:00 PST

        // // Test 2 - find shift named Second
        // // s = findShift("2023-01-23", "Second", testShifts); 
        // // Should return name: Second, start 2023-01-23 12:00:00 PST, end 2023-01-23 20:00:00 PST

        // // Test 3 - find shift named Third
        // s = findShift("2023-01-23", "Third", testShifts); 
        // // Should return name: Third, start start 2023-01-23 20:00:00 PST, end 2023-01-24 04:00:00 PST

        // if (s !== undefined) {
        //     console.log('returned from findShift');
        //     console.log('name ' + s.name);
        //     // console.log('start ' + format(s.start, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     // console.log('end ' + format(s.end, 'yyyy-MM-dd kk:mm:ss.SSS'));
        //     console.log('start ' + formatInTimeZone(s.start, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        //     console.log('end ' + formatInTimeZone(s.end, testShifts.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        // }
        // else {
        //     console.log("shift not found");
        // }

        // END TESTING findShift
        ///////////////////////////////////

        if (startMs > endMs) {
            displayAlert("Start date is after end date. Please correct the dates and try again.");
            return;
        }

        if (startMs > getUnixTime(now)) {
            displayAlert("Start date is in the future. Report data is not yet available.");
            return;
        }

        // TODO: Delete these times, for testing only.
        // startMs = 1699707600;
        // endMs = 1699740000;
        // The following are for report testing with Braun's timestream database.
        // startMs = 1706014800;           // Tue Jan 23 2024 08:00:00 GMT-0500 (Eastern Standard Time), Tue Jan 23 2024 13:00:00 GMT+0000
        // endMs = 1706040360;             // Tue Jan 23 2024 15:06:00 GMT-0500 (Eastern Standard Time), Tue Jan 23 2024 20:06:00 GMT+0000
        // More report testing with Braun's timestream database.
        // startMs = 1706792400;           // Thu Feb 01 2024 08:00:00 GMT-0500 (Eastern Standard Time), Thu Feb 01 2024 13:00:00 GMT+0000
        // endMs = 1706795100;             // Thu Feb 01 2024 08:45:00 GMT-0500 (Eastern Standard Time), Thu Feb 01 2024 13:45:00 GMT+0000
        // console.log(`start time is ${startMs} end time is ${endMs}`);

        let params: ReportParams = {
            machineName: findName(state.machines, machineId),
            reportTime: reportTime,
            shift: shift,
            timezone: site.cfg.shiftList.timezone,
            startDate: startMs,
            endDate: endMs
        }

        // Determine the page to which to navigate based on Report Type
        // and pass the parameters to that page.
        switch(reportType) {
            case "ReportMachineUtilization":
                navigate(`/ReportMachineUtilization/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
            case "ReportAlarmDetail":
                navigate(`/ReportAlarmDetail/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
            case "ReportAlarmSummary":
                navigate(`/ReportAlarmSummary/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
            case "ReportFormulaDetail":
                navigate(`/ReportFormulaDetail/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
            case "ReportFormulaSummary":
                navigate(`/ReportFormulaSummary/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
            case "ReportItemDetail":
                navigate(`/ReportItemDetail/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
            case "ReportItemSummary":
                navigate(`/ReportItemSummary/${customerId}/${siteId}/${systemId}/${machineId}`, {state: params});
                break;
        }
    }

    // Report time changed handler.
    const handleReportTimeChange = (value: string) => {
        setReportTime(value);

        // Set visibility of various fields based on report time value.
        setShiftListVisibility(value === "ReportByShift");
        setStartDateVisibility(value === "ReportByShift" || value === "ReportByTime");
        setStartTimeVisibility(value === "ReportByTime");
        setEndDateVisibility(value === "ReportByTime");
        setEndTimeVisibility(value === "ReportByTime");
    }

    return (
        <div>
            {/* <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/Site/${customerId}/${customerName}/${siteId}/${siteName}` }}>{siteName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/System/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}${systemName}` }}>{systemName}</Breadcrumb.Item>
            </Breadcrumb> */}

            <h4 className={classNames("braun-text pb-3")}>Report Builder</h4>

            <Form onSubmit={handleSubmit}>
                <Form.Label>Report Type</Form.Label>
                <div className={classNames("col-md-4", "pb-3")}>
                    <Form.Select value={reportType} onChange={event => setReportType(event.target.value)}>
                        <option value="ReportMachineUtilization">Machine Utilization</option>
                        <option value="ReportAlarmDetail">Alarm Detail</option>
                        <option value="ReportAlarmSummary">Alarm Summary</option>
                        <option value="ReportFormulaDetail">Formula Detail</option>
                        <option value="ReportFormulaSummary">Formula Summary</option>
                        <option value="ReportItemDetail">Item Detail</option>
                        <option value="ReportItemSummary">Item Summary</option>
                    </Form.Select>
                </div>

                <Form.Label>Machine</Form.Label>
                <div className={classNames("col-md-4", "pb-3")}>
                    <Form.Select value={machineId} onChange={event => setMachineId(event.target.value)}>
                        {/* We won't support All Washers and All Dryers. */}
                        {/* <option value="AllWashers">All Washers</option> */}
                        {/* <option value="All">All Dryers</option> */}

                        {/* Add machines passed to us in state.machines. */}
                        {
                            state.machines.map((machine:IMachine, i:number) =>
                                <option key={i.toString()} value={machine.id}>{machine.name}</option>
                            )
                        }
                    </Form.Select>
                </div>

                <Form.Label>Report Time</Form.Label>
                <div className={classNames("col-md-4", "pb-3")}>
                    <Form.Select value={reportTime} onChange={event => handleReportTimeChange(event.target.value)}>
                        <option value="CurrentShift">Current Shift</option>
                        <option value="LastShift">Last Shift</option>
                        <option value="LastHour">Last Hour</option>
                        <option value="Last8Hours">Last 8 Hours</option>
                        <option value="ReportByShift">Report By Shift</option>
                        <option value="ReportByTime">Report By Time</option>
                    </Form.Select>
                </div>

                {
                showShiftList &&
                <div>
                <Form.Label>Shift</Form.Label>
                <div className={classNames("col-md-4", "pb-3")}>
                    <Form.Select value={shift} onChange={event => setShift(event.target.value)}>
                        {/* Add shifts. */}
                        {
                            site.cfg.shiftList.shifts.map((shift:Shift, i:number) =>
                                <option key={i.toString()} value={shift.name}>{shift.name}</option>
                            )
                        }

                    </Form.Select>
                </div>
                </div>
                }

                {
                (showStartDate || showStartTime) &&
                <div className={classNames("row", "pb-3")}>
                    {
                    showStartDate &&
                    <div className={classNames("col")}>
                        <div className={classNames("col-md-4")}>
                            <Form.Group controlId="start-date">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" name="startdate" value={startDate} onChange={event => setStartDate(event.target.value)}/>
                            </Form.Group>
                        </div>
                    </div>
                    }
                    {
                    showStartTime &&
                    <div className={classNames("col")}>
                        <div className={classNames("col-md-4")}>
                            <Form.Group controlId="start-time">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control type="time" name="starttime" value={startTime} onChange={event => setStartTime(event.target.value)}/>
                            </Form.Group>
                        </div>
                    </div>
                    }
                </div>
                }

                {
                (showEndDate || showEndTime) &&
                <div className={classNames("row", "pb-3")}>
                    {
                    showEndDate &&
                    <div className={classNames("col")}>
                        <div className={classNames("col-md-4")}>
                            <Form.Group controlId="end-date">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="date" name="enddate" value={endDate} onChange={event => setEndDate(event.target.value)}/>
                            </Form.Group>
                        </div>
                    </div>
                    }
                    {
                    showEndTime &&
                    <div className={classNames("col")}>
                        <div className={classNames("col-md-4")}>
                            <Form.Group controlId="end-time">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="time" name="endtime" value={endTime} onChange={event => setEndTime(event.target.value)}/>
                            </Form.Group>
                        </div>
                    </div>
                    }
                </div>
                }

                <div className={classNames("pb-4")}></div>
                <span>
                    <Button variant="primary" type="submit" className={classNames("me-5")}>Generate Report</Button>
                </span>
            </Form>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />

        </div>
    );
}
