import { Bar } from 'react-chartjs-2';
import {Data} from "./Data"

// import { Chart as ChartJS } from "chart.js/auto";

// import Chart from 'chart.js/auto';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';

export interface BarChartProps {
    title: string,
    xmin?: number,   // bottom of range for x axis
    xmax?: number,   // top of range for x axis
    actualValue?: number,
    targetValue?: number
}

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {

    let data =
    {
        labels: [""],
        datasets: [{
            backgroundColor: ["red"],
            barThickness: 20,
            data: [0],
            label: ''
        }]
    }

    // Hard-coded data
    // const data =
    // {
    //     labels: ["Italy", "France", "Spain", "USA", "Argentina"],
    //     datasets: [{
    //         backgroundColor: ["red","green","blue","orange","brown"],
    //         data: [55, 49, 44, 24, 15],
    //         label: 'Wine Production'
    //     }]
    // }

    let options =
    {
        indexAxis: 'y' as const,
        responsive: true,
        scales: {
            x:
              {
                min: 0,
                max: 100 
                // can specify stepSize too
              }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: ''
            }
        }
    }

    // Hard-coded options 
    // const options =
    // {
    //     indexAxis: 'y' as const,
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             display: false,
    //             position: 'right' as const
    //         },
    //         title: {
    //             display: true,
    //             text: 'World Wine Production 2018'
    //         }
    //     }
    // }

    options.plugins.title.text = props.title;
    if (props.xmin !== undefined)
        options.scales.x.min = props.xmin;
    if (props.xmax !== undefined)
        options.scales.x.max = props.xmax;
    if (props.actualValue !== undefined)
        data.datasets[0].data[0] = props.actualValue;

    // Graph background color
    let bg:string = '#e9e9e9';    // light gray

    if (props.actualValue !== undefined && props.targetValue !== undefined) {
        let p:number;

        // Determine percentage: actual value vs target value
        if (props.targetValue > 0)
            p = props.actualValue / props.targetValue;
        else
            p = 1;

        if (p < 0.5) {
            data.datasets[0].backgroundColor = ["#dc3545"]; // bar color, Bootstrap danger red
        }
        else if (p < 0.75) {
            data.datasets[0].backgroundColor = ["#ffc107"]; // bar color, Bootstrap warning yellow
        }
        else {
            data.datasets[0].backgroundColor = ["#198754"]; // bar color, Bootstrap success green
        }
    }

    return (
        <div style={{ width: 300, border: '1px solid', borderRadius:6, marginBottom:6, backgroundColor:bg}}>
            <Bar data={data} options={options}/>
            <div className="d-flex justify-content-between" style={{ fontSize:12, padding:6}}>
                <div>Actual <Data value={props.actualValue}/></div>
                <div>Target <Data value={props.targetValue}/></div>
            </div>
        </div>
    );
}
