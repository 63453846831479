import React from "react";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";
import {WashnetBackend} from "../services/WashnetBackend";
import {IUser} from "../models/user";
import {IMachine, MachineListResponse} from "../models/machine";
import {ModalShowMessage} from "./ModalShowMessage"
import {Data} from "../components/Data"

export interface SystemDetailProps {
    backend: WashnetBackend,
    currentUser: IUser | undefined
}

export const SystemDetail: React.FC<SystemDetailProps> = (props: SystemDetailProps) => {
    const [machines, setMachines] = useState<IMachine[]>([]);
    const {customerId, customerName, siteId, siteName, systemId, systemName} = useParams();

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined) {
            console.log("customerId or siteId or systemId is undefined");
            return;
        }

        props.backend.getMachinesForSystem(customerId, siteId, systemId)
        .then((response: MachineListResponse) => {
            setMachines(response.machines)
        })
        .catch((error) => {
            displayAlert(error.message);
        })

    }, [customerId, siteId, systemId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    return(
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>Administration Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/customers" }}>Manage Companies</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/customers/${customerId}/${customerName}` }}>{customerName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/sites/${customerId}/${customerName}/${siteId}/${siteName}` }}>{siteName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/systems/${customerId}/${customerName}/${siteId}/${siteName}/${systemId}/${systemName}` }}>{systemName}</Breadcrumb.Item>
            </Breadcrumb>

            <h4>{`Manage System: ${systemName}`}</h4>

            <div className={classNames("pb-4")}></div>
            <h5>Machines</h5>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Machine Name</th>
                        <th>Machine ID</th>
                        <th>Description</th>
                        <th>Model</th>
                        <th>Type</th>
                        <th>Disabled</th>
                    </tr>
                </thead>
                <tbody>
                {
                    // machine.enabled is false if the machine has been deleted (no longer part of the parent system), true if it's still in use.
                    machines.map((machine: IMachine, i: number) =>
                        <tr key={i.toString()}>
                            <td><Data value={machine.name}/></td>
                            <td><Data value={machine.id}/></td>
                            <td><Data value={machine.desc}/></td>
                            <td><Data value={machine.model}/></td>
                            <td><Data value={machine.type}/></td>
                            <td><Data value={machine.enabled === false}/></td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

            <hr/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />
        </>
    );
}
