import {CognitoUser} from "@aws-amplify/auth";
import {Shift, ReportShift, ShiftList, ShiftUnixTime, _Shift} from "./models/types"
import { parseISO, set, compareDesc, addDays, subDays, differenceInMinutes, sub, isAfter, getUnixTime, fromUnixTime, format, isEqual } from 'date-fns'
import { formatInTimeZone, toDate } from 'date-fns-tz'
import {ITimezone} from "react-timezone-select";
import {messages} from "./admin/messages";
import green from "./assets/green-dot.png";
import gray from "./assets/gray-dot.png";
import red from "./assets/red-dot.png";
import yellow from "./assets/yellow-dot.png";
import orange from "./assets/orange-dot.png";
import blue from "./assets/blue-dot.png";

/////////////////////////////////////////////////

// Regular expressions for validating entries in various dialogs.

export const idExpression = new RegExp('^[a-z][a-z0-9_-]{1,10}[a-z0-9]$');
export const nameExpression = new RegExp('^[a-zA-Z][ a-zA-Z0-9_.-]{2,31}[a-zA-Z0-9]$');
export const emailExpression = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
export const userNameExpression = new RegExp("^[a-zA-Z,.'-]+$");
export const positiveIntExpression = new RegExp("^[1-9][0-9]*$");

// Matches phone number in this format xxx-xxx-xxxx
export const phoneExpression = new RegExp('^[0-9]{3}[-][0-9]{3}[-][0-9]{4}$');

// These regex cause compiler error.
// E.164 international phone number format from
//https://learning.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch04s03.html
// export const phoneExpressionInt1 = new RegExp('^\+(?:[0-9] ?){6,14}[0-9]$');
// export const phoneExpressionInt2 = new RegExp('^\+(?:[0-9]?){6,14}[0-9]$'); // does not allow space

// See https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito 
// See https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html

// Regex that does not allow special characters.
// export const passwordExpression = new RegExp('^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 ]{8,256}$');

// Regex that requires at least one special character (this is a literal regex that is enclosed in slashes).
export const passwordExpression = new RegExp(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/);

/////////////////////////////////////////////////

// For dialogs used to Add or Edit.
export enum DialogType {
    ADD, EDIT
}

// Breaks string into two strings (prefix and message)
// and looks up the color based on the message.
// For example, breaks "REMOTE | THIS IS THE MESSAGE" into two strings,
// "REMOTE | " and "THIS IS THE MESSAGE".
function getColor(regex:RegExp, msg:string): string {
    const match = msg.match(regex);
    if (match !== null) {
        // Note that the Map.get() method does a case sensitive lookup.
        var color = messages.get(match[2]);
        if (color !== undefined)
            return color;
    }
    return "gray";
}

// This determines the color for a machine status message.
export function geColorFromMsg(msg?:string): string {
    if (msg === undefined)
        return "gray";

    // Removes period from the end (does nothiing if there is no period).
    msg = msg.replace(/\.$/, "");

    // Removes MM:SS from the end (does nothiing if there is no MM:SS).
    msg = msg.replace(/\s-\s\d+:\d+$/, "");

    if (msg.toUpperCase().startsWith("AUTO"))
        // Only one AUTO message is orange.
        if (msg.toUpperCase().endsWith("INACTIVE"))
            return "orange";
        else
            return "green";
    if (msg.toUpperCase().startsWith("MANUAL"))
        return "yellow";
    if (msg.toUpperCase().startsWith("ALM"))
        return "red";
    if (msg.toUpperCase().startsWith("IDLE"))
        return "orange";
    if (msg.toUpperCase().startsWith("WRN"))
        return "yellow";
    if (msg.startsWith("---"))
        return "orange";

    if (msg.toUpperCase().startsWith("REMOTE")) {
        // Strip off "REMOTE |" and look up the color from the remaining message.
        return getColor(/^(REMOTE\s*\|\s*)(.+)$/, msg);
    }
    if (msg.toUpperCase().startsWith("LOCAL")) {
        // Strip off "LOCAL |" and look up the color from the remaining message.
        return getColor(/^(LOCAL\s*\|\s*)(.+)$/, msg);
    }
    if (msg.toUpperCase().startsWith("START_UP")) {
        // Strip off "START_UP |" and look up the color from the remaining message.
        return getColor(/^(START_UP\s*\|\s*)(.+)$/, msg);
    }
    if (msg.toUpperCase().startsWith("WASH")) {
        // Strip off "WASH |" and look up the color from the remaining message.
        return getColor(/^(WASH\s*\|\s*)(.+)$/, msg);
    }

    // Otherwise look up the color from the message.
    var color = messages.get(msg);
    return color === undefined ? "gray" : color;
}

// We used to determine LED color from the machine status message.
// This determines the color for an LED icon that appears on the machine status summary.
// It determines the color from the machine status message.
export function getLedColorOld(msg?:string): string {
    var color = geColorFromMsg(msg);
    var icon: string = gray;

    switch(color) {
        case "gray":
            icon = gray;
            break;
        case "green":
            icon = green;
            break;
        case "yellow":
            icon = yellow;
            break;
        case "red":
            icon = red;
            break;
        case "orange":
            icon = orange;
            break;
    }

    return icon;
}

// Now we determine LED color from the productivity state message.
// This determines the color for an LED icon that appears on the machine status summary.
// It determines the color from the productivity state message.
export function getLedColor(msg?:string): string {
    var icon: string;

    if (msg === undefined)
        icon = gray;
    else if (msg.startsWith("IN_ALARM"))
        icon = red;
    else if (msg.startsWith("OFFLINE"))
        icon = gray;
    else
        icon = blue;

    return icon;
}

// We used to determine color from the machine status message.
// This is for the machine status message.
export function getMachineColorOld(msg:string): string {
    if (msg === undefined)
        return "#CDDBEB";               // light blue

    var color = geColorFromMsg(msg);
    var hexCode: string = "#CDDBEB";    // light blue

    switch(color) {
        case "gray":
            hexCode = "#E4E4E7";
            break;
        case "green":
            hexCode = "#B7DA9C";
            break;
        case "yellow":
            hexCode = "#FEEB8E";
            break;
        case "red":
            hexCode = "#F7A3A4";
            break;
        case "orange":
            hexCode = "#F4D0A4";
            break;
    }

    return hexCode;
}

// Now we determine color from the productivity state message.
// This is for the machine status message.
export function getMachineColor(msg:string): string {
    var hexCode: string;

    if (msg === undefined)
        hexCode = "#E4E4E7";            // light gray
    else if (msg.startsWith("IN_ALARM"))
        hexCode = "#F7A3A4";            // light red
    else if (msg.startsWith("OFFLINE"))
        hexCode = "#E4E4E7";            // light gray
    else
        hexCode = "#CDDBEB";            // light blue

    return hexCode;
}

// This is for the network status message.
// This is the enum in wn_web: enum('OFF SCAN','OFFLINE','CONNECTING','ONLINE')
export function getNetworkColor(status?:string): string {
    let color: string;

    if (status === undefined) {
        return "#E4E4E7";                       // light gray
    }
    else {
        let str:string = status.toUpperCase();

        if (str.startsWith("ONLINE"))
            color = "#B7DA9C";                  // light green
        else if (str.startsWith("OFFLINE"))
            color = "#F7A3A4";                  // light red
        else if (str.startsWith("CONNECTING"))
            color = "#FEEB8E";                  // light yellow
        else if (str.startsWith("OFF SCAN"))
            color = "#E4E4E7";                  // light gray
        else
            color = "#E4E4E7";                  // light gray
    }

    return color;
}

// This is for the productivity state message.
// The WN_Data_Collection PDF says productivity states are:
//    Offline, In Manual, In Alarm, Waiting to Load, Loading, Processing, Waiting to Unload, and Unloading
// However, this is the enum in wn_web: enum('INIT_STATE','OFFLINE','IN_MANUAL','IN_ALARM','WAIT_FOR_LOAD','LOADING','WAIT_FOR_UNLOAD','UNLOADING')
// We will use this enum in determining color.
// Is there a PROCESSING state? It's not in the enum.
export function getProductivityColor(status?:string): string {
    let color: string;

    if (status === undefined) {
        color = "#E4E4E7";                      // light gray
    }
    else {
        let str:string = status.toUpperCase();

        if (str.startsWith("INIT_STATE"))
            color = "#CDDBEB";                  // light blue
        else if (str.startsWith("IN_MANUAL"))
            color = "#B7DA9C";                  // light green
        else if (str.startsWith("LOADING"))
            color = "#FEEB8E";                  // light yellow
        else if (str.startsWith("UNLOADING"))
            color = "#FEEB8E";                  // light yellow
        else if (str.startsWith("WAIT_FOR_LOAD"))
            color = "#F4D0A4";                  // light orange
        else if (str.startsWith("WAIT_FOR_UNLOAD"))
            color = "#F4D0A4";                  // light orange
        else if (str.startsWith("IN_ALARM"))
            color = "#F7A3A4";                  // light red
        else if (str.startsWith("OFFLINE"))
            color = "#E4E4E7";                  // light gray
        else
            color = "#E4E4E7";                  // light gray
    }

    return color;
}

/////////////////////////////////////////////////

// compareDesc(start, end) === 1 means start < end
// compareDesc(start, end) === -1 means start > end
// compareDesc(start, end) === 0 means start = end

function isLess(dateLeft, dateRight): boolean {
    return compareDesc(dateLeft, dateRight) === 1;
}

function isGreater(dateLeft, dateRight): boolean {
    return compareDesc(dateLeft, dateRight) === -1;
}

function isLessEqual(dateLeft, dateRight): boolean {
    return compareDesc(dateLeft, dateRight) === 1 || compareDesc(dateLeft, dateRight) === 0;
}

function isGreaterEqual(dateLeft, dateRight): boolean {
    return compareDesc(dateLeft, dateRight) === -1 || compareDesc(dateLeft, dateRight) === 0;
}

// Converts a UNIX time to a formatted string (e.g. 09/08/2023 14:30:00).
// Optionally show time zone, default time zone is UTC.
export function unixTimeToString(timestamp: number, show: boolean = false, timezone: string = "UTC"): string {
    if (timestamp === undefined)
        return "----";
    const t:Date = fromUnixTime(Number(timestamp));

    if (show)
        return formatInTimeZone(t, timezone, 'kk:mm:ss MM/dd/yyyy zzz');
    else
        return formatInTimeZone(t, timezone, 'kk:mm:ss MM/dd/yyyy');
}

// Converts a UNIX time to a time string (e.g. 14:30:00).
// Optionally show time zone, default time zone is UTC.
export function unixTimeToTimeString(timestamp: number, show: boolean = false, timezone: string = "UTC"): string {
    if (timestamp === undefined)
        return "----";
    const t:Date = fromUnixTime(Number(timestamp));

    if (show)
        return formatInTimeZone(t, timezone, 'kk:mm:ss zzz');
    else
        return formatInTimeZone(t, timezone, 'kk:mm:ss');
}

// Converts a UNIX time to a date string (e.g. 09/08/2023).
// Optionally show time zone, default time zone is UTC.
export function unixTimeToDateString(timestamp: number, show: boolean = false, timezone: string = "UTC"): string {
    if (timestamp === undefined)
        return "----";
    const t:Date = fromUnixTime(Number(timestamp));

    if (show)
        return formatInTimeZone(t, timezone, 'MM/dd/yyyy zzz');
    else
        return formatInTimeZone(t, timezone, 'MM/dd/yyyy');
}

// Convert milliseconds to a string in hours:minutes:seconds format (e.g. "1:40:30").
export function msecToHMS(milliseconds?:number): string {
    if (milliseconds === undefined)
        return "---";
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor(((milliseconds % 360000) % 60000) / 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// Convert seconds to a string in hours:minutes:seconds format (e.g. 6,030 seconds converts to "1:40:30").
export function secToHMS(secs:number) {
    if (secs === undefined)
        return "---";
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = Math.floor(((secs % 3600) % 60) / 1);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// Returns the time (as hours:minutes) from the given date, formatted in the given time zone.
function get_time(date:Date, timezone:ITimezone): string {
    return formatInTimeZone(date, timezone.toString(), 'HH:mm');
}

// Returns a Date whose date component is now (today) and whose time component is the given time in the given time zone.
export function get_date(now:Date, time:string, timezone:ITimezone): Date {
    // time is a string of hours and minutes (e.g. "08:15")

    // The toDate function is used to parse a Date from a string containing a date and time
    // representing time in any time zone by providing an IANA time zone name on the timeZone option.
    // For example toDate("2023-01-23 08:00", { timeZone: 'America/Los_Angeles' }) yields 8:00 AM in LA on 2023-01-23

    return toDate(`${format(now, 'yyyy-MM-dd')} ${time}`, { timeZone: timezone.toString() });
}

// Returns the difference in minutes between two dates.
function getDifferenceInMinutes(endDate:Date, startDate:Date): number {
    // For differenceInMinutes, if first date is later than second date,
    // difference will be positive, otherwise difference will be negative.
    // 1440 minutes = 24 hours
    var diff = differenceInMinutes(endDate, startDate);
    return (diff < 0) ? 1440 + diff : diff;
}

// Finds the current shift (the shift that contains the current time (now)).
// Returns the start time/date and the end time/date of the current shift.
export function findCurrentShift(now:Date, shiftList:ShiftList): ReportShift | undefined {
    // now is the current time/date (it's a Date).
    // Each item in shiftList is a pair of strings representing start and end times (e.g "08:00", "17:00").

    for (let i in shiftList.shifts) {

        const shiftName = shiftList.shifts[i].name;
        const shiftId = shiftList.shifts[i].id;

        // The toDate function is used to parse a Date from a string containing a date and time
        // representing time in any time zone by providing an IANA time zone name on the timeZone option.
        // For example toDate("2023-01-23 08:00", { timeZone: 'America/Los_Angeles' }) yields 8:00 AM in LA on 2023-01-23

        var start:Date = get_date(now, shiftList.shifts[i].start, shiftList.timezone);
        var end:Date = get_date(now, shiftList.shifts[i].end, shiftList.timezone);
        // console.log(formatInTimeZone(start, shiftList.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
        // console.log(formatInTimeZone(end, shiftList.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));

        //var start = 1;
        //var now = 2;
        //var end = 4;
        if (compareDesc(start, end) === 1)                                          // if (start < end)
            if (compareDesc(now, start) === -1 && compareDesc(now, end) === 1)          // if (now > start && now < end)
            {
                // console.log("case 1");
                // console.log('now ' + format(now, 'yyyy-MM-dd kk:mm:ss.SSS'));
                // console.log('start ' + format(start, 'yyyy-MM-dd kk:mm:ss.SSS'));
                // console.log('end ' + format(end, 'yyyy-MM-dd kk:mm:ss.SSS'));

                // start date is today, end date is today
                return {id:shiftId, name:shiftName, start:start, end:end};
            }

        //start = 20;
        //now = 23;
        //end = 4;	// this is tomorrow
        if (compareDesc(start, end) === -1)                                         // if (start > end)
            if (compareDesc(now, start) === -1)                                         // if (now > start)
            {
                // console.log("case 2");
                // console.log('now ' + format(now, 'yyyy-MM-dd kk:mm:ss.SSS'));
                // console.log('start ' + format(start, 'yyyy-MM-dd kk:mm:ss.SSS'));
                // console.log('end ' + format(end, 'yyyy-MM-dd kk:mm:ss.SSS'));

                // start date is today, end date is tomorrow
                return {id:shiftId, name:shiftName, start:start, end:addDays(end, 1)};
            }

        //start = 20;	// this is yesterday
        //now = 1;	
        //end = 4;
        if (compareDesc(start, end) === -1)                                         // if (start > end)
            if (compareDesc(now, end) === 1)                                            // if (now < end)
            {
                // console.log("case 3");
                // console.log('now ' + format(now, 'yyyy-MM-dd kk:mm:ss.SSS'));
                // console.log('start ' + format(start, 'yyyy-MM-dd kk:mm:ss.SSS'));
                // console.log('end ' + format(end, 'yyyy-MM-dd kk:mm:ss.SSS'));

                // start date is yesterday, end date is today
                return {id:shiftId, name:shiftName, start:subDays(start, 1), end:end};
            }
    }

    return undefined;
}

// Finds the previous shift (the shift before the current shift).
// Returns the start time/date and the end time/date of the previous shift.
export function findPreviousShift(now:Date, shiftList:ShiftList): ReportShift | undefined {
    // now is the current time/date (it's a Date).
    // Each item in shiftList is a pair of strings representing start and end times (e.g "8:00", "17:00").

    var index:number = -1;
    var diff:number = Number.MAX_VALUE;

    // Get start time/date and end time/date for the current shift.
    var currentShift = findCurrentShift(now, shiftList);
    if (currentShift === undefined)
        return undefined;

    // The previous shift is the shift whose end time/date is closest to the start time/date of the current shift.

    // Create a temporary shift list whose times have the same date component as today.
    const tempShiftList =
        shiftList.shifts.map((shift) => {
            return {id:shift.id, name: shift.name, start: get_date(now, shift.start, shiftList.timezone), end: get_date(now, shift.end, shiftList.timezone)};
        });

    // console.log("here is tempShiftList");
    // for (let i in tempShiftList) {
    //     console.log(formatInTimeZone(tempShiftList[i].start, shiftList.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
    //     console.log(formatInTimeZone(tempShiftList[i].end, shiftList.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));
    // }

    // Create a variable whose time component is the same as the current shift's start time, and whose date component is the same as today.
    const startTime:string = get_time(currentShift.start, shiftList.timezone);
    const currentShiftStart:Date = get_date(now, startTime, shiftList.timezone);

    // console.log("here is currentShiftStart");
    // console.log(formatInTimeZone(currentShiftStart, shiftList.timezone.toString(), 'yyyy-MM-dd HH:mm:ss zzz'));

    // Find the shift whose end is closest to the start of the current shift (currentShiftStart). This is the previous shift.
    // And calculate the difference in minutes between the start of the current shift (currentShiftStart) and the end of the previous shift.
    for (let i = 0; i < tempShiftList.length; i++) {
        if (tempShiftList[i].name === currentShift.name)
            continue;
        var d = getDifferenceInMinutes(tempShiftList[i].end, currentShiftStart);
        if (d < diff) {
            diff = d;
            index = i;
        }    
        // console.log(`diff is ${d} index is ${i} name is ${tempShiftList[i].name}`)
    }

    // Subtract this difference from the start of the current shift, to yield the date of the end of the previous shift, call this shiftEnd.
    var shiftEnd = sub(currentShift.start, {minutes: diff});

    // Then find the difference between the start and end of the previous shift (e.g. 8:00 to 16:00 is 480 minutes).
    var shiftDiff = getDifferenceInMinutes(tempShiftList[index].end, tempShiftList[index].start);

    // Subtract this difference from the end of the previous shift, to yield the date of the start of the previous shift, call this shiftStart.
    var shiftStart = sub(shiftEnd, {minutes: shiftDiff});

    return {id:tempShiftList[index].id, name:tempShiftList[index].name, start:shiftStart, end:shiftEnd};
}

// Returns the start date/time and the end date/time of the given shift on the given start date.
export function findShift(startDate:string, shiftName:string, shiftList:ShiftList): ReportShift | undefined {

    // Start date is the desired report date.
    // Find the given shift in the shift list.
    // Get start time and end time for the given shift.
    // Combine start date with start time to determine start date/time.
    // Combine end date with end time to determine end date/time.
    // It's possible end date/time will be the following day.
    // It's also possible the selected shift hasn't occurred yet.

    // startDate is the desired report date.
    // startDate will be a string in this format yyyy-mm-dd (e.g. "2023-01-23").
    // Convert startDate to a Date.
    const date:Date = parseISO(startDate);

    // Find the given shift in the shift list.
    var shift: Shift | undefined = undefined;
    for (let i = 0; i < shiftList.shifts.length; i++) {
        if (shiftList.shifts[i].name === shiftName) {
            shift = shiftList.shifts[i];
            break;
        }   
    }

    // Get start time and end time for the given shift.
    // Combine start date with start time to determine start date/time.
    // Combine end date with end time to determine end date/time.
    if (shift !== undefined) {
        var start:Date = get_date(date, shift.start, shiftList.timezone);
        var end:Date = get_date(date, shift.end, shiftList.timezone);

        if (isAfter(start, end))
            return {id:shift.id, name:shift.name, start:start, end:addDays(end, 1)};    // end date is tomorrow
        else
            return {id:shift.id, name:shift.name, start:start, end:end};
    }

    return undefined;
}

// Gets the current shift (the shift that contains the current time (now)).
// Returns the id and name and also start time, end time, and now as UNIX times.
export function getCurrentShift(now:Date, shiftList:ShiftList): ShiftUnixTime | undefined {
    var s = findCurrentShift(now, shiftList);
    if (s !== undefined) {
        return {id:s.id, name:s.name, start:getUnixTime(s.start), end:getUnixTime(s.end), now:getUnixTime(now)};
    }
    return undefined;
}

// Compares two shifts for overlapping times.
// Returns true of overlap is detected, false otherwise.
// Before we call this function we ensure both shifts do not cross midnight.
// Note that this function doesn't need to consider the shift's time zone
// since all shift times are within that time zone. 
// For example, comparing 8:00 and 5:00 within the Eastern time zone is the
// same as comparing 8:00 and 5:00 within the Pacific time zone.
export function checkForShiftOverlap(shiftLeft: _Shift, shiftRight: _Shift): boolean {
	// If now (today) is 2023-11-17 then 
	//  midnight is midnight at the end of today (2023-11-18 24:00:00.000)
	//  and
	//  startOfDay is midnight at the start of today (2023-11-17 24:00:00.000)
	var now:Date = new Date();
	var midnight:Date = set(now, {hours: 24, minutes: 0, seconds: 0, milliseconds: 0});
	var startOfDay:Date = set(now, {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});

	// Split start and end times into hours and minutes.
	var startArray = shiftLeft.start.split(":");
	var endArray = shiftLeft.end.split(":");

	// Set start and end to have the same date component as now (today), but different times.
	var startLeft:Date = set(now, {hours: parseInt(startArray[0]), minutes: parseInt(startArray[1]), seconds: 0, milliseconds: 0});
	var endLeft:Date = set(now, {hours: parseInt(endArray[0]), minutes: parseInt(endArray[1]), seconds: 0, milliseconds: 0});

    // Split start and end times into hours and minutes.
    startArray = shiftRight.start.split(":");
    endArray = shiftRight.end.split(":");

    // Set start and end to have the same date component as now (today), but different times.
    var startRight:Date = set(now, {hours: parseInt(startArray[0]), minutes: parseInt(startArray[1]), seconds: 0, milliseconds: 0});
    var endRight:Date = set(now, {hours: parseInt(endArray[0]), minutes: parseInt(endArray[1]), seconds: 0, milliseconds: 0});

    var overlap: boolean = false;

    // Case #1: Neither shift crosses midnight.
    if (isLess(startLeft, endLeft) && isLess(startRight, endRight)) {
        if (isLess(startLeft, endRight) && isLess(startRight, endLeft)) {
            console.log("overlap case #1");
            overlap = true;
        }
        else {
            console.log("no overlap in case #1");
            overlap = false;
        }
    }

    // Case #2: Both shifts cross midnight.
    // We don't check for this because we don't allow more than one shift to cross midnight.
    // We ensure both shifts do not cross midnight before this function is called.
    else if (isLess(endLeft, startLeft) && isLess(endRight, startRight)) {
        console.log("case #2 detected!");
    }

    // Case #3: shiftLeft crosses midnight, shiftRight does not.
    else if (isLess(endLeft, startLeft) && isLess(startRight, endRight)) {			// if (endLeft < startLeft && startRight < endRight)

        if (isLess(startRight, startLeft) && isGreater(endRight, startLeft)) {		// if (startRight < startLeft && endRight > startLeft)
            console.log("overlap case #3, A");
            overlap = true;
        }	
        else if (isEqual(startLeft, startRight)) {									// else if (startLeft = startRight)
            console.log("overlap case #3, B");
            overlap = true;
        }	
        else if (isGreater(startRight, startLeft) && isLess(endRight, midnight)) {	// else if (startRight > startLeft && endRight < midnight)
            console.log("overlap case #3, C");
            overlap = true;
        }	
        else if (isEqual(endLeft, endRight)) {										// else if (endLeft = endRight)
            console.log("overlap case #3, D");	
            overlap = true;
        }	
        else if (isLess(startRight, endLeft) && isGreater(endRight, endLeft)) {		// else if (startRight < endLeft && endRight > endLeft)
            console.log("overlap case #3, E");
            overlap = true;
        }	
        else {
            console.log("no overlap in case #3");
            overlap = false;
        }	
    }
    
    // Case #4: shiftRight crosses midnight, shiftLeft does not.
    else if (isLess(startLeft, endLeft) && isLess(endRight, startRight)) {						// if (startLeft < endLeft && endRight < startRight)

        if (isGreater(endRight, startLeft) && isLess(endRight, endLeft)) {						// if (endRight > startLeft && endRight < endLeft)
            console.log("overlap case #4, A");	
            overlap = true;
        }	
        else if (isEqual(startLeft, startRight)) {												// else if (startLeft = startRight)
            console.log("overlap case #4, B");
            overlap = true;
        }	
        else if (isLess(startRight, startLeft) && isGreater(addDays(endRight, 1), midnight)) {	// else if (startRight < startLeft && (endRight + 1 day) > midnight)
            console.log("overlap case #4, C");
            overlap = true;
        }	
        else if (isEqual(endLeft, endRight)) {													// else if (endLeft = endRight)
            console.log("overlap case #4, D");	
            overlap = true;
        }	
        else if (isGreater(startRight, startLeft) && isLess(startRight, endLeft)) {				// else if (startRight > startLeft && startRight < endLeft)
            console.log("overlap case #4, E");
            overlap = true;
        }	
        else {
            console.log("no overlap in case #4");
            overlap = false;
        }	
    }

    return overlap;
}

/////////////////////////////////////////////////

// Determines if the given user is a Braun admin.
export async function isBraunAdmin(user: CognitoUser): Promise<boolean> {
    return new Promise<boolean>( (resolver, rejector) => {
        user.getUserAttributes((error, attrs) => {
            if (error || !attrs) {
                return resolver(false)
            }
            else {
                const ba = attrs.find((attr) => attr.Name === "custom:braunAdmin");
                return resolver(ba != null && ba.Value === "true")
            }
        })
    })
}

// Gets customer ID from the given user object.
export async function getCustomerId(user: CognitoUser): Promise<string> {
    return new Promise<string>( (resolver, rejector) => {
        user.getUserAttributes((error, attrs) => {
            if (error) {
                return rejector({message:error.message});
            }
            else if (!attrs) {
                return rejector({message:"User has no attributes"})
            }
            else {
                const ba = attrs.find((attr) => attr.Name === "custom:customer");
                if (ba == null) {
                    return rejector({message:"User has no customer attribute"})
                }
                else {
                    return resolver(ba.Value)
                }
            }
        })
    })
}

// Checks ID for validity.
// Returns true if valid, false otherwise.
export function checkId(id?:string): boolean
{
    if (id === undefined ||
        id.length < 3 ||
        id.length > 12 ||
        id.indexOf("#") !== -1)
    {
        return false;
    }
    return true;
}
