import React from "react";
import {ISiteMetrics, DisplayType} from "../models/types";
import {MultiBarChart2} from '../components/MultiBarChart2';

interface Props {
    list: ISiteMetrics[],
    displayType: DisplayType
}

export const SiteStatusGraphs: React.FC<React.PropsWithoutRef<Props>> = (props: Props) => {

    if (props.list.length > 6) {
        // Display the top 3 and the bottom 3 graphs.
        if (props.displayType === DisplayType.TOTALS) {
            // generate top 3 TOTALS graphs
            // generate bottom 3 TOTALS graphs
            return (
                <>
                    <h5 className="braun-text mb-4">Top Performing Sites</h5>
                    <MultiBarChart2 list={props.list.slice(0,3)} subTitle={"Totals"} displayType={props.displayType} barTitles={["Pounds", "Loads", "Turns"]}/>
                    <h5 className="braun-text mb-4">Bottom Performing Sites</h5>
                    <MultiBarChart2 list={props.list.slice(3,6)} subTitle={"Totals"} displayType={props.displayType} barTitles={["Pounds", "Loads", "Turns"]}/>
                </>
            );
        }
        else if (props.displayType === DisplayType.RATES) {
            // generate top 3 RATES graphs
            // generate bottom 3 RATES graphs
            return (
                <>
                    <h5 className="braun-text mb-4">Top Performing Sites</h5>
                    <MultiBarChart2 list={props.list.slice(0,3)} subTitle={"Rates"} displayType={props.displayType} barTitles={["Pounds/Hour", "Loads/Hour", "Turns/Hour"]}/>
                    <h5 className="braun-text mb-4">Bottom Performing Sites</h5>
                    <MultiBarChart2 list={props.list.slice(3,6)} subTitle={"Rates"} displayType={props.displayType} barTitles={["Pounds/Hour", "Loads/Hour", "Turns/Hour"]}/>
                </>
            );
        }
    }
    else if (props.list.length <= 6) {
        // There are less than 6 sites, so display graphs for all sites.
        if (props.displayType === DisplayType.TOTALS) {
            // generate up to 3 TOTALS graphs
            // if there are more sites, generate up to 3 additional TOTALS graphs
            return (
                <>
                    <h5 className="braun-text mb-4">Site Ranking</h5>
                    <MultiBarChart2 list={props.list.slice(0,3)} subTitle={"Totals"} displayType={props.displayType} barTitles={["Pounds", "Loads", "Turns"]}/>
                    <MultiBarChart2 list={props.list.slice(3,6)} subTitle={"Totals"} displayType={props.displayType} barTitles={["Pounds", "Loads", "Turns"]}/>
                </>
            );
        }
        else if (props.displayType === DisplayType.RATES) {
            // generate up to 3 RATES graphs
            // if there are more sites, generate up to 3 additional RATES graphs
            return (
                <>
                    <h5 className="braun-text mb-4">Site Ranking</h5>
                    <MultiBarChart2 list={props.list.slice(0,3)} subTitle={"Rates"} displayType={props.displayType} barTitles={["Pounds/Hour", "Loads/Hour", "Turns/Hour"]}/>
                    <MultiBarChart2 list={props.list.slice(3,6)} subTitle={"Rates"} displayType={props.displayType} barTitles={["Pounds/Hour", "Loads/Hour", "Turns/Hour"]}/>
                </>
            );
        }
    }

    return <></>
}
