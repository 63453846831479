import classNames from "classnames";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import braun_logo from "../assets/Braun_Logo.png";
import rx_logo from "../assets/Braun_Rx_Logo.png";
import parts_logo from "../assets/Braun_Parts_Logo.png";

export const SupportView: React.FC = () => {
    return (
        <>
        {/* <h1>This is heading 1</h1>
        <h2>This is heading 2</h2>
        <h3>This is heading 3</h3>
        <h4>This is heading 4</h4>
        <h5>This is heading 5</h5>
        <h6>This is heading 6</h6> */}

        <h4 className="mb-4 braun-text">Support</h4>
        <Container fluid>
            <Row className="border border-secondary" style={{ backgroundColor: '#e0e0e0', margin:8}}>
                <Col>
                    <div className="braun-bold-text" style={{padding:20}}>
                        G.A. Braun, Inc.<br/>
                        79 General Irwin Blvd.<br/>
                        North Syracuse, NY 13212
                    </div>
                </Col>
                <Col>
                    <div className="braun-bold-text" style={{padding:20}}>
                        Mail To:<br/>
                        P.O. Box 3029<br/>
                        Syracuse, NY 13220
                    </div>
                </Col>
                <Col>
                    <div className="braun-bold-text" style={{padding:20}}>
                        Main Office:<br/>
                        (315) 475-3123<br/>
                        (800) 432-7286
                    </div>
                </Col>
            </Row>
        </Container>

        <h4 className="mt-4 braun-text">Links</h4>
        <Container fluid>
            <Row>
                <Col className="border border-primary braun-bg" lg={true} style={{margin:20}}>
                    <div style={{padding:20}}>
                        <h4 className={classNames("text-center braun-bold-text my-3")}>G.A. Braun</h4> 
                        <img className={classNames("mx-auto d-block")} src={braun_logo} alt="Washnet" height="60%" width="60%" />
                        <div className={classNames("pb-4")}></div>
                        <p className={classNames("text-center")}><a href="https://www.gabraun.com/" target="_blank" rel="noreferrer">https://www.gabraun.com/</a></p> 
                    </div>
                </Col>
                <Col className="border border-primary braun-bg" lg={true}  style={{margin:20}}>
                    <div style={{padding:20}}>
                        <h4 className={classNames("text-center braun-bold-text my-3")}>Braun Services</h4> 
                        <img className={classNames("mx-auto d-block")} src={braun_logo} alt="Washnet" height="60%" width="60%" />
                        <div className={classNames("pb-4")}></div>
                        <p className={classNames("text-center")}><a href="https://www.gabraun.com/services" target="_blank" rel="noreferrer">https://www.gabraun.com/services</a></p> 
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="border border-primary braun-bg" lg={true} style={{margin:20}}>
                    <div style={{padding:20}}>
                        <h4 className={classNames("text-center braun-bold-text my-3")}>Braun Parts</h4> 
                        <img className={classNames("mx-auto d-block")} src={parts_logo} alt="Washnet" height="60%" width="60%" />
                        <div className={classNames("pb-4")}></div>
                        <p className={classNames("text-center")}><a href="https://braunlaundryparts.com/" target="_blank" rel="noreferrer">https://braunlaundryparts.com/</a></p>
                    </div>
                </Col>
                <Col className="border border-primary braun-bg" lg={true} style={{margin:20}}>
                    <div style={{padding:20}}>
                        <h4 className={classNames("text-center braun-bold-text my-3")}>Braun Rx</h4> 
                        <img className={classNames("mx-auto d-block")} src={rx_logo} alt="Washnet" height="60%" width="60%" />
                        <div className={classNames("pb-4")}></div>
                        <p className={classNames("text-center")}><a href="https://www.gabraunrx.com" target="_blank" rel="noreferrer">https://www.gabraunrx.com/</a></p>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
}
