import {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Data} from "../components/Data"
import { intervalToDuration, formatDuration, format, fromUnixTime } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import {ITimezone} from "react-timezone-select";

export interface ReportProps {
    title: string,
    machineName: string,
    startDate: number,
    endDate: number,
    timezone: ITimezone
}

export const ReportHeader: React.FC<React.PropsWithoutRef<ReportProps>> = (props: ReportProps) => {

    const [startDate, setStartDate] = useState("---");
    const [startTime, setStartTime] = useState("---");
    const [endDate, setEndDate] = useState("---");
    const [endTime, setEndTime] = useState("---");
    const [date, setDate] = useState("---");
    const [duration, setDuration] = useState("---");

    useEffect(() => {
        setDate(format(new Date(), 'MMM dd, yyyy'));

        var s:Date = fromUnixTime(props.startDate);
        var e:Date = fromUnixTime(props.endDate);

        if (props.startDate !== undefined) {
            setStartDate( formatInTimeZone(s, props.timezone.toString(), 'MMM dd, yyyy') );
            setStartTime( formatInTimeZone(s, props.timezone.toString(), 'h:mm aa zzz') );
        }

        if (props.endDate !== undefined) {
            setEndDate( formatInTimeZone(e, props.timezone.toString(), 'MMM dd, yyyy') );
            setEndTime( formatInTimeZone(e, props.timezone.toString(), 'h:mm aa zzz') );
        }

        if (props.startDate !== undefined && props.endDate !== undefined) {
            var d = intervalToDuration({
                start: s,
                end: e
            });
            setDuration(formatDuration(d, { format: ['hours', 'minutes'] }));
        }

    }, []);

    return (
        <div>
            <hr/>
            <Container>
                <Row>
                    <Col className="col-sm">
                    <span><b>{props.title}</b></span>
                    </Col>
                    <Col className="col-sm">
                    <Data value={props.machineName}></Data>
                    </Col>
                    <Col className="col-sm">
                    Date: {date}
                    </Col>
                </Row>
            </Container>
            <hr/>
            <Container>
                <Row>
                    <Col className="col-sm">
                    Start Date: {startDate}
                    </Col>
                    <Col className="col-sm">
                    Start Time: {startTime}
                    </Col>
                    <Col className="col-sm">
                    Duration: {duration}
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm">
                    End Date: {endDate}
                    </Col>
                    <Col className="col-sm">
                    End Time: {endTime}
                    </Col>
                    <Col className="col-sm">
                    </Col>
                </Row>
            </Container>
            <hr/>
        </div>
    );
}
