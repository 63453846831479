import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Alert from "react-bootstrap/Alert";
import {Alarms} from "../models/types";
import {Data} from "./Data"
import {WashnetBackend} from "../services/WashnetBackend";

export interface AlarmSummaryProps {
    backend: WashnetBackend,
    machineAlarms?: Alarms[],
    path: string
}

// Alarm summary component.
export const AlarmSummary: React.FC<AlarmSummaryProps> = (props: AlarmSummaryProps) => {

    return (
        <>
        <h5 className="braun-text">Alarm Summary</h5>
        
        {
        (props.machineAlarms !== undefined) && (props.machineAlarms.length === 0) && 
            <Alert variant="warning">No current alarms</Alert>
        }

        {
        (props.machineAlarms !== undefined) && (props.machineAlarms.length > 0) &&
            <Accordion>
                {
                props.machineAlarms.map((item, i) =>
                    <Accordion.Item key={i} eventKey={i.toString()}>
                        <Accordion.Header><span><b><Data value={props.backend.getMachineName(props.path, item.machineId)}/> Alarms</b></span></Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <tbody>
                                {
                                    item.alarms.map((a, j) =>
                                    <tr key={j.toString()}>
                                        <td>Count: <Data value={a.count}/></td>
                                        <td>Alarm: <Data value={a.alarm}/></td>
                                    </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                )
                }
            </Accordion>
        }
        </>
    );
}
