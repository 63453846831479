import {useState} from "react";

/*
 * A react hook for keeping track of when things are finished loading.  To use it,
 * everything that takes time (e.g. a promise loading something from a web server)
 * needs to call inc() when starting and dec() when completing.  Care needs to be
 * taken to make sure dec() is called on exception, usually with either a catch()
 * or finally() block.
 */
export interface ICounter {
    inc: () => void,
    dec: () => void,
    isBusy: () => void
}

export function useBusy(): ICounter {
    const [busyCount, setBusyCount] = useState<number>(0);
    // console.log(busyCount);
    return {
        inc: () => {setBusyCount(busyCount + 1)},
        dec: () => {setBusyCount(busyCount - 1)},
        isBusy: () => {return busyCount > 0}
    }
}