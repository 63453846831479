import {SystemNode} from "./system";
import {IShiftList} from "./types";
import {ProductionTotals, SystemSummary} from "./types";

export interface SiteListResponse {
    sites: ISite[]
}

export interface SiteConfig {
    shiftList: IShiftList
}

export interface ISite {
    id: string,
    name: string,
    desc: string;
    cfg: SiteConfig
}

export class Site implements ISite {
    constructor(id="", name="", desc="", cfg={ shiftList: {timezone:"America/New_York", shifts:[]} } )
    {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.cfg = cfg;
    }

    id: string;
    name: string;
    desc:string;
    cfg: SiteConfig;
}

// For tree
export interface SiteNode {
    id: string,
    name: string;
    systems: SystemNode[];
}

///////////////////////////////////

export interface SiteStatusResponse {
    overall_totals?: ProductionTotals,   // Overall production totals for all systems at this site (ID will be site ID)
    summaries?: SystemSummary[]          // Summary for each system at this site (IDs will be system IDs)
}
