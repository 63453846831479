import {Container, Row, Col} from "react-bootstrap";
import washer from "../assets/washer.png";
import dryer from "../assets/dryer.png";
import tunnel from "../assets/tunnel.png";
import press from "../assets/press.png";
import generic from "../assets/generic_machine.png";
// import dryer from "../assets/dryer.svg";
// import washer from "../assets/washer.svg";
// import tunnel from "../assets/tunnel.svg";

interface MachineIconProps {
    machineType: string,
    machineName: string
}

// Returns a machine image.
function renderImage(machineType:string) {

    // For machines that aren't a washer, dryer, tunnel or press, return a generic icon.
    // We will use a different generic icon if Braun supplies one.

    if (machineType === undefined)
        return null;
    else if (machineType.toLowerCase() === "washer")
        return <img src={washer} alt="washer" width="17" height="23"/>
    else if (machineType.toLowerCase() === "dryer")
        return <img src={dryer} alt="dryer" width="17" height="23"/>
    else if (machineType.toLowerCase() === "tunnel")
        return <img src={tunnel} alt="tunnel" width="56" height="19"/>
    else if (machineType.toLowerCase() === "press")
        return <img src={press} alt="press" width="16" height="17"/>
    else
        return <img src={generic} alt="generic" width="19" height="15"/>
}

export const MachineIcon: React.FC<MachineIconProps> = (props: MachineIconProps) => {
    return (
        <Container className="fluid m-0 p-0">
          <Row>
            <Col className="d-flex justify-content-end m-0 p-0">{renderImage(props.machineType)}</Col>
            <Col className="d-flex justify-content-start ms-2 p-0">{props.machineName}</Col>
          </Row>
        </Container>
    );
}
