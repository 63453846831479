import classNames from "classnames";
import { EventKey } from '@restart/ui/types';
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {IUser, UserPermissionsSet} from "../models/user";
import {CustomerNode} from "../models/customer";
import {SiteNode} from "../models/site";
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import {Trash3} from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import {DialogType} from "../util"

interface PermissionsEditorProps {
    show: boolean,
    title: string,
    type: DialogType,
    handleSave: (p:UserPermissionsSet) => void,
    handleClose: () => void,
    user: IUser,                     // The user being edited
    currentUser: IUser | undefined,  // The currently logged in user (the user doing the editing)
    customerTree?: CustomerNode      // The tree for this customer.
}

enum UserPermissionState {
    RO=0, RW=1, ADMIN=2
}

// Represents a row in the permission table.
class TableRow {
    constructor(resource = "", state = UserPermissionState.RO)
    {
        this.resource = resource;
        this.state = state;
    }

    resource: string;
    state: UserPermissionState;
}

export const ModalEditPermissions: React.FC<PermissionsEditorProps> = (props: PermissionsEditorProps) => {

    const [permissionArray, setPermissionArray] = useState<TableRow[]>([]);
    const [selectedTreeItem, setSelectedTreeItem] = useState<string>("");
    const [isCustAdmin, setIsCustAdmin] = useState(false);
    const [isBraunAdmin, setIsBraunAdmin] = useState(false);
    const [customerTree, setCustomerTree] = useState<CustomerNode | undefined>(undefined);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function clearAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    useEffect(() => {
        // Copy values sent to this component from the parent to the form fields.

        setCustomerTree(props.customerTree);
        
        // Create the permission array from values passed in props.user.
        let arr: Array<TableRow> = [];

        // The ro, rw and admin arrays returned by getUser() (and passed to us in props.user) will be in one of two states:
        //   [] an empty array, meaning the associated string set doesn't exist in the DynamoDB table
        //   ["somestring", ...] an array of one or more strings, meaning the associated string set exists in the DynamoDB table and contains those strings

        if (props.user !== undefined) {
            if (props.user.ro !== undefined && props.user.ro.length > 0) {
                props.user.ro.map((item) => ( arr.push(new TableRow(item, UserPermissionState.RO)) ));
            }

            if (props.user.rw !== undefined && props.user.rw.length > 0) {
                props.user.rw.map((item) => ( arr.push(new TableRow(item, UserPermissionState.RW)) ));
            }

            if (props.user.admin !== undefined && props.user.admin.length > 0) {
                props.user.admin.map((item) => ( arr.push(new TableRow(item, UserPermissionState.ADMIN)) ));
            }
    
            if (props.user.customerAdmin !== undefined)
                setIsCustAdmin(props.user.customerAdmin);

            if (props.user.superuser !== undefined)
                setIsBraunAdmin(props.user.superuser);
        }

        setPermissionArray(arr);
        clearAlert();
    }, [props.user, props.customerTree]);

    // Helper function that returns true if the resource string represents a site (e.g. rit#main), false otherwise.
    function isSite(resource: string) : boolean {
        // A site resource string (e.g. rit#main) has one #, meaning it can be split into two strings.
        return (resource.split("#").length === 2) ? true : false;
    }

    // Helper function that returns true if the resource string (e.g. rit#syracuse) is already in the permissiion table, false otherwise.
    function isDuplicate(resource: string) : boolean {
        for (let i = 0; i < permissionArray.length; i++)
            if (permissionArray[i].resource.toLowerCase() === resource.toLowerCase())
                return true;
        return false;
    }

    // Helper function that returns true if the user has admin access to the resource, false otherwise.
    function isSiteAdmin(resource: string, user: IUser | undefined) : boolean {
        if (user !== undefined) {
            if (user.admin !== undefined) {
                for (let i = 0; i < user.admin.length; i++)
                    if (user.admin[i].toLowerCase() === resource.toLowerCase())
                        return true;
            }
        }
        return false;
    }

    // Handler for the save button.
    const handleSave = () => {
        // Create a new permissions object containing the edited permissions and return to the parent.
        // It's the parent's responsibility to save the new permissions to the backend.

        let rw: Array<string> = [];
        for (let i = 0; i < permissionArray.length; i++)
            if (permissionArray[i].state === UserPermissionState.RW)
                rw.push(permissionArray[i].resource);

        let ro: Array<string> = [];
        for (let i = 0; i < permissionArray.length; i++)
            if (permissionArray[i].state === UserPermissionState.RO)
                ro.push(permissionArray[i].resource);

        let admin: Array<string> = [];
        for (let i = 0; i < permissionArray.length; i++)
            if (permissionArray[i].state === UserPermissionState.ADMIN)
                admin.push(permissionArray[i].resource);

        // Create the edited permission set.
        let perms:UserPermissionsSet = {
            superuser: isBraunAdmin,
            customerAdmin: isCustAdmin,
            ro: ro,
            rw: rw,
            admin: admin
        }

        // Clear these states.
        setPermissionArray([]);
        setSelectedTreeItem("");
        setIsCustAdmin(false);
        setIsBraunAdmin(false);
        clearAlert();

        // Return the edited permission set to the parent.
        props.handleSave(perms);
    }

    // Handler for the cancel button.
    const handleClose = () => {
        // Clear these states.
        setPermissionArray([]);
        setSelectedTreeItem("");
        setIsCustAdmin(false);
        setIsBraunAdmin(false);
        clearAlert();
    
        // Don't return anything to the parent.
        props.handleClose();
    }

    const handleAdd = () => {
        if (selectedTreeItem === "") {
            displayAlert("Nothing is selected in the resource tree. Select a resource and try again.");
            return;
        }

        if (isDuplicate(selectedTreeItem)) {
            displayAlert(`${selectedTreeItem} is already in the permission table. Duplicates not allowed.`);
            return;
        }

        // Add the selected item in the tree as a new row in the table.
        var arr:TableRow[] = JSON.parse(JSON.stringify(permissionArray));
        arr.push(new TableRow(selectedTreeItem));
        setPermissionArray(arr);
    }

    const handleDelete = (index:number) => {
        // Delete the row at the given index from the table.
        var arr:TableRow[] = JSON.parse(JSON.stringify(permissionArray));
        arr.splice(index,1);
        setPermissionArray(arr);
    }

    // Called when an item is selected in the resource tree.
    const onSelect = (eventKey: EventKey | null) => {
        if (eventKey) {
            setSelectedTreeItem(`${eventKey}`);
        }
    }

    // Change handler for the radio button groups.
    function onChangeRadio(event: React.ChangeEvent<HTMLInputElement>, index:number) {
        // index indicates the index of a row in the permission table.

        let allowed:boolean = false;
        
        // Check if the logged in user is allowed to set the site admin radio button.
        // Only a customer admin or a superuser or a site admin for this site is allowed to set the site admin button.
        if (parseInt(event.target.value) === UserPermissionState.ADMIN) {
            if (props.currentUser?.customerAdmin || props.currentUser?.superuser) {
                allowed = true;
            }
            else {
                // Check is the current user is site admin for this resource.
                // If he is, he is allowed to set the site admin button.
                allowed = isSiteAdmin(permissionArray[index].resource, props.currentUser);
            }
        }
        else {
            allowed = true;
        }

        if (allowed) {
            var arr:TableRow[] = JSON.parse(JSON.stringify(permissionArray));
            arr[index].state = parseInt(event.target.value);
            setPermissionArray(arr);
        }
        else {
            displayAlert("Only a customer admin or site admin can set this user as a site admin.");
        }
    }

    // Change handler for the customer administrator checkbox.
    function onChangeCustAdminBox(event: React.ChangeEvent<HTMLInputElement>) {
        // Only a customer admin or a superuser is allowed to check the customer admin box.
        if (props.currentUser?.customerAdmin || props.currentUser?.superuser) {
            setIsCustAdmin(event.target.checked);
        }
        else {
            displayAlert("Only a customer admin can set this user as customer admin.");
        }
    }

    // Change handler for the Braun administrator checkbox.
    function onChangeBraunAdminBox(event: React.ChangeEvent<HTMLInputElement>) {
        // Only a superuser is allowed to check the Braun admin box.
        if (props.currentUser?.superuser) {
            setIsBraunAdmin(event.target.checked);
        }
        else {
            displayAlert("Only a Braun admin can set this user as Braun admin.");
        }
    }

    return (
        <>
        <Modal show={props.show} backdrop="static" size="lg" scrollable={true}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{`Permissions for ${props.user.email}`}</h5>
                <Form>
                    {/* Show the Braun admin checkbox only if the user doing the editing is a Braun admin. */}
                    {
                    (props.currentUser?.superuser !== undefined && props.currentUser?.superuser === true) &&
                    <Form.Group className={classNames("mb-3")} controlId="braun">
                        <Form.Check 
                            type="checkbox"
                            id={"braunadmin"}
                            label={"Braun Administrator"}
                            checked={isBraunAdmin}
                            disabled={!props.currentUser?.superuser}
                            onChange={(event) => onChangeBraunAdminBox(event)}
                        />

                        <Form.Text className="text-muted">
                            Check this box to make this user a Braun administrator (you must be a Braun administrator to check this box).
                        </Form.Text>
                    </Form.Group>
                    }

                    <Form.Group className={classNames("mb-3")} controlId="cust">
                        <Form.Check 
                            type="checkbox"
                            id={"admin"}
                            label={"Company Administrator"}
                            checked={isCustAdmin}
                            disabled={!props.currentUser?.customerAdmin && !props.currentUser?.superuser}
                            onChange={(event) => onChangeCustAdminBox(event)}
                        />

                        <Form.Text className="text-muted">
                            Check this box to make this user a company administrator (you must be a Braun admimistrator or a customer administrator to check this box).
                            Otherwise, set permissions for this user in the table below.
                            To add a permission to the table, select the desired resource in the tree, press <i>Add Permission</i>,
                            then select <i>Read-Only</i> or <i>Read-Write</i> for that resource.
                            &nbsp;<i>Site Admin</i> permission can be given only if the resource is a site (and you are an administrator for that site).
                        </Form.Text>
                    </Form.Group>

                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>Resource</th>
                                <th>Resource Permission</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            permissionArray.map((item:TableRow, i:number) =>
                                <tr key={i}>
                                    <td>
                                        {item.resource}
                                    </td>
                                    <td>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="Read-Only"
                                                checked={item.state === UserPermissionState.RO}
                                                value={UserPermissionState.RO}
                                                name={`group${i}`}
                                                type="radio"
                                                onChange={(event) => onChangeRadio(event, i)}
                                            />
                                            <Form.Check
                                                inline
                                                label="Read-Write"
                                                checked={item.state === UserPermissionState.RW}
                                                value={UserPermissionState.RW}
                                                name={`group${i}`}
                                                type="radio"
                                                onChange={(event) => onChangeRadio(event, i)}
                                            />
                                            <Form.Check
                                                inline
                                                label="Site Admin"
                                                checked={item.state === UserPermissionState.ADMIN}
                                                disabled={!isSite(item.resource)}
                                                value={UserPermissionState.ADMIN}
                                                name={`group${i}`}
                                                type="radio"
                                                onChange={(event) => onChangeRadio(event, i)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <Button variant="outline-dark" size="sm" onClick={() => handleDelete(i)}><Trash3/></Button>
                                    </td>
                                </tr>
                            )
                            }
                        </tbody>
                    </Table>
                </Form>

                {/* This alert is for showing errors. It is always visible. */}
                <Alert variant={showAlert ? "danger" : "light"}>
                    {alertMessage}
                </Alert>

                <h5>Resource Tree</h5>
                <div style={{ border: '2px groove' }}>

                    {/* We don't include systems and machines is this SidebarMenu because we
                        don't allow permissions to be set at the system or machine level. */}

                    {
                    (customerTree !== undefined) &&
                    <SidebarMenu
                        exclusiveExpand={false}
                        collapseOnSelect={false}
                        onSelect={onSelect}
                        bg="light"
                        variant="light"
                        expand={false}
                        defaultExpanded={true}
                        hide={false}
                    >
                        <SidebarMenu.Collapse>
                            <SidebarMenu.Body>

                                {/* Indentation Level 0 - Customer */}
                                <SidebarMenu.Nav className="customer">
                                    <SidebarMenu.Sub>
                                        <SidebarMenu.Sub.Toggle>
                                            <SidebarMenu.Nav.Link eventKey={`${customerTree.id}`}>
                                                <SidebarMenu.Nav.Icon/>
                                                <SidebarMenu.Nav.Title>{customerTree.name}</SidebarMenu.Nav.Title>
                                            </SidebarMenu.Nav.Link>
                                        </SidebarMenu.Sub.Toggle>

                                        {/* Indentation Level 1 - Site */}
                                        {
                                            // For each site...
                                            customerTree.sites.map((site:SiteNode, i:number) =>
                                                <SidebarMenu.Sub.Collapse key={i.toString()}>
                                                    <SidebarMenu.Nav className="site">
                                                        <SidebarMenu.Nav.Link eventKey={`${customerTree.id}#${site.id}`}>
                                                            <SidebarMenu.Nav.Icon/>
                                                            <SidebarMenu.Nav.Title>{site.name}</SidebarMenu.Nav.Title>
                                                        </SidebarMenu.Nav.Link>
                                                    </SidebarMenu.Nav>
                                                </SidebarMenu.Sub.Collapse>
                                                // end site
                                            )
                                        }

                                    </SidebarMenu.Sub>
                                </SidebarMenu.Nav>
                                {/* end customer */}

                            </SidebarMenu.Body>
                        </SidebarMenu.Collapse>
                    </SidebarMenu>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleAdd} className={classNames("me-5")}>Add Permission</Button>
                <Button variant="secondary" onClick={handleClose} className={classNames("me-2")}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
