import React from "react";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {WashnetBackend} from "../services/WashnetBackend";
import {ISystem, SystemListResponse, System} from "../models/system";
import {IUser} from "../models/user";
import {ModalEditSystem} from "./ModalEditSystem";
import {Trash3, Pencil} from 'react-bootstrap-icons';
import {ModalShowMessage} from "./ModalShowMessage"
import {Data} from "../components/Data"
import {DialogType} from "../util"

export interface SiteDetailProps {
    backend: WashnetBackend,
    currentUser: IUser | undefined
}

export const SiteDetail: React.FC<SiteDetailProps> = (props: SiteDetailProps) => {
    const [systems, setSystems] = useState<ISystem[]>([]);
    const {customerId, customerName, siteId, siteName} = useParams();

    // Function to read the system list from the backend.
    // Called from useEffect and when the system list is edited.
    var refreshSystemList = () => {
        props.backend.getSystemsForSite(customerId as string, siteId as string)
        .then((response: SystemListResponse) => {
            setSystems(response.systems)
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined) {
            console.log("customerId or siteId is undefined");
            return;
        }
        refreshSystemList();
    }, [customerId, siteId]);

    ////////////////////////////////////////////////////////////////////

    // Values sent to the add/edit dialogs.
    const [systemValues, setSystemValues] = useState<ISystem>(new System());

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    ///////////////////////////////////
    // For Add modal dialog
    const [showAddDialog, setShowAddDialog] = useState(false);

    const handleShowAddDialog = () => {
        setSystemValues(new System());
        setShowAddDialog(true);
    }
        
    // This is a callback called by the child component ModalEditSystem.
    // The child passes the new system values in parameter sys.
    const handleSaveAddDialog = (system:ISystem) => {
        setShowAddDialog(false);

        // Create a new system in the backend.
        props.backend.createSystem(customerId as string, siteId as string, system)
        .then((site: ISystem) => {
            refreshSystemList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseAddDialog = () => {
        setShowAddDialog(false);
    }

    ///////////////////////////////////
    // For Edit modal dialog
    const [showEditDialog, setShowEditDialog] = useState(false);

    // Opens editor for a system (row) in the table.
    const handleShowEditDialog = (index:number) => {
        // This does a deep copy.
        setSystemValues(JSON.parse(JSON.stringify(systems[index])));

        setShowEditDialog(true);
    }

    // This is a callback called by the child component ModalEditSystem.
    // The child passes the updated system values in parameter sys.
    const handleSaveEditDialog = (system:ISystem) => {
        setShowEditDialog(false);

        // Update this system in the backend.
        props.backend.updateSystem(customerId as string, siteId as string, system)
        .then((site: ISystem) => {
            refreshSystemList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    }

    // Deletes a system (row) from the table.
    const handleDelete = (index:number) => {
        // index is row clicked in the table

        // Delete this system from the backend.
        props.backend.deleteSystem( customerId as string, siteId as string, systems[index].id )
        .then(() => {
            refreshSystemList();
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    return(
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>Administration Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/customers" }}>Manage Companies</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/customers/${customerId}/${customerName}` }}>{customerName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/sites/${customerId}/${customerName}/${siteId}/${siteName}` }}>{siteName}</Breadcrumb.Item>
            </Breadcrumb>

            <h4>{`Manage Site: ${siteName}`}</h4>

            <div className={classNames("pb-4")}></div>
            <h5>Systems</h5>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>System Name</th>
                        <th>System ID</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    systems.map((system: ISystem, i: number) =>
                        <tr key={i.toString()}>
                            <td><Link to={`/admin/systems/${customerId}/${customerName}/${siteId}/${siteName}/${system.id}/${system.name}`}><Data value={system.name}/></Link></td>
                            <td><Data value={system.id}/></td>
                            <td><Data value={system.desc}/></td>
                            <td>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="edit">Edit System</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleShowEditDialog(i)} className={classNames("me-2")}><Pencil/></Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement={'top'} overlay={<Tooltip id="delete">Delete System</Tooltip>}>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleDelete(i)}><Trash3/></Button>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            <Button variant="primary" onClick={handleShowAddDialog}>Add System</Button>
            
            <hr/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />

            {/* Modal dialog to add a system. */}
            <ModalEditSystem show={showAddDialog}
                title={"Add System"}
                type={DialogType.ADD}
                handleSave={handleSaveAddDialog}
                handleClose={handleCloseAddDialog}
                systemValues={systemValues}
            />

            {/* Modal dialog to edit an existing system. */}
            <ModalEditSystem show={showEditDialog}
                title={"Edit System"}
                type={DialogType.EDIT}
                handleSave={handleSaveEditDialog}
                handleClose={handleCloseEditDialog}
                systemValues={systemValues}
            />
        </>
    );
}