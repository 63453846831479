import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classNames from "classnames";

export interface ModalShowProps {
    show: boolean,
    variant: string,
    message: string,
    handleClose: () => void,
}

export const ModalShowMessage: React.FC<ModalShowProps> = (props: ModalShowProps) => {

    const handleClose = () => {
        props.handleClose();
    }

    return (
        <Modal centered show={props.show} backdrop="static" size="sm">
            <Modal.Header className={classNames("message-header")}>
                <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
