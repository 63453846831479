import React from "react";
import classNames from "classnames";
import {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {idExpression, nameExpression, DialogType} from "../util"
import {ISite, Site} from "../models/site";
import Alert from "react-bootstrap/Alert";

export interface ModalEditSiteProps {
    show: boolean,
    title: string,
    type: DialogType,
    handleSave: (sys:ISite) => void,
    handleClose: () => void,
    siteValues: ISite,
}

export const ModalEditSite: React.FC<ModalEditSiteProps> = (props: ModalEditSiteProps) => {
    const [siteId, setSiteId] = useState("");
    const [idValid, setIdValid] = useState(true);
    const [siteName, setSiteName] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [description, setDescription] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function clearAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    useEffect(() => {
        // Copy values sent to this component from the parent to the form fields.
        setSiteId(props.siteValues.id);
        setIdValid(true);
        setSiteName(props.siteValues.name);
        setNameValid(true);
        setDescription(props.siteValues.desc);
        clearAlert();
    }, [props.siteValues]);

    const updateId = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSiteId(event.target.value)
        setIdValid(event.target.value.length === 0 || idExpression.test(event.target.value))
    }

    const updateName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSiteName(event.target.value)
        setNameValid(event.target.value.length === 0 || nameExpression.test(event.target.value))
    }

    const handleSave = () => {
        // We need to run regex tests here because idValid and nameValid are set only if the user edits the fields.
        if (!idExpression.test(siteId) || !nameExpression.test(siteName)) {
            displayAlert("Enter valid system ID and name");
        }
        else {
            var s = new Site(siteId, siteName, description);

            setSiteId("");
            setSiteName("");
            setDescription("");
            clearAlert();
    
            // Return new site to the parent.
            props.handleSave(s);
        }
    }

    const handleClose = () => {
        // Note we are not returning anything to the parent.
        setSiteId("");
        setSiteName("");
        setDescription("");
        clearAlert();
        props.handleClose();
    }

    return (
        <Modal show={props.show} backdrop="static" scrollable={true}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Label>Site ID</Form.Label>
                    <Form.Group className={classNames("mb-3")} controlId="site-id">

                        {
                        props.type === DialogType.ADD &&
                        <Form.Control type="text" value={siteId} isInvalid={!idValid} onChange={(event) => updateId(event)}/>
                        }

                        {
                        props.type === DialogType.EDIT &&
                        <Form.Control type="text" value={siteId} disabled/>
                        }

                        <Form.Text className="text-muted">
                            Site ID must be unique for this company. It should be short and contain only
                            lowercase letters, digits, underscores, and hyphens.
                            An existing ID cannot be changed.
                        </Form.Text>
                    </Form.Group>
                    <Form.Label>Site Name</Form.Label>
                    <Form.Group className={classNames("mb-3")} controlId="site-name">
                        <Form.Control type="text" value={siteName} isInvalid={!nameValid} onChange={(event) => updateName(event)}/>
                        <Form.Text className="text-muted">
                            Enter a friendly name for the site.
                            It can contain uppercase and lowercase letters, digits, underscores, hyphens, periods and spaces.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className={classNames("mb-3")} controlId="desc">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" value={description}  placeholder="(optional)" onChange={(event) => setDescription(event.target.value)}/>
                    </Form.Group>

                    {/* This alert is for showing errors. It is always visible. */}
                    <Alert variant={showAlert ? "danger" : "light"}>
                        {alertMessage}
                    </Alert>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}