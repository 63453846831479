import {useState} from "react";
import Accordion from 'react-bootstrap/Accordion';
import Alert from "react-bootstrap/Alert";
import {States} from "../models/types";
import {Led} from './Led';
import {LedBar} from './LedBar';
import {AccordionEventKey} from "react-bootstrap/esm/AccordionContext";
import {Data} from "./Data"
import {WashnetBackend} from "../services/WashnetBackend";

export interface StatusSummaryProps {
    backend: WashnetBackend,
    machineStates?: States[],
    path: string
}

// Machine status summary component.
export const StatusSummary: React.FC<StatusSummaryProps> = (props: StatusSummaryProps) => {
    const [currentKey, setCurrentKey] = useState<AccordionEventKey>(null);

    // Callback fired when an Accordion.Header is clicked.
    const handleSelect = (event: AccordionEventKey) => {
        // event will be null when all accordion items are closed.
        // event will be the item number (0, 1, etc.) when an item is expanded.
        setCurrentKey(event);
    }

    return (
        <>
        <h5 className="braun-text">Status Summary</h5>

        {
        (props.machineStates !== undefined) && (props.machineStates.length === 0) && 
            <Alert variant="warning">No current status information</Alert>
        }

        {
        (props.machineStates !== undefined) && (props.machineStates.length > 0) && 
            <Accordion onSelect={event => handleSelect(event)}>
                {
                props.machineStates.map((item, i) =>
                    <Accordion.Item key={i} eventKey={i.toString()}>
                        {/* This always shows the LedBar. */}
                        {/* <Accordion.Header><b>{item.machineType}</b>&nbsp;<LedBar machineStates={item.states}/></Accordion.Header> */}

                        {/* This hides the LedBar when the header is open, shows it otherwise. */}
                        <Accordion.Header><span className="pe-0"><b><Data value={item.machineType}/></b></span> {currentKey !== i.toString() && <LedBar machineStates={item.states}/>} </Accordion.Header>

                        <Accordion.Body>
                            {
                                item.states.map((s, j) =>
                                    <p key={j.toString()}><span className="pe-0"><Led machineState={s}/></span><Data value={props.backend.getMachineName(props.path, s.machineId)}/> - <Data value={s.machineStatus}/></p>
                                )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                )
                }
            </Accordion>
        }
        </>
    );
}
