import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import classNames from "classnames";

/*
 * This component is a wrapper around bootstrap's Modal but specialized to display Amplify
 * auth errors.  If the alert passed in is undefined the alert will be hidden.
 */

export interface AmplifyAuthError {
    message: string,
    code: string
}

interface AmplifyAuthAlertProps {
    error?: AmplifyAuthError
}

export const AmplifyAuthAlert: React.FC<AmplifyAuthAlertProps> = (props: AmplifyAuthAlertProps) => {
    const [show, setShow] = useState<boolean>(false);

    const doClose = () => {
        setShow(false)
    }

    useEffect(() => {
        if (props.error !== undefined) {
            setShow(true)
        }
    }, [props.error])

    return <Modal
        centered
        show={show}
        onEscapeKeyDown={doClose}
        onHide={doClose}
        onClose={doClose}>
        <Modal.Header className={classNames("message-header")} closeButton={true}>{props.error?.code}</Modal.Header>
        <Modal.Body>{props.error?.message}</Modal.Body>
    </Modal>
}
