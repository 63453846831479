import Button from 'react-bootstrap/Button';
import {WashnetBackend} from "../services/WashnetBackend";
// import {ICustomer, Customer, CustomerNode, CustomerListResponse, CustomerStatusResponse} from "../models/customer";
// import {ISite, Site, SiteListResponse, SiteStatusResponse} from "../models/site";
// import {ISystem, System, SystemListResponse, SystemStatusResponse} from "../models/system";
// import {IMachine, Machine, MachineListResponse, MachineStatusResponse, MachineAlarmsResponse} from "../models/machine";
// import {IUser, User, UserListResponse, UserPermissions} from "../models/user";
// import {ShiftList} from '../models/types';
// import {getCurrentShift, unixTimeToString} from "../util";

export interface TestViewProps {
    backend: WashnetBackend
}

// THIS PAGE IS FOR TESTING REST API CALLS TO THE WASHNET BACKEND.
// MOST OF THIS SHOULD BE COMMENTED OUT. ENABLE ONLY THE FUNCTION YOU ARE TESTING.

export const TestView: React.FC<TestViewProps> = (props: TestViewProps) => {
    const handleClick = () => {

        /********************************************************************
         *** TEST WHO AM I
         ********************************************************************/

        // props.backend.whoAmI()
        // .then((response: IUser) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error.message);
        // })

        /********************************************************************
         *** TEST CUSTOMER STUFF
         ********************************************************************/

        // Test getting all customers. It worked!
        // props.backend.getAllCustomers()
        // .then((response: CustomerListResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error.message);
        // })

        // Test creating a new, valid customer. It worked!
        // Test creating an existing customer. It returned "Customer with this ID already exists", which is correct.
        // Test creating a customer with invalid ID or name. It returned "Invalid customer ID or name specified", which is correct.
        // let c = new Customer("apple", "Apple Laundry", {targets: {pounds:1, pounds_per_hour:2, loads:3, loads_per_hour:4, turns:5, turns_per_hour:6}});
        // let c = new Customer("grape", "Grape Laundry", "A really grape company!");
        // props.backend.createCustomer(c)
        // .then((response: ICustomer) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error.message);
        // })

        // Test getting an existing customer. It worked!
        // Test getting a non-existent customer. It returned "Customer ID doesn't exist", which is correct!
        // Test getting with a bad ID. It returned "Invalid customer ID specified", which is correct!
        // props.backend.getCustomer("rit")
        // .then((response: ICustomer) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test updating an existing customer (with a new name). It worked!
        // Test updating a non-existent customer. Returned ""Cannot update customer, customer ID crapola doesn't exist" which is correct.
        // Test with bad customer ID in the body. Returned "Invalid customer ID specified" which is correct
        // Test with bad customer name in the body. Returned "Invalid customer ID or name specified in request body" which is correct
        // Try mismatch between customer ID on url and customer ID in body. Returned "Path paramater customer ID doesn't match customer ID in request body", which is correct.
        // let cust = new Customer("funkyid", "fred");
        // let cust = new Customer("apple", "New Apple Laundry", "", {targets: {pounds:11, pounds_per_hour:12, loads:13, loads_per_hour:14, turns:15, turns_per_hour:16}});
        // let cust = new Customer("rit", "Rochester Institute of Technology", "I Love RIT", {targets: {pounds:11, pounds_per_hour:12, loads:13, loads_per_hour:14, turns:15, turns_per_hour:16}});
        // props.backend.updateCustomer(cust)
        // .then((response: ICustomer) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test deleting an existing customer. It worked!
        // Test deleting an non-existent customer. It returned OK, which is correct.
        // Test deleting with a bad customer ID. It returned "Invalid customer ID specified", which is correct.
        // props.backend.deleteCustomer("grape")
        // .then((response) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
         *** TEST SITE STUFF
         ********************************************************************/

        // Test for an existing customer that has sites. Returned an array of sites, which is correct.
        // Test for an existing customer that has no sites. Returned an empty array, which is correct.
        // Test for a non-existent customer. Returned an empty array, which is correct.
        // Test with a bad ID. Returned "Invalid customer ID specified", which is correct!
        // props.backend.getSitesForCustomer("jones")
        // .then((response: SiteListResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test with invalid site id. Returned "Invalid site ID or name specified', which is correct
        // Test with invalid site name. Returned "Invalid site ID or name specified', which is correct
        // Test with invalid customer ID. Returned "Invalid customer ID specified", which is correct
        // Test creating a site that already exists for this customer. It returned "Site with this ID already exists'", which is correct.
        // Test creating a site for non-existent customer ID. Returned "Cannot create site, customer doesn't exist", which is correct.
        // Test creating a site for an existing customer ID. It worked.
        // let site = new Site("1", "newsitename");            // invalid ID
        // let site = new Site("newsiteid", "x");              // invalid name
        // let site = new Site("newsiteid", "newsitename");    // valid site
        // let site = new Site("city", "RIT City Campus");     // valid site
        // let site = new Site("city", "Grape City Campus");     // valid site
        // let site = new Site("testsite", "Test Site");     // valid site
        // props.backend.createSite("rit", site)
        // .then((response: ISite) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test getting an existing customer and site. It worked!
        // Test getting a non-existent customer. It returned "Site ID junk#main doesn't exist"", which is correct!
        // Test getting a non-existent site. It returned "Site ID rit#junk doesn't exist"", which is correct!
        // Test getting with a bad customer ID. It returned "Invalid customer ID specified", which is correct!
        // Test getting with a bad site ID. It returned "Invalid site ID specified", which is correct!
        // props.backend.getSite("rit", "city")
        // .then((response: ISite) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test updating an existing site. It worked!
        // Test updating with existing customer ID and a non-existent site ID. Returned "Cannot update site, site ID rit#crapola doesn't exist" which is correct
        // Try with invalid customer ID. It returned "Invalid customer ID specified", which is correct
        // Try with invalid site ID (in body). It returned "Invalid site ID specified" which is correct
        // Try with invalid site name (in body). It returned "Invalid site ID or name specified in request body" which is correct
        // Try mismatch between site ID on url and site ID in body. Returned "Path paramater site ID doesn't match site ID in request body", which is correct.
        // let site = new Site("city", "City Campus", "",
        //   {shiftList:{timezone:"America/New_York",shifts:[{name:"2nd Shift",start:"08:30",end:"17:40"}]},targets:{pounds:11,pounds_per_hour:12,loads:13,loads_per_hour:14,turns:15,turns_per_hour:16}});
        // let site = new Site("testsite", "Test Site", "hello", {shiftList:{timezone:"America/New_York",shifts:[]}});
        // props.backend.updateSite("rit", site)
        // .then((response: ISite) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test deleting an existing customer/site. It worked
        // Test with non-existent customer ID. It returned OK, which is correct.
        // Test with non-existent site ID. It returned OK, which is correct.
        // Test with a bad customer ID. It returned "Invalid customer ID specified", which is correct.
        // Test with a bad site ID. It returned "Invalid site ID specified", which is correct.
        // props.backend.deleteSite("rit", "testsite")
        // .then((response) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
         *** TEST SYSTEM STUFF
         ********************************************************************/

        // Test with an existing customer/site that has systems (rit#main). Returned an array of systems, which is correct.
        // Test with an existing customer/site that has no systems. Returned an empty array, which is correct.
        // Test with a non-existent customer (e.g. crapola#main). Returned an empty array, which is correct.
        // Test with a non-existent site (e.g. rit#crapola). Returned an empty array, which is correct.
        // Test with a bad customer ID. Returned "Invalid customer ID specified", which is correct!
        // Test with a bad site ID. Returned "Invalid customer ID specified", which is correct!
        // props.backend.getSystemsForSite("jones", "city")
        // .then((response: SystemListResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test with invalid customer ID. Returned "Invalid customer ID specified", which is correct
        // Test with invalid site ID. Returned "Invalid site ID specified", which is correct
        // Test with invalid system id. Returned "Invalid system ID or name specified', which is correct
        // Test with invalid system name. Returned "Invalid system ID or name specified', which is correct
        // Test creating a system that already exists for this customer#site. It returned "System with ID cims already exists", which is correct.
        // Test creating a system for non-existent customer ID. Returned "Cannot create system, site ID crapola#city doesn't exist"", which is correct.
        // Test creating a system for non-existent site ID. Returned "Cannot create system, site ID rit#junky doesn't exist", which is correct
        // Test creating a system for an existing customer#site. It worked.
        // let site = new Site("1", "newsitename");            // invalid ID
        // let site = new Site("newsiteid", "x");              // invalid name
        // let site = new Site("newsiteid", "newsitename");    // valid site
        // let system = new System("purple", "System Purple");     // valid site
        // let system = new System("testsystem", "Test System");     // valid site
        // props.backend.createSystem("rit", "testsite", system)
        // .then((response: ISystem) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test getting an existing customer#site#system. It worked!
        // Test getting a non-existent customer. It returned "System ID crapola#main#cims doesn't exist", which is correct!
        // Test getting a non-existent site. It returned "System ID rit#crapola#cims doesn't exist", which is correct!
        // Test getting a non-existent system. It returned "System ID rit#city#crapola doesn't exist", which is correct!
        // Test getting with a bad customer ID. It returned "Invalid customer ID specified", which is correct!
        // Test getting with a bad site ID. It returned "Invalid site ID specified", which is correct!
        // Test getting with a bad system ID. It returned "Invalid system ID specified", which is correct!
        // props.backend.getSystem("rit", "city", "cims")
        // .then((response: ISystem) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test updating an existing site. It worked!
        // Test with existing customer ID and a non-existent site ID. Returned "Cannot update system, system ID rit#north#group-a doesn't exist" which is correct.
        // Test with existing customer ID, existing site Id, and a non-existent system ID. Returned "Cannot update system, system ID rit#main#group-a doesn't exist" which is correct.
        // Try with invalid customer ID. It returned "Invalid customer ID specified", which is correct
        // Try with invalid site ID. It returned "Invalid site ID specified", which is correct
        // Try with invalid system ID (in body). It returned "Invalid system ID specified" which is correct
        // Try with invalid system name (in body). It returned "Invalid system ID or name specified in request body" which is correct
        // Try mismatch between site ID on url and site ID in body. Returned "Path paramater system ID doesn't match system ID in request body", which is correct.
        // let system = new System("cims", "System Green", "what's up green",
        //   {targets: {pounds:31, pounds_per_hour:32, loads:33, loads_per_hour:34, turns:35, turns_per_hour:36}});
        // let system = new System("testsystem", "Test System", "hello");
        // props.backend.updateSystem("rit", "testsite", system)
        // .then((response: ISystem) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test deleting an existing customer/site/system. It worked
        // Test with non-existent customer ID. It returned OK, which is correct.
        // Test with non-existent site ID. It returned OK, which is correct.
        // Test with non-existent system ID. It returned OK, which is correct.
        // Test with a bad customer ID. It returned "Invalid customer ID specified", which is correct.
        // Test with a bad site ID. It returned "Invalid site ID specified", which is correct.
        // Test with a bad system ID. It returned "Invalid system ID specified", which is correct.
        // props.backend.deleteSystem("rit", "testsite", "testsystem")
        // .then((response) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
         *** TEST MACHINE STUFF
         ********************************************************************/

        // Test with an existing customer/site/system that has machines (rit#main#cims). Returned an array of machines, which is correct.
        // Test with an existing customer/site/system that has no machines (rit#city#cims). Returned an empty array, which is correct.
        // Test with a non-existent customer (e.g. crapola#main#cims). Returned an empty array, which is correct.
        // Test with a non-existent site (e.g. rit#crapola#cims). Returned an empty array, which is correct.
        // Test with a non-existent system (e.g. rit#main#crapola). Returned an empty array, which is correct.
        // Test with a bad customer ID. Returned "Invalid customer ID specified", which is correct!
        // Test with a bad site ID. Returned "Invalid site ID specified", which is correct!
        // Test with a bad system ID. Returned "Invalid system ID specified", which is correct!
        // props.backend.getMachinesForSystem("jones", "city", "system1")
        // .then((response: MachineListResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Test getting an existing customer#site#system#machine. It worked!
        // Test getting a non-existent customer. It returned "Machine ID crap#main#cims#washer22 doesn't exist", which is correct!
        // Test getting a non-existent site. It returned "Machine ID rit#crap#cims#washer22 doesn't exist", which is correct!
        // Test getting a non-existent system. It returned "Machine ID rit#main#crap#washer22 doesn't exist", which is correct!
        // Test getting a non-existent machine. It returned "Machine ID rit#main#cims#crap doesn't exist", which is correct!
        // Test getting with a bad customer ID. It returned "Invalid customer ID specified", which is correct!
        // Test getting with a bad site ID. It returned "Invalid site ID specified", which is correct!
        // Test getting with a bad system ID. It returned "Invalid system ID specified", which is correct!
        // Test getting with a bad machine ID. It returned "Invalid machine ID specified", which is correct!
        // props.backend.getMachine("rit", "testsite", "testsystem", "testmachine")
        // .then((response: IMachine) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // let machine = new Machine("tunnel2", "Tunnel 1", "TUNNEL", "Red Tunnel", "Model 15", true);
        // let machine = new Machine("tunnel1", "Tunnel 1", "TUNNEL", "Green Tunnel", "Model 67", true);
        // let machine = new Machine("washer1", "New Washer", "WASHER", "Blue Washer", "Model 1", true);
        // let machine = new Machine("dryer1", "Old Dryer", "DRYER", "Pink Dryer", "Model 5", true);
        // props.backend.createMachine("rit", "test", "system2", machine)
        // .then((response: IMachine) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // let machine = new Machine("tim1", "Tim Machine 1", "dryer", "this is an old dryer", "Model T", false);
        // let machine = new Machine("testmachine", "Test Machine", "dryer", "this is an old dryer", "Model T", false);
        // props.backend.updateMachine("rit", "testsite", "testsystem", machine)
        // .then((response: IMachine) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // props.backend.deleteMachine("rit", "testsite", "testsystem", "testmachine")
        // .then((response) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // props.backend.getMachineAlarms("rit", "testsite", "testsystem", "testmachine", 1694520000, 1694552400)
        // .then((response: MachineAlarmsResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
         *** TEST USER STUFF
         ********************************************************************/

        // let user = new User("", "rit", "Samuel", "Barnes", "sammy@rit.edu", "Sammy", "", "America/New_York", false, false, ["rit"], ["rit#Syracuse", "rit#Rochester"], []);
        // let user = new User("", "rit", "Johnny", "Hodges", "johnny@rit.edu", "Johnny", "", "America/New_York", false, false, [], ["rit"]);
        // let user = new User("", "rit", "John", "Doe", "testuser@rit.edu", "Johnny", "", "America/New_York", false, false, [], [], []);
        // user.temporaryPassword = "Password23";
        // props.backend.createUser("rit", user)
        // .then((response: IUser) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // This returns the ro, rw, and admin arrays (if they exist in DynamoDB), which is correct
        // Test getting an exising user.
        // Tim = "186e7749-24fc-4f9f-8143-125655966192"
        // User 1 = "7501b990-3596-4f71-8a39-eec3c5696060"
        // Debbie = "902a675c-98f2-4b40-b915-08345d0a57c5"
        // Chris = "94880fe3-b996-42aa-b1c2-099f9853b09d"
        // Sammy = "06735827-8328-482c-9aeb-3b1c1dd40a1d"
        // props.backend.getUser("smith", "19575f9e-8886-45c0-96a0-72663401a0af")
        // .then((response: IUser) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // let user = new User("186e7749-24fc-4f9f-8143-125655966192", "rit", "Timothy", "Murtaugh", "temasp@rit.edu", "Big Bad Tim", "+15854757558", "America/New_York", false, false, ["rit#city#cims"], [], []);
        // let user = new User("6dbec1e6-e5e2-4a68-8f66-ef7fd8d142f9", "rit", "John", "Doe", "testuser@rit.edu", "Big Bad John", "", "America/New_York", false, false, [], [], []);
        // let user = new User("06735827-8328-482c-9aeb-3b1c1dd40a1d", "rit", "Samuel", "Barnes", "sammy@rit.edu", "Sammy", "", "America/New_York", false, false, [], [], []);
        // let user = new User("19575f9e-8886-45c0-96a0-72663401a0af", "smith", "Roger", "Smith", "smith1@rit.edu", "ttt", "", "America/New_York", false, false, [], [], []);
        // props.backend.updateUser("rit", user)
        // .then((response: IUser) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // This does not return ro, rw, and admin arrays, which is correct.
        // props.backend.getUsersForCustomer("rit")
        // .then((response: UserListResponse) => {
        //     console.log("It worked!");
        //     console.log(response);F
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Johnny Hodges = d71b65b5-1f3a-4eda-a95b-c9b8b6062b4f
        // props.backend.deleteUser("rit", "d71b65b5-1f3a-4eda-a95b-c9b8b6062b4f")
        // .then((response: string) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // Tim = "186e7749-24fc-4f9f-8143-125655966192"
        // User 1 = "7501b990-3596-4f71-8a39-eec3c5696060"
        // Debbie = "902a675c-98f2-4b40-b915-08345d0a57c5"
        // Sammy = "06735827-8328-482c-9aeb-3b1c1dd40a1d"
        // Try it with a bad customer ID
        // Try it with a bad user ID
        // props.backend.getUserGrants("rit", "186e7749-24fc-4f9f-8143-125655966192")
        // .then((response: UserPermissions) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // props.backend.addUserGrants("rit", "06735827-8328-482c-9aeb-3b1c1dd40a1d", {admin: [], ro: [], rw: ["rit#city"]})
        // .then((response) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // props.backend.deleteUserGrants("rit", "06735827-8328-482c-9aeb-3b1c1dd40a1d", {admin: [], ro: ["rit#main"], rw: ["rit#city"]})
        // .then((response) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
         *** TEST TREE STUFF
         ********************************************************************/

        // Test getting the tree for an existing customer.
        // Test getting the tree for a non-existent customer.
        // props.backend.getTree("rit")
        // .then((response: CustomerNode) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
            TEST GETTING STATUS DATA FOR CUSTOMER, SITE, SYSTEM AND MACHINE.
            TODO: RETEST THE FOLLOWING WHEN THEY RETURN REAL, NOT STATIC, DATA.
         ********************************************************************/

        // TODO: THIS NEEDS TO BE TESTED WHEN getCustomerStatus IN AWS RETURNS REAL, NOT STATIC, DATA.
        // Test getting an existing customer.
        // Test getting a non-existent customer.
        // Test getting with a bad ID.
        // props.backend.getCustomerStatus("jones", 1694520000, 1694552400)
        // .then((response: CustomerStatusResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // TODO: THIS NEEDS TO BE TESTED WHEN getSiteStatus IN AWS RETURNS REAL, NOT STATIC, DATA.
        // Test getting an existing customer.
        // Test getting a non-existent customer.
        // Test getting with a bad customer ID.
        // Test getting with a bad site ID.
        // props.backend.getSiteStatus("rit", "city", 1699448400, 1699480800)
        // .then((response: SiteStatusResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // TODO: THIS NEEDS TO BE TESTED WHEN getSystemStatus IN AWS RETURNS REAL, NOT STATIC, DATA.
        // Test getting an existing customer.
        // Test getting a non-existent customer.
        // Test getting with a bad ID.
        // props.backend.getSystemStatus("jones", "city", "system1", 1694520000, 1694552400)
        // .then((response: SystemStatusResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        // TODO: THIS NEEDS TO BE TESTED WHEN getMachineStatus IN AWS RETURNS REAL, NOT STATIC, DATA.
        // Test getting an existing customer.
        // Test getting a non-existent customer.
        // Test getting with a bad ID.
        // props.backend.getMachineStatus("jones", "city", "system1", "shuttle2")
        // .then((response: MachineStatusResponse) => {
        //     console.log("It worked!");
        //     console.log(response);
        // })
        // .catch((error) => {
        //     console.log("It failed");
        //     console.log(error);
        // })

        /********************************************************************
         *** TEST SHIFT TIME STUFF
         ********************************************************************/

        // // BEGIN TEST
        // var shiftList:ShiftList = {
        //     timezone: "America/Los_Angeles", 
        //     shifts: [
        //         {id:0, name:"1st Shift", start:"08:00", end:"16:00", targets:{"pounds":6,"pounds_per_hour":6,"loads":6,"loads_per_hour":6,"turns":6,"turns_per_hour":6}},
        //         {id:1, name:"2nd Shift", start:"16:00", end:"00:00", targets:{"pounds":7,"pounds_per_hour":7,"loads":7,"loads_per_hour":7,"turns":6,"turns_per_hour":7}},
        //         {id:2, name:"3rd Shift", start:"00:00", end:"08:00", targets:{"pounds":8,"pounds_per_hour":8,"loads":8,"loads_per_hour":8,"turns":8,"turns_per_hour":8}}
        //     ]
        // }        
        // var now:Date = new Date();

        // var s = getCurrentShift(now, shiftList);
        // if (s !== undefined) {
        //     console.log("Current shift name is " + s.name);
        //     console.log("Current shift times as UNIX times");
        //     console.log("now " + s.now);
        //     console.log("shift start time " + s.start);
        //     console.log("shift end time " + s.end);

        //     console.log(`Current shift times formatted in time zone ${shiftList.timezone.toString()}`);
        //     console.log("now " + unixTimeToString(s.now, true, shiftList.timezone.toString()));
        //     console.log("shift start time " + unixTimeToString(s.start, true, shiftList.timezone.toString()));
        //     console.log("shift end time " + unixTimeToString(s.end, true, shiftList.timezone.toString()));
        // }

        // // This test was run on Mar 19, 2024 at 12:22:02 PDT (which is 3:22:02 in EDT).
        // // Here is the output of this test:

        // // Current shift name is 1st Shift
        // // Current shift times as UNIX times
        // // now 1710876122
        // // shift start time 1710860400
        // // shift end time 1710889200
        // // Current shift times formatted in time zone America/Los_Angeles
        // // now 12:22:02 03/19/2024 PDT
        // // shift start time 08:00:00 03/19/2024 PDT
        // // shift end time 16:00:00 03/19/2024 PDT

        // // All of this output is correct.
        // // END TEST
    }

    return (
        <div><Button variant="primary" onClick={() => handleClick()}>Test</Button></div>
    );
}
