import Auth, {CognitoUser} from "@aws-amplify/auth";
import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import React, {useEffect, useState} from "react";

export interface CurrentUserProps {
    cognitoUser?: CognitoUser
}

/*
 * Helper function to find an attribute.  If the attribute is not found, returns undefined.
 * If the attribute is found but is an empty string, this also returns undefined.
 * This is most useful to find the first property that is not null or empty (more generally
 * you would probably return an empty string as-is).
 */
function findProperty(attributes: CognitoUserAttribute[], field: string): string | undefined {
    const v = attributes.find((attr: CognitoUserAttribute) => attr.Name === field)?.Value
    if (v === "") {
        return undefined;
    }
    return v;
}

export const CurrentUser: React.FC<CurrentUserProps> = (props: CurrentUserProps) => {
    const target = React.useRef(null)

    /*
     * The current user is rendered as a span so that it can be styled
     * by the parent.  It is placed in an OverlayTrigger so that a tooltip
     * will give you the full name.
     */

    useEffect(() => {
        if (props.cognitoUser === undefined) {
            setDisplayName("Not Logged In");
        }
        else {
            Auth.userAttributes(props.cognitoUser)
                .then((attributes: CognitoUserAttribute[]) => {
                    const display = findProperty(attributes, "nickname")
                        || findProperty(attributes, "given_name")
                        || findProperty(attributes, "email")
                        || "Logged In User";
                    setDisplayName(`Hello ${display}!`)
                })
        }
    }, [props.cognitoUser]);

    const [displayName, setDisplayName] = useState<string>("")

    return <>
        <span ref={target}>{displayName}</span>
    </>;
}
