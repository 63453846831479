import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BarChart} from '../components/BarChart';
import {ProductionTotals, IProductionTargets} from "../models/types";

export interface MultiBarChartProps {
    totals?: ProductionTotals,
    targets?: IProductionTargets
}

export const MultiBarChart: React.FC<MultiBarChartProps> = (props: MultiBarChartProps) => {

    // Gets the max of two values.
    // This is for the range on the x axis of the bar charts.
    function calcMax(value1?:number, value2?:number) : number {
        if (value1 !== undefined && value2 !== undefined)
            return Math.round(Math.max(value1, value2));
        else if (value1 !== undefined)
            return Math.round(value1);
        else if (value2 !== undefined)
            return Math.round(value2);
        else
            return 100;
    }

    return (
        <Container>
            <Row>
                <Col className="col-sm">
                    <h5 className="braun-text">Production Totals</h5>
                    <BarChart title={"Total Pounds"}
                        xmin={0}
                        xmax={calcMax(props.totals?.pounds, props.targets?.pounds)}
                        actualValue={props.totals?.pounds}
                        targetValue={props.targets?.pounds} />
                </Col>
                <Col className="col-sm">
                    <h5 className="braun-text">Production Rates</h5>
                    <BarChart title={"Pounds per Hour"}
                        xmin={0}
                        xmax={calcMax(props.totals?.pounds_per_hour, props.targets?.pounds_per_hour)}
                        actualValue={props.totals?.pounds_per_hour}
                        targetValue={props.targets?.pounds_per_hour} />
                </Col>
            </Row>
            <Row>
                <Col className="col-sm">
                    <BarChart title={"Total Loads"}
                        xmin={0}
                        xmax={calcMax(props.totals?.loads, props.targets?.loads)}
                        actualValue={props.totals?.loads}
                        targetValue={props.targets?.loads} />
                </Col>
                <Col className="col-sm">
                    <BarChart title={"Loads per Hour"}
                        xmin={0}
                        xmax={calcMax(props.totals?.loads_per_hour, props.targets?.loads_per_hour)}
                        actualValue={props.totals?.loads_per_hour}
                        targetValue={props.targets?.loads_per_hour} />
                </Col>
            </Row>
            <Row>
                <Col className="col-sm">
                    <BarChart title={"Total Turns"}
                        xmin={0}
                        xmax={calcMax(props.totals?.turns, props.targets?.turns)}
                        actualValue={props.totals?.turns}
                        targetValue={props.targets?.turns} />
                </Col>
                <Col className="col-sm">
                    <BarChart title={"Turns per Hour"}
                        xmin={0}
                        xmax={calcMax(props.totals?.turns_per_hour, props.targets?.turns_per_hour)}
                        actualValue={props.totals?.turns_per_hour}
                        targetValue={props.targets?.turns_per_hour} />
                </Col>
            </Row>
        </Container>
    );
}
