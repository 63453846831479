import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {IUser} from "../models/user";

export interface AdminHomeProps {
    currentUser: IUser | undefined
}

export const AdminHome : React.FC<React.PropsWithoutRef<AdminHomeProps>> = (props) => {
    useEffect(() => {
        console.log(props.currentUser);
    }, [props.currentUser]);

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>Administration Home</Breadcrumb.Item>
            </Breadcrumb>

            <h4>Administration Home</h4>

            <Container fluid>
                <Row>
                    <Link to={"/admin/customers"}>Manage Companies</Link>
                </Row>
            </Container>
        </div> 
    );
}