import Table from 'react-bootstrap/Table';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {WashnetBackend} from "../services/WashnetBackend";
import {ReportHeader} from '../components/ReportHeader';
import {FormulaDetailRequest, FormulaDetailResponse, FormulaDetailTotals} from "../models/reports"
import {secToHMS, unixTimeToTimeString, unixTimeToDateString} from "../util";
import {ModalShowMessage} from "../admin/ModalShowMessage"
import {ReportParams} from "../models/reports";
import {Data} from "../components/Data"

export interface FormulaDetailProps {
    backend: WashnetBackend
}

export const ReportFormulaDetail: React.FC<FormulaDetailProps> = (props: FormulaDetailProps) => {

    const {customerId, siteId, systemId, machineId} = useParams();
    const [formulaDetail, setFormulaDetail] = useState<FormulaDetailResponse>();
    const [totals, setTotals] = useState<FormulaDetailTotals>();

    const state:ReportParams = useLocation().state;

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined || siteId === undefined || systemId === undefined || machineId === undefined) {
            console.log("customerId, siteId, systemId or machineId is undefined");
            return;
        }

        // Outgoing to backend.
        let params: FormulaDetailRequest = {
            customerId: customerId,
            siteId: siteId,
            systemId: systemId,
            machineId: machineId,
            startDate: state.startDate,
            endDate: state.endDate
        }

        props.backend.getFormulaDetailReport(params)
        .then((response: FormulaDetailResponse) => {
            setFormulaDetail(response);

            let t:FormulaDetailTotals = {
                weight: 0,
                timeInMachine: 0,
                timeProcessing: 0,
                timeWaitingToUnload: 0,
                timeInAlarm: 0,
                timeInManual: 0,
                timeOffline: 0
            }
        
            // Sum each column in the rows of the response set.
            let r:FormulaDetailResponse = response as FormulaDetailResponse;
            r.response.forEach((element) => {
                t.weight += element.weight;
                t.timeInMachine += element.timeInMachine;
                t.timeProcessing += element.timeProcessing;
                t.timeWaitingToUnload += element.timeWaitingToUnload;
                t.timeInAlarm += element.timeInAlarm;
                t.timeInManual += element.timeInManual;
                t.timeOffline += element.timeOffline;
            })

            setTotals(t);
        })
        .catch((error) => {
            setAlertMessage(error.message);
            setShowAlert(true);
        })

    }, [customerId, siteId, systemId, machineId]);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleCloseMessage = () => {
        setShowAlert(false);
    }

    return (
        <div>
            <ReportHeader title="Formula Detail" machineName={state.machineName} startDate={state.startDate} endDate={state.endDate} timezone={state.timezone} />

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Formula</th>
                    <th>Weight</th>
                    <th>Entry Time/Date</th>
                    <th>Exit Time/Date</th>
                    <th>Time In Machine</th>
                    <th>Time Processing</th>
                    <th>Time Wait To Unload</th>
                    <th>Time In Alarm</th>
                    <th>Time In Manual</th>
                    <th>Time Offline</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    formulaDetail !== undefined &&
                    <>
                        {
                        formulaDetail.response.map((item, j) =>
                            <tr key={j.toString()}>
                            <td><Data value={item.formula}/></td>
                            <td><Data value={item.weight}/></td>
                            <td>{unixTimeToTimeString(item.enterDateTime, true, state.timezone.toString())}<br/>{unixTimeToDateString(item.enterDateTime, false)}</td>
                            <td>{unixTimeToTimeString(item.exitDateTime, true, state.timezone.toString())}<br/>{unixTimeToDateString(item.exitDateTime, false)}</td>
                            <td>{secToHMS(item.timeInMachine)}</td>
                            <td>{secToHMS(item.timeProcessing)}</td>
                            <td>{secToHMS(item.timeWaitingToUnload)}</td>
                            <td>{secToHMS(item.timeInAlarm)}</td>
                            <td>{secToHMS(item.timeInManual)}</td>
                            <td>{secToHMS(item.timeOffline)}</td>
                            </tr>
                        )
                        }
                    </>
                    }
                    {
                    totals !== undefined &&
                    <>
                        <tr>
                        <td>Totals:</td>
                        <td>{totals.weight}</td>
                        <td></td>
                        <td></td>
                        <td>{secToHMS(totals.timeInMachine)}</td>
                        <td>{secToHMS(totals.timeProcessing)}</td>
                        <td>{secToHMS(totals.timeWaitingToUnload)}</td>
                        <td>{secToHMS(totals.timeInAlarm)}</td>
                        <td>{secToHMS(totals.timeInManual)}</td>
                        <td>{secToHMS(totals.timeOffline)}</td>
                        </tr>
                    </>
                    }
                </tbody>
            </Table>
            <hr/>

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseMessage}
            />

        </div>
    );
}
