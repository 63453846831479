import {Container, Row, Col} from "react-bootstrap";
import {BarChart2} from '../components/BarChart2';
import {ISiteMetrics, DisplayType} from "../models/types";

interface MultiBarChart2Props {
    list: ISiteMetrics[],
    subTitle: string,
    displayType: DisplayType,
    barTitles: string[]
}

// This generates up to 3 bar charts, and fills the empty space if less than 3.
export const MultiBarChart2: React.FC<MultiBarChart2Props> = (props: MultiBarChart2Props) => {
    return (
        <>
        <Container className="braun-text mb-4" fluid>
            <Row>
            {/* Generate up to 3 bar charts (depends on length of props.list). */}
            {
                // Display the Totals graphs
                (props.displayType === DisplayType.TOTALS) &&
                props.list.map((s: ISiteMetrics, i: number) =>
                    <Col className="col-sm" key={i}>
                        <BarChart2 title={s.name} subTitle={props.subTitle} barTitles={props.barTitles}
                         barValues={[s.total_pounds_percent, s.total_loads_percent, s.total_turns_percent]}
                         actuals={[s.pounds, s.loads, s.turns]}
                         targets={[s.targets.pounds, s.targets.loads, s.targets.turns]}/>
                    </Col>
                )
            }
            {
                // Display the Rates graphs
                (props.displayType === DisplayType.RATES) &&
                props.list.map((s: ISiteMetrics, i: number) =>
                    <Col className="col-sm" key={i}>
                        <BarChart2 title={s.name} subTitle={props.subTitle} barTitles={props.barTitles}
                         barValues={[s.rate_pounds_percent, s.rate_loads_percent, s.rate_turns_percent]}
                         actuals={[s.pounds_per_hour, s.loads_per_hour, s.turns_per_hour]}
                         targets={[s.targets.pounds_per_hour, s.targets.loads_per_hour, s.targets.turns_per_hour]}/>
                    </Col>
                )
            }

            {/* If less than 3 were generated above, generate enough columns to fill in the space. */}
            {
                (props.list.length < 3) &&
                <>
                <Col className="col-sm">
                </Col>
                </>
            }
            {
                (props.list.length < 2) &&
                <>
                <Col className="col-sm">
                </Col>
                </>
            }
            {
                (props.list.length < 1) &&
                <>
                <Col className="col-sm">
                </Col>
                </>
            }
            </Row>
        </Container>
        </>
    );
}
