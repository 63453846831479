import Auth, {CognitoUser} from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import {AmplifyAuthAlert, AmplifyAuthError} from "./components/AmplifyAuthAlert";
import classNames from "classnames";
import React, {useEffect, useState, createContext} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Customers} from "./admin/Customers";
import {AdminHome} from "./admin/AdminHome";
import {CustomerDetail} from "./admin/CustomerDetail";
import {SiteDetail} from "./admin/SiteDetail";
import {SystemDetail} from "./admin/SystemDetail";
import {DebugHome} from "./debug/debug_home";
import {DebugJwt} from "./debug/debug_jwt";
import {AppNavbar} from './components/AppNavbar';
import {AppSidebar} from './components/AppSidebar';
import {ChangePassword} from "./components/ChangePassword";
import {Login} from "./components/Login";
import {PrivateRoutes} from "./components/LoginRequired";
import {NewPassword} from "./components/NewPassword";
import {PasswordRecovery} from "./components/PasswordRecovery";
import {CustomerNode, CustomerListResponse, ICustomer} from "./models/customer";
import {IUser} from "./models/user";
import {WashnetContext} from "./services/WashnetContext";
import {useAwsBackend} from "./services/WashnetAwsBackend";
import {useSimulatedBackend} from "./services/WashnetSimulatedBackend";
import {RootView} from "./views/RootView";
import {MachineView} from "./views/MachineView";
import {ReportView} from "./views/ReportView";
import {SiteView} from "./views/SiteView";
import {SystemView} from "./views/SystemView";
import {SupportView} from "./views/SupportView";
import {CustomerView} from "./views/CustomerView";
import {TestView} from "./views/TestView";
import {ReportMachineUtilization} from "./views/ReportMachineUtilization";
import {ReportAlarmDetail} from "./views/ReportAlarmDetail";
import {ReportAlarmSummary} from "./views/ReportAlarmSummary";
import {ReportItemDetail} from "./views/ReportItemDetail";
import {ReportItemSummary} from "./views/ReportItemSummary";
import {ReportFormulaDetail} from "./views/ReportFormulaDetail";
import {ReportFormulaSummary} from "./views/ReportFormulaSummary";
import {isBraunAdmin, getCustomerId} from "./util";
import {ModalShowMessage} from "./admin/ModalShowMessage"

import './App.scss';

// We currently support light and dark themes only, but with
// a few changes we could support other colored themes as well.
export const ThemeContext = createContext({
    theme: "",
    changeTheme: (newTheme:string) => {}
})

export interface AppProps {
}

export const App: React.FC = (props: React.PropsWithoutRef<AppProps>) => {
    // This is the state when the app is started and no one was previously logged in:
    //   There will be no logged in user (and AppNavbar will say "Not Logged In")
    //   The tree will be empty.
    //   The Home page will be showing.
    //   The Select Customer dropdown on the AppNavbar will be hidden.
    // If someone was previously logged in:
    //   His credentials will be fetched from local storage and he will be the current user.
    //   His company tree will be shown.
    //   The Select Customer dropdown on the AppNavbar will be shown if he's a super user, hidden otherwise.
    // See useEffect below.

    const navigate = useNavigate();

    const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined); // The logged in user as CognitoUser
    const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);       // The logged in user as IUser

    // The super user can see a list of all customers and can select a customer to view.
    const [customers, setCustomers] = useState<ICustomer[]>([]);                        // List of customers (all customers for super user, one customer for other users)
    const [tree, setTree] = useState<CustomerNode | undefined>(undefined);              // The tree that is shown in the sidebar.
    const [customerId, setCustomerId] = useState<string>("");                           // The current customer

    const [theme, setTheme] = useState("dark");

    const [authError, setAuthError] = useState<AmplifyAuthError>()

    // Use location to determine whether to show or hide the AppSidebar.
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(true);

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    ///////////////////////////////////

    // Called from login and password dialogs and AppNavbar to set Cognito user.
    function cognitoUserChanged(newUser: CognitoUser | undefined) {
        // newUser will be either a CognitoUser (a valid logged in user) 
        // or undefined (to indicate that no user is logged in).
        console.log("Cognito user has changed");
        setCognitoUser(newUser);
    }

    // Callback function to update the tree.
    // It's used by AppNavbar when a customer is selected from the Select Customer dropdown.
    const changeCustomer = (customerId:string) => {
        setCustomerId(customerId);

        backend.clearTreeCache();

        backend.getTree(customerId)
        .then((tree: CustomerNode) => {
            // Show the tree for the newly selected customer.
            setTree(tree);
            // Go to the home page, otherwise we will stay 
            // on whatever page was showing at the time
            // the new customer was selected.
            navigate("/");
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    };

    // Callback function to set the theme.
    const changeTheme = (newTheme:string) => {
        setTheme(newTheme);
    };

    // Callback function to show/hide the sidebar.
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    console.log("App is refreshing");

    // Use either the simulated backend or the real AWS backend.
    // The simulated backed is for testing.
    // Normally the AWS backend is used.
    // const backend = useSimulatedBackend();
    const backend = useAwsBackend();

    // This useEffect is called only when the app initializes, I think.
    useEffect(() => {
        console.log("Running useEffect1 in App");
        Amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: 'us-east-1',
                userPoolId: 'us-east-1_wv2NNJvh0',                      // User pool ID for Braun production environment
                userPoolWebClientId: '7iegt8ot0nvo25nn9crfc9s6po',      // Client ID for Braun production environment
                // userPoolId: 'us-east-1_fVQHR5a11',                      // User pool ID for RIT development environment
                // userPoolWebClientId: '1j3ot2qfhhbe8235gis55p9tqn',      // Client ID for RIT development environment
                storage: localStorage
            }
        });

        // This call will fetch the current user session from local storage if it exists.
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            // User successfully fetched from local storage.
            setCognitoUser(user);
        })
        .catch((error) => {
            console.log("App useEffect1 error", error);
            if (typeof error === 'string') {
                // "The user is not authenticated" is normal when
                // no user is logged in, so don't display it.
                // Otherwise display the error.
                if (error !== "The user is not authenticated")
                    displayAlert(error);
            }
            else {
                setAuthError(error);
            }
        })
    }, [])

    // This useEffect is called when cognitoUser changes.
    useEffect(() => {
        console.log("Running useEffect2 in App");

        // When Cognito user is undefined, no one is logged in.
        // Set current user = undefined
        // Empty the list of customers (no customer dropdown will be shown on the AppNavbar)
        // Empty the tree (no tree will be shown on the AppSidebar)
        if (cognitoUser === undefined) {
            setCurrentUser(undefined);
            setCustomers([]);
            setTree(undefined);
            return;
        }

        // If user is defined but his password must be changed just return.
        // The user has logged in for the first time and he must change his password.
        // We don't want to execute the code in the rest of this function until he
        // changes his password and logs in again.
        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED")
            return;

        // When Cognito user is defined, someone is logged in.
        // Set current user
        // Set the tree
        // If the user is a Braun admin
        //   show the customer list in a dropdown on the navbar (handled by AppNavbar when state variable 'cognitoUser' changes)
        //   show the tree for the customer selected in the dropdown (handled by AppSidebar when state variable 'tree' changes)
        // If the user is not a Braun admin
        //   show the tree for the customer associated with the user (handled by AppSidebar when state variable 'tree' changes)
        getCustomerId(cognitoUser)
        .then((customerId:string) => {
            setCustomerId(customerId);
            // return customerId (string) and cognitoUser (CognitoUser) to the next block
            return Promise.all([customerId, cognitoUser]);
        })
        .then(([customerId, user]) => {
            // return customerId (string), user (CognitoUser), and isBraunAdmin (boolean) to the next block
            return Promise.all([customerId, user, isBraunAdmin(user)]);
        })
        .then(([customerId, user, braunAdmin]) => {
            if (braunAdmin) {
                // User is a Braun admin, get list of all customers.
                return backend.getAllCustomers().then((list:CustomerListResponse) => {
                    setCustomers(list.customers);
                })
                .then( () => {
                    // return customerId (string) and user (CognitoUser) to the next block
                    return Promise.all([customerId, user]);
                })
            }
            else {
                // User is not a Braun admin, so get the one and only customer associated with this user.
                return backend.getCustomer(customerId).then((customer:ICustomer) => {
                    setCustomers([customer]);
                })
                .then( () => {
                    // return customerId (string) and user (CognitoUser) to the next block
                    return Promise.all([customerId, user]);
                })
            }
        })
        .then(([customerId, user]) => {
            // call getUser(customerId, user.getUsername())
            // return customerId (string) and user (IUser) to the next block
            return Promise.all([customerId, backend.getUser(customerId, user.getUsername())]);
        })
        .then(([customerId, user]) => {
            // call setCurrentUser(user);
            // return customerId (string) to the next block
            setCurrentUser(user);
            return customerId;
        })
        .then((customerId) => {
            backend.clearTreeCache();
            // return getTree(customerId) to the next block
            return backend.getTree(customerId);
        })
        .then((tree: CustomerNode) => {
            // call setTree(tree)
            setTree(tree);
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }, [cognitoUser]);

    // This useEffect is called when the current URL changes.
    useEffect(() => {
        console.log("Running useEffect3 in App");
        if (location.pathname.startsWith("/admin") || location.pathname.startsWith("/debug"))
        {
            // Hide sidebar for these locations.
            setShowSidebar(false);
        }
        else if (location.pathname === "/")
        {
            // Show the sidebar.
            setShowSidebar(true);
        }
        else
        {
            // For everything else, the sidebar might be visible or hidden.
            // Don't change the view state of the sidebar.
        }
    }, [location]);

    return (
        <>
        <ThemeContext.Provider value={{ theme, changeTheme }}>
            <WashnetContext.Provider value={backend}>
                <div className={classNames("main-wrapper", "main-wrapper-responsive-lg")}>
                    <AppNavbar user={cognitoUser}
                        customerId={customerId}
                        customerList={customers}
                        cognitoUserChanged={cognitoUserChanged}
                        onCustomerChanged={(customerId) => changeCustomer(customerId)}
                        toggleSidebar={() => toggleSidebar()} />

                    {/* The sidebar will be shown or hidden based on the boolean property showSidebar. */}
                    <AppSidebar showSidebar={showSidebar}
                                user={cognitoUser}
                                customerTree={tree}/>

                    <main className={classNames("main-container", "container-fluid")}>
                        <Routes>
                            <Route element={<PrivateRoutes cognitoUser={cognitoUser} cognitoUserChanged={cognitoUserChanged}/>}>
                                { /* These routes require a user to be logged in. */}
                                <Route path="/Customer/:customerId/:customerName" element={<CustomerView backend={backend}/>}/>
                                <Route path="/Site/:customerId/:customerName/:siteId/:siteName" element={<SiteView backend={backend}/>}/>
                                <Route path="/System/:customerId/:customerName/:siteId/:siteName/:systemId/:systemName" element={<SystemView backend={backend}/>}/>
                                <Route path="/Machine/:customerId/:customerName/:siteId/:siteName/:systemId/:systemName/:machineId/:machineName" element={<MachineView backend={backend}/>}/>
                                <Route path="/Report/:customerId/:customerName/:siteId/:siteName/:systemId/:systemName" element={<ReportView backend={backend}/>}/>
                                <Route path="/ReportMachineUtilization/:customerId/:siteId/:systemId/:machineId" element={<ReportMachineUtilization backend={backend}/>}/>
                                <Route path="/ReportAlarmDetail/:customerId/:siteId/:systemId/:machineId" element={<ReportAlarmDetail backend={backend}/>}/>
                                <Route path="/ReportAlarmSummary/:customerId/:siteId/:systemId/:machineId" element={<ReportAlarmSummary backend={backend}/>}/>
                                <Route path="/ReportItemDetail/:customerId/:siteId/:systemId/:machineId" element={<ReportItemDetail backend={backend}/>}/>
                                <Route path="/ReportItemSummary/:customerId/:siteId/:systemId/:machineId" element={<ReportItemSummary backend={backend}/>}/>
                                <Route path="/ReportFormulaDetail/:customerId/:siteId/:systemId/:machineId" element={<ReportFormulaDetail backend={backend}/>}/>
                                <Route path="/ReportFormulaSummary/:customerId/:siteId/:systemId/:machineId" element={<ReportFormulaSummary backend={backend}/>}/>
                                <Route path="/admin" element={<AdminHome currentUser={currentUser}/>}/>
                                <Route path="/admin/customers" element={<Customers backend={backend} currentUser={currentUser}/>}/>
                                <Route path="/admin/customers/:customerId/:customerName" element={<CustomerDetail backend={backend} currentUser={currentUser}/>}/>
                                <Route path="/admin/sites/:customerId/:customerName/:siteId/:siteName" element={<SiteDetail backend={backend} currentUser={currentUser}/>}/>
                                <Route path="/admin/systems/:customerId/:customerName/:siteId/:siteName/:systemId/:systemName" element={<SystemDetail backend={backend} currentUser={currentUser}/>}/>
                                <Route path="/debug/jwt" element={<DebugJwt cognitoUser={cognitoUser}/>}/>
                                <Route path="/debug" element={<DebugHome/>}/>
                            </Route>

                            <Route>
                                { /* These routes work even if a user is not logged in. */}
                                <Route path="/Support" element={<SupportView/>}/>
                                <Route path="/change-password" element={<ChangePassword cognitoUser={cognitoUser} cognitoUserChanged={cognitoUserChanged}/>}/>
                                <Route path="/new-password" element={<NewPassword cognitoUser={cognitoUser} cognitoUserChanged={cognitoUserChanged}/>}/>
                                <Route path="/password-recovery" element={<PasswordRecovery cognitoUser={cognitoUser} cognitoUserChanged={cognitoUserChanged}/>}/>
                                <Route path="/login" element={<Login cognitoUser={cognitoUser} cognitoUserChanged={cognitoUserChanged}/>}/>
                                <Route path="/test" element={<TestView backend={backend}/>}/>
                                <Route path="/" element={<RootView/>}/>
                            </Route>

                            {/* Default route */}
                            <Route path="*" element={<Navigate to="/" replace={true}/>}/>

                        </Routes>
                    </main>
                </div>
            </WashnetContext.Provider>
        </ThemeContext.Provider>

        {/* Modal to show auth message. */}
        <AmplifyAuthAlert error={authError}/>

        {/* Modal to show message. */}
        <ModalShowMessage show={showAlert}
            message={alertMessage}
            variant="danger"
            handleClose={handleCloseAlert}
        />

        </>
    );
}
