import React from "react";
import classNames from "classnames";
import {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import TimezoneSelect, {ITimezone} from "react-timezone-select";
import {IUser} from "../models/user";
import {PasswordRequirements} from "../components/PasswordRequirements";
import {emailExpression, phoneExpression, passwordExpression, userNameExpression, DialogType} from "../util"

export interface ModalEditUserProps {
    show: boolean,
    title: string,
    type: DialogType,
    handleSave: (u:IUser) => void,
    handleClose: () => void,
    user: IUser
}

export const ModalEditUser: React.FC<ModalEditUserProps> = (props: ModalEditUserProps) => {
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const [first, setFirst] = useState("");
    const [firstValid, setFirstValid] = useState(true);
    const [last, setLast] = useState("");
    const [lastValid, setLastValid] = useState(true);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [nickname, setNickname] = useState("");
    const [nicknameValid, setNicknameValid] = useState(true);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [pw, setPw] = useState("");
    const [pwValid, setPwValid] = useState(true);
    const [confirmPw, setConfirmPw] = useState("");
    const [confirmPwValid, setConfirmPwValid] = useState(true);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    function displayToast(message:string) {
        setToastMessage(message);
        setShowToast(true);
    }

    function clearToast() {
        setToastMessage("");
        setShowToast(false);
    }

    function clearFields() {
        clearToast();

        // Clear form fields
        setFirst("");
        setFirstValid(true);
        setLast("");
        setLastValid(true);
        setEmail("");
        setEmailValid(true);
        setNickname("");
        setNicknameValid(true);
        setPhone("");
        setPhoneValid(true);
        setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        setPw("");
        setPwValid(true);
        setConfirmPw("");
        setConfirmPwValid(true);
    }

    // Takes an international format phone number, removes the +1 and puts into xxx-xxx-xxxx format.
    function convertPhoneNumber(phone:string):string {
        let result = phone;
        if (phone.length === 12) {
            result = `${phone.slice(2,5)}-${phone.slice(5,8)}-${phone.slice(8,12)}`;
        }
        return result;
    }

    useEffect(() => {
        // Copy values sent to this component from the parent to the form fields.
        
        if (props.user.first !== undefined)
            setFirst(props.user.first);
        if (props.user.last !== undefined)
            setLast(props.user.last);
        if (props.user.email !== undefined)
            setEmail(props.user.email);
        if (props.user.nickname !== undefined)
            setNickname(props.user.nickname);
        if (props.user.phone !== undefined)
            setPhone( convertPhoneNumber(props.user.phone) );
        if (props.user.timezone !== undefined) {
            if (typeof props.user.timezone === "string")
                setSelectedTimezone(props.user.timezone);
            else
                setSelectedTimezone(props.user.timezone.value);
        }
        else {
            setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        }

        clearToast();
    }, [props.user]);

    const updateFirst = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirst(event.target.value)
        setFirstValid(userNameExpression.test(event.target.value))
    }

    const updateLast = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLast(event.target.value)
        setLastValid(userNameExpression.test(event.target.value))
    }

    const updateEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value)
        setEmailValid(event.target.value.length === 0 || emailExpression.test(event.target.value))
    }

    const updateNickname = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNickname(event.target.value)
        setNicknameValid(userNameExpression.test(event.target.value))
    }

    const updatePhone = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhone(event.target.value)
        setPhoneValid(event.target.value.length === 0 || phoneExpression.test(event.target.value))
    }

    const updatePw = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPw(event.target.value)
        setPwValid(event.target.value.length === 0 || passwordExpression.test(event.target.value))
    }

    const updateConfirmPw = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPw(event.target.value)
        setConfirmPwValid(event.target.value.length === 0 || passwordExpression.test(event.target.value))
    }

    const handleSave = () => {
        // Return new user to the parent.

        // We need to run regex tests here because emailValid and pwValid are set only if the user edits the fields.
        if (pw !== confirmPw) {
            displayToast("Passwords don't match");
        }
        else if (props.type === DialogType.ADD && pw.length === 0) {
            // Add User requires a password, it can be blank for Edit User.
            displayToast("A password is required when adding a user");
        }
        else if (pw !== "" && passwordExpression.test(pw) === false) {
            displayToast("Password is invalid");
        }
        else if (emailExpression.test(email) === false) {
            displayToast("Email address is invalid");
        }
        else if (phone.length > 0 && phoneExpression.test(phone) === false) {
            displayToast("Phone number is invalid");
        }
        else if (!userNameExpression.test(first) || !userNameExpression.test(last)) {
            displayToast("First or last name is invalid (both are required and can't be empty)");
        }
        else {
            clearFields();

            var user:IUser = JSON.parse(JSON.stringify(props.user));

            user.first = first;
            user.last = last;
            user.email = email;
            user.nickname = nickname;

            // If a phone number was entered, put it into the
            // international format expected by Cognito.
            // Resulting phone number length should be 12 (e.g. +15854757558).
            if (phone.length > 0)
                user.phone = '+1' + phone.replaceAll('-', '');

            // Set user.timezone to a string (e.g. America/New_York).
            if (typeof selectedTimezone === "string") 
                user.timezone = selectedTimezone;
            else 
                user.timezone = selectedTimezone.value;

            // Always include password when adding a user.
            // Also include password when editing a user, if a password has been specified.
            if (props.type === DialogType.ADD || pw.length > 0)
                user.temporaryPassword = pw;

            props.handleSave(user);
        }
    }

    const handleClose = () => {
        // Note we are not returning anything to the parent.
        clearFields();
        props.handleClose();
    }

    return (
        <Modal show={props.show} backdrop="static" scrollable={true}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Container>
                    <Row>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="email">
                                <Form.Label>Username (Email)</Form.Label>

                                {
                                props.type === DialogType.ADD &&
                                <Form.Control type="email" value={email} isInvalid={!emailValid} onChange={(event) => updateEmail(event)}/>
                                }

                                {
                                props.type === DialogType.EDIT &&
                                <Form.Control type="text" value={email} disabled/>
                                }

                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="phone">
                                <Form.Label>Phone (xxx-xxx-xxxx)</Form.Label>
                                <Form.Control type="tel" value={phone} placeholder="(optional)" isInvalid={!phoneValid} onChange={(event) => updatePhone(event)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="firstname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" value={first} isInvalid={!firstValid} onChange={(event) => updateFirst(event)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="timezone">
                                <Form.Label>Timezone</Form.Label>
                                <TimezoneSelect
                                    value={selectedTimezone}
                                    onChange={setSelectedTimezone}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="lastname">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" value={last} isInvalid={!lastValid} onChange={(event) => updateLast(event)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="new_pw">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" value={pw} isInvalid={!pwValid} onChange={event => updatePw(event)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="nickname">
                                <Form.Label>Nickname</Form.Label>
                                <Form.Control type="text" value={nickname} placeholder="(optional)" isInvalid={!nicknameValid} onChange={(event) => updateNickname(event)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className={classNames("mb-3")} controlId="confirm_pw">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" value={confirmPw} isInvalid={!confirmPwValid} onChange={event => updateConfirmPw(event)}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* This alert is for showing errors. It is always visible. */}
                    <Alert variant={showToast ? "danger" : "light"}>
                        {toastMessage}
                    </Alert>

                    {
                    props.type === DialogType.EDIT &&
                    <div className="mb-3">
                        <Form.Text className="text-muted">
                            <h6>Notes</h6>
                            If changing the password, enter a new password.<br/>
                            Otherwise, leave the password fields blank.<br/>
                            Username (Email) cannot be changed.
                        </Form.Text>
                    </div>
                    }

                    {
                    props.type === DialogType.ADD &&
                    <div className="mb-3">
                        <Form.Text className="text-muted">
                            <h6>Notes</h6>
                            Email address will be used as username.<br/>
                            All fields, except nickname and phone number, are required.
                        </Form.Text>
                    </div>
                    }

                    <PasswordRequirements/>
                    </Container>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}