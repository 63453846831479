import {CognitoUser} from "@aws-amplify/auth";
import {CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserSession} from "amazon-cognito-identity-js";
import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";

export interface DebugJwtProps {
    cognitoUser: CognitoUser | undefined
}

export const DebugJwt: React.FC<DebugJwtProps> = (props: DebugJwtProps) => {
    const [idToken, setIdToken] = useState<CognitoIdToken>()
    const [accessToken, setAccessToken] = useState<CognitoAccessToken>()
    const [refreshToken, setRefreshToken] = useState<CognitoRefreshToken>()

    useEffect(() => {
        if (props.cognitoUser !== undefined) {
            props.cognitoUser.getSession((error: any, session: CognitoUserSession) => {
                if (!error) {
                    setIdToken(session.getIdToken())
                    setAccessToken(session.getAccessToken())
                    setRefreshToken(session.getRefreshToken())
                }
            })
        }
    }, [props.cognitoUser])


    return <>
        <h4>ID Token</h4>
        <Table striped bordered title={"ID Token"}>
            <thead>
            <tr>
                <th>Claim</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {
                idToken && idToken.payload && Object.keys(idToken.payload).map((key) =>
                    <tr key={"id." + key}>
                        <td>{key}</td>
                        <td>{toDateOrString(idToken.payload, key)}</td>
                    </tr>
                )
            }

            </tbody>
        </Table>


        <h4>Access Token</h4>
        <Table striped bordered title={"Access Token"}>
            <thead>
            <tr>
                <th>Claim</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {
                accessToken && Object.keys(accessToken.payload).map((key) =>
                    <tr key={"access." + key}>
                        <td>{key}</td>
                        <td>{toDateOrString(accessToken.payload, key)}</td>
                    </tr>
                )
            }
            </tbody>
        </Table>


        <h4>Refresh Token</h4>
        <Table striped bordered title={"Refresh Token"}>
            <thead>
            <tr>
                <th>Claim</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {
                refreshToken && Object.keys(refreshToken).map((key) =>
                    <tr key={"refresh." + key}>
                        <td>{key}</td>
                        <td>{toDateOrString(refreshToken, key)}</td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    </>
}


function toDateOrString(obj: any, key: string): string {
    const val = obj[key]
    if (val === undefined || val === null) {
        return "(null)"
    } else if (key === "iat" || key === "exp" || key === "auth_time") {
        /* this time looks kind of like an epoch so display it as a date */
        return new Date(val * 1000).toString()
    } else {
        return val.toString()
    }

}

