import {ICustomer, CustomerListResponse} from "../models/customer";
import {CustomerNode, CustomerStatusResponse} from "../models/customer"
import {ISite, SiteListResponse} from "../models/site";
import {SiteStatusResponse} from "../models/site"
import {ISystem, SystemListResponse} from "../models/system";
import { SystemStatusResponse} from "../models/system"
import {IMachine, MachineListResponse, MachineAlarmsResponse} from "../models/machine";
import {MachineStatusResponse} from "../models/machine"
import {IUser, UserListResponse, UserPermissions} from "../models/user";
import {MachineUtilizationRequest, MachineUtilizationResponse} from "../models/reports"
import {AlarmDetailRequest, AlarmDetailResponse} from "../models/reports"
import {AlarmSummaryRequest, AlarmSummaryResponse} from "../models/reports"
import {ItemDetailRequest, ItemDetailResponse} from "../models/reports"
import {ItemSummaryRequest, ItemSummaryResponse} from "../models/reports"
import {FormulaDetailRequest, FormulaDetailResponse} from "../models/reports"
import {FormulaSummaryRequest, FormulaSummaryResponse} from "../models/reports"
import {ICounter} from "./busy";

/*
 * This class defines the Washnet backend and provides methods for
 * creating, reading, updating, and deleting stuff.
 */
export abstract class WashnetBackend {

    protected constructor(private busyCounter: ICounter) {
        this.treeCache = new Map<string, CustomerNode>();
    }

    // Tree cache: Maps customerId to a tree (CustomerNode).
    protected treeCache: Map<string, CustomerNode>;

    public clearTreeCache() {
        console.log("Clearing the tree cache");
        this.treeCache.clear();
    }
    
    abstract getBackendURL(): string;

    abstract getMachineName(key:string, machineId:string);
    
    // Returns the "tree" of sites, systems, and machines for the given customer (for populating the sidebar).
    abstract getTree(customerId:string): Promise<CustomerNode>;

    // For fetching customer, site, system, machine, including cfg data (shifts, targets, etc.).
    abstract getCustomer(customerId:string): Promise<ICustomer>;
    abstract getAllCustomers() : Promise<CustomerListResponse>;
    abstract getSite(customerId:string, siteId:string): Promise<ISite>;
    abstract getSitesForCustomer(customerId:string) : Promise<SiteListResponse>;
    abstract getSystem(customerId:string, siteId:string, systemId:string): Promise<ISystem>;
    abstract getSystemsForSite(customerId:string, siteId:string) : Promise<SystemListResponse>;
    abstract getMachine(customerId:string, siteId:string, systemId:string, machineId:string): Promise<IMachine>;
    abstract getMachinesForSystem(customerId:string, siteId:string, systemId:string): Promise<MachineListResponse>;

    // For fetching current data for customer, site, system and machine pages.
    abstract getCustomerStatus(customerId:string, start:number, end:number): Promise<CustomerStatusResponse>;
    abstract getSiteStatus(customerId:string, siteId:string, start:number, end:number): Promise<SiteStatusResponse>;
    abstract getSystemStatus(customerId:string, siteId:string, systemId:string, start:number, end:number): Promise<SystemStatusResponse>;
    abstract getMachineStatus(customerId:string, siteId:string, systemId:string, machineId:string): Promise<MachineStatusResponse>;
    abstract getMachineAlarms(customerId:string, siteId:string, systemId:string, machineId:string, start:number, end:number): Promise<MachineAlarmsResponse>;

    // For creating reports.
    abstract getMachineUtilizationReport(params:MachineUtilizationRequest): Promise<MachineUtilizationResponse>;
    abstract getAlarmDetailReport(params:AlarmDetailRequest): Promise<AlarmDetailResponse>;
    abstract getAlarmSummaryReport(params:AlarmSummaryRequest): Promise<AlarmSummaryResponse>;
    abstract getItemDetailReport(params:ItemDetailRequest): Promise<ItemDetailResponse>;
    abstract getItemSummaryReport(params:ItemSummaryRequest): Promise<ItemSummaryResponse>;
    abstract getFormulaDetailReport(params:FormulaDetailRequest): Promise<FormulaDetailResponse>;
    abstract getFormulaSummaryReport(params:FormulaSummaryRequest): Promise<FormulaSummaryResponse>;

    // For creating, updating and deleting customers, sites and systems.
    abstract createCustomer(customer: ICustomer) : Promise<ICustomer>;
    abstract deleteCustomer(customerId:string): Promise<string>;
    abstract updateCustomer(customer: ICustomer): Promise<ICustomer>;
    abstract createSite(customerId:string, site: ISite) : Promise<ISite>;
    abstract deleteSite(customerId:string, siteId:string): Promise<string>;
    abstract updateSite(customerId:string, site: ISite): Promise<ISite>;
    abstract createSystem(customerId:string, siteId:string, system: ISystem) : Promise<ISystem>;
    abstract deleteSystem(customerId:string, siteId:string, systemId:string): Promise<string>;
    abstract updateSystem(customerId:string, siteId:string, system: ISystem): Promise<ISystem>;
    abstract createMachine(customerId:string, siteId:string, systemId:string, machine:IMachine): Promise<IMachine>;
    abstract deleteMachine(customerId:string, siteId:string, systemId:string, machineId:string): Promise<string>;
    abstract updateMachine(customerId:string, siteId:string, systemId:string, machine:IMachine): Promise<IMachine>;

    // For fetching, creating, updating and deleting users, and handling user permissions.
    abstract createUser(customerId:string, user: IUser) : Promise<IUser>;
    abstract deleteUser(customerId:string, userId:string) : Promise<string>;
    abstract updateUser(customerId:string, user: IUser) : Promise<IUser>;
    abstract getUser(customerId:string, userId:string) : Promise<IUser>;
    abstract getUsersForCustomer(customerId:string) : Promise<UserListResponse>;
    abstract getUserGrants(customerId:string, userId:string) : Promise<UserPermissions>;
    abstract addUserGrants(customerId:string, userId:string, grants:UserPermissions) : Promise<string>;
    abstract deleteUserGrants(customerId:string, userId:string, grants:UserPermissions) : Promise<string>;
    abstract whoAmI() : Promise<IUser>;

    public get isBusy() {
        return this.busyCounter.isBusy()
    }

    protected startBusy() {
        // console.log("inc");
        this.busyCounter.inc()
    }

    protected finishBusy() {
        // console.log("dec");
        this.busyCounter.dec()
    }
}
