import classNames from "classnames";
import Button from "react-bootstrap/Button";
import {ArrowClockwise} from 'react-bootstrap-icons';
import {useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import {WashnetBackend} from "../services/WashnetBackend";
import {CustomerStatusResponse} from "../models/customer"
import {ISite, SiteListResponse} from "../models/site";
import {ProductionTargets, IProductionTargets, ISiteMetrics} from "../models/types";
import {PeriodType, DisplayType, RankType, SortType} from "../models/types";
import {ModalShowMessage} from "../admin/ModalShowMessage"
import {Container, Row, Col} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Alert from "react-bootstrap/Alert";
import {getUnixTime, sub} from 'date-fns'
import {SiteStatusGraphs} from '../components/SiteStatusGraphs';
import {getCurrentShift} from "../util";
import {ArrowUp, ArrowDown} from 'react-bootstrap-icons';

export interface CustomerViewProps {
    backend: WashnetBackend
}

export const CustomerView: React.FC<CustomerViewProps> = (props: CustomerViewProps) => {
    const {customerId, customerName} = useParams();

    const [period, setPeriod] = useState<PeriodType>(PeriodType.LAST_HOUR);
    const [display, setDisplay] = useState<DisplayType>(DisplayType.TOTALS);
    const [rankBy, setRankBy] = useState<RankType>(RankType.POUNDS);
    
    const [customerStatus, setCustomerStatus] = useState<CustomerStatusResponse>();
    const [sites, setSites] = useState<ISite[]>([]);

    // Here we have two separate site lists so that each list can be sorted separately.

    // This is the sorted list of sites for the graphs.
    const [sortedSiteList, setSortedSiteList] = useState<ISiteMetrics[]>([]);

    // This is the sorted list of sites for the table.
    const [sortedTableList, setSortedTableList] = useState<ISiteMetrics[]>([]);

    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");

    ///////////////////////////////////

    // Called when a table column is clicked.
    function sortTable(field:string,) {
        let order:string = sortOrder;

        if (field === sortField) {
            // This column is currently selected and just clicked again, so toggle the sort order.
            order = (order === "asc" ? "desc" : "asc");
        }
        else {
            // Column was just selected (previously another column was selected)
            // so default sort order to "asc".
            order = "asc";
        }

        // console.log("at sort", field, order);
        setSortedTableList( sortTableList(sortedTableList, field, order) );

        // Save the field that is currently selected and the current sort order.
        setSortField(field); 
        setSortOrder(order);
    }

    // This is for sorting the site list that appears in the table.
    // Returns a new sorted list from the given list.
    //  - field is the name of the column to sort
    //  - order is the sort order ("asc" or "desc") 
    function sortTableList(list: ISiteMetrics[], field: string, order: string): ISiteMetrics[] {
        // Note that sort() changes the original array.
        // In other words it sorts in place.
        return list.sort( (element1: ISiteMetrics, element2: ISiteMetrics) => {
            var item1: number = 0;
            var item2: number = 0;
        
            switch(field) {
                case "pounds":
                    item1 = element1.pounds;
                    item2 = element2.pounds;
                    break;
                case "pounds/hour":
                    item1 = element1.pounds_per_hour;
                    item2 = element2.pounds_per_hour;
                    break;
                case "loads":
                    item1 = element1.loads;
                    item2 = element2.loads;
                    break;
                case "loads/hour":
                    item1 = element1.loads_per_hour;
                    item2 = element2.loads_per_hour;
                    break;
                case "turns":
                    item1 = element1.turns;
                    item2 = element2.turns;
                    break;
                case "turns/hour":
                    item1 = element1.turns_per_hour;
                    item2 = element2.turns_per_hour;
                    break;
            }
            
            if (order === "asc") {
                if (item1 < item2)
                    return -1;
                if (item1 > item2)
                    return 1;
                return 0;
            }
            else {
                if (item1 > item2)
                    return -1;
                if (item1 < item2)
                    return 1;
                return 0;
            }
        })
    }

    ///////////////////////////////////

    // Get the time range in hours from the selection in the Time Period dropdown.
    function getTimeRange(): number {
        var range: number = 1;
        switch(period) {
            case PeriodType.LAST_HOUR:
                range = 1;
                break;
            case PeriodType.LAST_TWO_HOURS:
                range = 2;
                break;
            case PeriodType.LAST_FOUR_HOURS:
                range = 4;
                break;
            case PeriodType.LAST_EIGHT_HOURS:
                range = 8;
                break;
        }
        return range;
    }

    // Sort type is determined by the selections in 
    // the Display dropdown and the Rank By dropdown.
    function getSortType(): SortType {
        var sortType: SortType;
        switch(display) {
            case DisplayType.TOTALS:
                switch(rankBy) {
                    case RankType.POUNDS:
                        sortType = SortType.TOTAL_POUNDS;
                        break;
                    case RankType.LOADS:
                        sortType = SortType.TOTAL_LOADS;
                        break;
                    case RankType.TURNS:
                        sortType = SortType.TOTAL_TURNS;
                        break;
                }
            break;
            case DisplayType.RATES:
                switch(rankBy) {
                    case RankType.POUNDS:
                        sortType = SortType.RATE_POUNDS;
                        break;
                    case RankType.LOADS:
                        sortType = SortType.RATE_LOADS;
                        break;
                    case RankType.TURNS:
                        sortType = SortType.RATE_TURNS;
                        break;
                }
            break;
        }
        return sortType;
    }

    // Get the targets from the sites list for the given siteId.
    function getTargetsForSite(siteList:ISite[], siteId:string): IProductionTargets {
        const now:Date = new Date();
        const _site = siteList.find((element) => element.id === siteId);
        if (_site !== undefined) {
            const currentShift = getCurrentShift(now, _site.cfg.shiftList);
            if (currentShift !== undefined) {
                const shiftId = currentShift?.id;
                const s = _site.cfg.shiftList.shifts.find((element) => element.id === shiftId);
                if (s !== undefined) {
                    return s.targets;
                }
            }
        }

        // Targets not found for site, return default targets.
        console.log(`Targets not found for site ${siteId}, using default targets.`);
        return new ProductionTargets();
    }

    // Creates and returns a new list from the response that includes calculated statistics.
    // response: customer status data from the backend, contains production totals for sites
    // siteList: the list of sites from this customer, contains production targets for sites
    // range: the time range in hours, used to calculate the "per hour" values
    function calcSiteList(response: CustomerStatusResponse, siteList:ISite[], range:number): ISiteMetrics[] {
        var newList:ISiteMetrics[] = response.sites.map((site) => {
            var targets = getTargetsForSite(siteList, site.id);

            // Targets might be zero so don't divide by zero.
            return {
                  id: site.id,
                  name: getSiteName(siteList, site.id),

                  // production totals
                  pounds: site.pounds,
                  loads: site.loads,
                  turns: site.turns,

                  // production rates
                  pounds_per_hour: site.pounds / range,
                  loads_per_hour: site.loads / range,
                  turns_per_hour: site.turns / range,

                  // production totals divided by targets as a percentage 
                  total_pounds_percent: (targets.pounds !== 0) ? Math.round(site.pounds / targets.pounds * 100) : 0,
                  total_loads_percent: (targets.loads !== 0) ? Math.round(site.loads / targets.loads * 100) : 0,
                  total_turns_percent: (targets.turns !== 0) ? Math.round(site.turns / targets.turns * 100) : 0,

                  // production rates divided by target rates as a percentage 
                  rate_pounds_percent: (targets.pounds_per_hour !== 0) ? Math.round(site.pounds / range / targets.pounds_per_hour * 100) : 0,
                  rate_loads_percent: (targets.loads_per_hour !== 0) ? Math.round(site.loads / range / targets.loads_per_hour * 100) : 0,
                  rate_turns_percent: (targets.turns_per_hour !== 0) ? Math.round(site.turns / range / targets.turns_per_hour * 100) : 0,

                  targets: targets
            } as ISiteMetrics;
        });
    
        return newList;
    }
    
    // Returns a new sorted list from the given list and the given sort type. 
    function sortSiteList(list: ISiteMetrics[], sortType:SortType): ISiteMetrics[] {
        // Note that sort() changes the original array.
        // In other words it sorts in place.
        return list.sort( (element1: ISiteMetrics, element2: ISiteMetrics) => {
            var item1: number;
            var item2: number;
        
            // Determine the fields to sort by for the given sort type.
            switch(sortType) {
                case SortType.TOTAL_POUNDS:
                    item1 = element1.total_pounds_percent;
                    item2 = element2.total_pounds_percent;
                    break;
                case SortType.TOTAL_LOADS:
                    item1 = element1.total_loads_percent;
                    item2 = element2.total_loads_percent;
                    break;
                case SortType.TOTAL_TURNS:
                    item1 = element1.total_turns_percent;
                    item2 = element2.total_turns_percent;
                    break;
                case SortType.RATE_POUNDS:
                    item1 = element1.rate_pounds_percent;
                    item2 = element2.rate_pounds_percent;
                    break;
                case SortType.RATE_LOADS:
                    item1 = element1.rate_loads_percent;
                    item2 = element2.rate_loads_percent;
                    break;
                case SortType.RATE_TURNS:
                    item1 = element1.rate_turns_percent;
                    item2 = element2.rate_turns_percent;
                    break;
            }
        
            if (item1 < item2)
                return -1;
            if (item1 > item2)
                return 1;
            return 0;
        })
    }

    // The onChange handler for the Period dropdown.
    const onChangePeriod = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPeriod(parseInt(event.target.value));

        // Get the customer status data from the backend when Period dropdown changes.
        // This will cause the data to be recalculated and redisplayed.

        // Request the current status data for the given customer.
        getCustomerStatus();
    }

    // The onChange handler for the Display dropdown.
    const onChangeDisplay = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDisplay(parseInt(event.target.value));

        // Redisplay all data on the page when Display dropdown changes.
        // We don't need to requery the backend. We can use the already fetched
        // data, resort it, and redisplay.

        setSortedSiteList( sortSiteList(sortedSiteList, getSortType()) );
    }

    // The onChange handler for the Rank By dropdown.
    const onChangeSort = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setRankBy(parseInt(event.target.value));

        // Redisplay all data on the page when Rank By dropdown changes.
        // We don't need to requery the backend. We can use the already fetched
        // data, resort it, and redisplay.

        setSortedSiteList( sortSiteList(sortedSiteList, getSortType()) );
    }

    function getCustomerStatus() {
        // Request the current status data for the given customer.
        // We need to pass start and end times based on the selection in the period dropdown.
        // For example, if selection is Last Hour, start = now minus one hour, end = now.

        var now:Date = new Date();
        var endMs:number = getUnixTime(now);
        var startMs:number = getUnixTime(sub(now, {hours:getTimeRange()}));

        // TODO: Delete these times, for testing only.
        // startMs = 1699448400;
        // endMs = 1699480800;
        // console.log(`start time is ${startMs} end time is ${endMs}`);

        // TODO: Figure out how to get rid of this imperative (!).
        props.backend.getCustomerStatus(customerId!, startMs, endMs).then( (response:CustomerStatusResponse) => {
            setCustomerStatus(response);

            // Calculate the metrics for each site in the response.
            var list = calcSiteList(response, sites, getTimeRange());
            setSortedSiteList(list);

            // This does a deep copy of the list.
            setSortedTableList( JSON.parse(JSON.stringify(list)) );
        })
        .catch((error) => {
            displayAlert(error.message);
        })
    }

    // Handler for the refresh button.
    function refreshPage() {
        // Request the current status data for the given customer.
        getCustomerStatus();
    }

    // Look up site name from site ID.
    function getSiteName(siteList:ISite[], siteId:string):string {
        const s = siteList.find((element) => element.id === siteId);
        return (s === undefined) ? "---" : s.name;
    }

    useEffect(() => {
        // Make sure we have these IDs.
        if (customerId === undefined) {
            console.log("customerId is undefined");
            return;
        }

        var now:Date = new Date();
        var endMs:number = getUnixTime(now);
        var startMs:number = getUnixTime(sub(now, {hours:getTimeRange()}));
        
        // TODO: Delete these times, for testing only.
        // startMs = 1699448400;
        // endMs = 1699480800;
        // console.log(`start time is ${startMs} end time is ${endMs}`);

        // Request the target settings (and other info) for all sites for the given customer.
        props.backend.getSitesForCustomer(customerId).then( (response:SiteListResponse) => {
            setSites(response.sites);
            
            // There is a delay in setting sites when setSites() is called from this useEffect function.
            // So I have to pass the site list in Promise.all() because it's needed in the .then block.

            // Request the current status data for the given customer.
            return Promise.all([response.sites, props.backend.getCustomerStatus(customerId, startMs, endMs)])
        })
        .then(([siteList, response]) => {
            setCustomerStatus(response);

            // Calculate the metrics for each site in the response.
            var list = calcSiteList(response, siteList, getTimeRange());
            setSortedSiteList(list);

            // This does a deep copy of the list.
            setSortedTableList( JSON.parse(JSON.stringify(list)) );
        })
        .catch((error) => {
            displayAlert(error.message);
        })

    }, [customerId])

    ///////////////////////////////////
    // For the alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    function displayAlert(message:string) {
        setAlertMessage(message);
        setShowAlert(true);
    }

    function handleCloseAlert() {
        setAlertMessage("");
        setShowAlert(false);
    }

    ///////////////////////////////////

    return (
        <div>
            <div className="d-flex justify-content-between ms-1 mb-4">
                <h4 className="braun-text mb-4">{`${customerName}`}</h4>
                <Button className={classNames("me-2")} variant="light" size="lg" onClick={() => refreshPage()}><ArrowClockwise/></Button>
            </div>

            <div className="d-flex justify-content-between mb-3 fs-5">
                <Container fluid>
                    <Row>
                        <Col>
                            <Form.Label>Time Period</Form.Label>
                            <Form.Group className="mb-3 pe-5 braun-text" controlId="period">
                                <Form.Select value={period} onChange={event => onChangePeriod(event)}>
                                    <option value={PeriodType.LAST_HOUR}>Last Hour</option>
                                    <option value={PeriodType.LAST_TWO_HOURS}>Last 2 Hours</option>
                                    <option value={PeriodType.LAST_FOUR_HOURS}>Last 4 Hours</option>
                                    <option value={PeriodType.LAST_EIGHT_HOURS}>Last 8 Hours</option>
                                </Form.Select>            
                            </Form.Group>
                        </Col>

                        <Col>
                        <Form.Label>Display</Form.Label>
                            <Form.Group className="mb-3 pe-5 braun-text" controlId="display">
                                <Form.Select value={display} onChange={event => onChangeDisplay(event)}>
                                    <option value={DisplayType.TOTALS}>Production Totals</option>
                                    <option value={DisplayType.RATES}>Production Rates</option>
                                </Form.Select>            
                            </Form.Group>
                        </Col>

                        <Col>
                        <Form.Label>Rank By</Form.Label>
                            <Form.Group className="mb-3 pe-5 braun-text" controlId="rank">
                                <Form.Select value={rankBy} onChange={event => onChangeSort(event)}>
                                    <option value={RankType.POUNDS}>Pounds</option>
                                    <option value={RankType.LOADS}>Loads</option>
                                    <option value={RankType.TURNS}>Turns</option>
                                </Form.Select>            
                            </Form.Group>
                        </Col>

                    </Row>
                </Container>
            </div>

            {
                (sortedSiteList.length === 0) &&
                <Alert variant="warning">No data available for the selected time range.</Alert>
            }

            {
                (sortedSiteList.length > 0) &&
                <div>
                    {/* Shows the graphs */}
                    <SiteStatusGraphs list={sortedSiteList} displayType={display}/>

                    <h5 className="braun-text mb-4">All Sites</h5>
                    <h6 className="mb-4">Click a column header to sort the table.</h6>

                    <Container fluid>
                        <Row>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Site</th>
                                        <th onClick={() => sortTable("pounds")}>
                                            Pounds &nbsp; {sortField === "pounds" && (sortOrder === "asc" ? <ArrowUp/> : <ArrowDown/>)} 
                                        </th>
                                        <th onClick={() => sortTable("pounds/hour")}>
                                            Pounds/Hour &nbsp; {sortField === "pounds/hour" && (sortOrder === "asc" ? <ArrowUp/> : <ArrowDown/>)} 
                                        </th>
                                        <th onClick={() => sortTable("loads")}>
                                            Loads &nbsp; {sortField === "loads" && (sortOrder === "asc" ? <ArrowUp/> : <ArrowDown/>)} 
                                        </th>
                                        <th onClick={() => sortTable("loads/hour")}>
                                            Loads/Hour &nbsp; {sortField === "loads/hour" && (sortOrder === "asc" ? <ArrowUp/> : <ArrowDown/>)} 
                                        </th>
                                        <th onClick={() => sortTable("turns")}>
                                            Turns &nbsp; {sortField === "turns" && (sortOrder === "asc" ? <ArrowUp/> : <ArrowDown/>)} 
                                        </th>
                                        <th onClick={() => sortTable("turns/hour")}>
                                            Turns/Hour &nbsp; {sortField === "turns/hour" && (sortOrder === "asc" ? <ArrowUp/> : <ArrowDown/>)} 
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    sortedTableList.map((s: ISiteMetrics, i: number) =>
                                    <tr key={`s${i}`}>
                                        <td>{s.name}</td>
                                        <td>{s.pounds}</td>
                                        <td>{s.pounds_per_hour}</td>
                                        <td>{s.loads}</td>
                                        <td>{s.loads_per_hour}</td>
                                        <td>{s.turns}</td>
                                        <td>{s.turns_per_hour}</td>
                                    </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </Row>
                    </Container>

                    {/* TODO: How do we determine which sites are reporting? */}
                    {/* <h5 className="braun-text mb-4">Sites Not Reporting</h5>
                    <p>List sites that are not reporting here.</p> */}
                </div>
            }

            {/* Modal to show message. */}
            <ModalShowMessage show={showAlert}
                message={alertMessage}
                variant="danger"
                handleClose={handleCloseAlert}
            />
        </div>
    );
}
