import classNames from "classnames";
import {State} from "../models/types";
import {getLedColor} from '../util'

export interface LedProps {
    machineState: State
}

export const Led: React.FC<LedProps> = (props: LedProps) => {
    return (
        <img className={classNames("pe-0")} alt="led" src={getLedColor(props.machineState.productivityState)}/>
    );
}
