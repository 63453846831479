import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";

export const DebugHome : React.FC = (props) => {
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>Administration Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/debug" }}>Debug</Breadcrumb.Item>
            </Breadcrumb>

            <h4>Debug</h4>

            <Container fluid>
                <Row>
                    <Link to={"/debug/jwt"}>Authorization Tokens</Link>
                </Row>
            </Container>
        </div>
    );
}
